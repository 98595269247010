<template>
  <div>
    <div id="head">
      <Menubar msg="test" @query="query" @getwinsize="getwinsize" ref="Mbar" />
    </div>
    <a-spin
      :spinning="spinning"
      :indicator="indicator"
      size="large"
      :class="
        spinning
          ? windowsize.width > 400
            ? 'spin-content'
            : 'spin-content2'
          : ''
      "
    >
      <div id="content" style="padding: 6px" v-show="!spinning">
        <div style="margin-top: 75px">
          <Slickcarousel
            :msg="classtheme"
            :orid="orgid"
            @rload="rload"
            ref="slick"
          />          
        </div>
        <Actitemlist class="contantdiv" :item="list" kinds="inside" show="1" :winsize="windowsize" />
        <a-button
          v-show="!spinning && list.length>0"
          style="margin-bottom: 20px; margin-top: -20px"
          :loading="iconLoading"
          @click="getmore()"
          type="primary"
          shape="round"
          size="large"
        >
          <b-icon
            v-show="!iconLoading"
            style="margin-right: 0.3rem"
            scale="1.1"
            icon="cloud-download"
            variant="light"
          />
          取得更多資料
        </a-button>        
      </div>
    </a-spin>
    <div id="footer">
      <Footbar msg="test" :summary="mySummary" />
    </div>
    <div class="meta-button" v-if="status.token != ''">     
      <a href="#" @click="openArie()" class="meta-button-icon2"></a>
      <span class="meta-button-text"></span>
      <a href="#" @click="openContent()" class="meta-button-icon"></a>
      <span class="meta-button-text"></span>      
    </div>

    <a-modal
      :title="'我的最愛❤'"
      :visible="visible"
      :closable="true"
      :maskClosable="false"
      :okText="'刪除'"
      :okType="'danger'"
      :cancelText="'取消'"
      :bodyStyle="{ padding: '10px' }"
      @cancel="handelCancel"
    >
      <div class="text-center overflow-auto">
        <div
          style="padding: 0rem; max-height: 550px"
          v-if="status.token != ''"
          class="text-left"
        >
          <b-list-group>
            <b-list-group-item
              button
              v-for="data in user.data.user.acts_loves"
              @click="queryByOrg(data)"
              :key="data.uid"
            >
              <b-avatar
                variant="primary"
                :src="data.pri_url"
                :size="36"
                class="align-baseline"
              ></b-avatar>
              <span
                v-text="data.name"
                style="padding-left: 0.5rem; font-size: medium"
              />
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
      <template slot="footer">
        <div class="text-center"></div>
      </template>
    </a-modal>

    <a-modal
        :title= "'我的區域'"
        :visible="visible2"
        :closable=true   
        :maskClosable=false
        :okText="'刪除'"
        :okType="'danger'"
        :cancelText= "'取消'"
        :bodyStyle="{ padding:'10px'}"
        @cancel="handelCancel2"
      >
      <div class="text-center overflow-auto">
         <div style="padding:0rem;max-height: 550px;" v-if="user!==null" class="text-center">
          <a-row type="flex" justify="center" :align="'middle'" >
              <a-col :span="10" v-for="data in groupedData" :key="data.village">                
                <a-button shape="round"  @click="toArea(data.village)" style="margin:0.4rem;" :size="'large'" >
                  <b-icon icon="geo-alt-fill"  aria-hidden="true"></b-icon>
                  <span v-text="data.village" style="padding-left: 0.5rem;font-size: medium;" />
                </a-button>
              </a-col>                  
          </a-row>  
         </div>
      </div>
      <template slot="footer">
          <div class="text-center">
          </div>          
      </template>
      </a-modal>


  </div>
</template>

<script>
// @ is an alias to /src
import Slickcarousel from "@/components/Slickcarousel.vue";
import Actitemlist from "@/components/Actitemlist.vue";
import Menubar from "@/components/Menubar.vue";
import Footbar from "@/components/Footbar.vue";
import TW_JSON from '../assets/json/twdata.json'
import { actService, joinService } from "../_services";
import * as moment from "moment/moment";
import { mapState, mapMutations } from "vuex";
import router from "../router";

const queryData = (obj) => {
  return actService.getItemAct(obj);
};

const querySummary = () => {
  return joinService.getSummary();
};

export default {
  name: "Home",
  components: {
    Slickcarousel,
    Actitemlist,
    Menubar,
    Footbar,
  },
  data() {
    return {
      iconLoading: false,
      classtheme: "all",
      spinning: false,
      list: [],
      twJson: TW_JSON,  
      groupedData:[],          
      page: 1,
      orgid: "N",
      area:'N',
      visible: false,
      visible2:false,       
      windowsize: {
        width: 0,
        height: 0,
      },
      mySummary:[],
      queryState: {},      
      indicator: (
        <div class="loading-box">
          <div class="inter-load">
            <div class="rect rect1"></div>
            <div class="rect rect2"></div>
            <div class="rect rect3"></div>
            <div class="rect rect4"></div>
            <div class="rect rect5"></div>
          </div>
        </div>
      ),
    };
  },
  computed: {
    ...mapState("account", ["user", "status", "targetpageUrl"]),
  },
  methods: {
    ...mapMutations("account", ["setpageUrl"]),
    query(rs) {
      this.orgid = "N";
      this.area= "N";      
      this.classtheme = rs;
      this.page = 1;
      this.list = [];
      this.infiniteHandler();
      this.slick;
      this.$refs["slick"].goquery(this.classtheme);
    },
    fdateformat(val) {
      return moment(val).format("YYYY-MM-DD HH:mm");
    },
    getwinsize(val) {
      this.windowsize.width = val.width;
      this.windowsize.height = val.height;
    },
    infiniteHandler() {
      this.iconLoading = true;
      var my_date = new Date();
      var first_date = new Date(
        my_date.getFullYear(),
        my_date.getMonth() - 12,
        1
      );
      let params = {
        page: this.page,
        size: 6,
        type: this.classtheme,
        //date: this.fdateformat(Date.now()),
        date: this.fdateformat(first_date),
        states: "Y",
        orgid: this.orgid,
        area:this.area
      };
      queryData({
        ...params,
      }).then(({ data }) => {
        let obj = data.results;
        if (obj != null) {
          this.page += 1;
          setTimeout(() => {
            //for (var prop in obj) {
            //   if(this.list.find(rs=>rs.id==obj[prop].id)==undefined){
            //    this.list.push(obj[prop]);
            //   } 
            //}
            let myobj = this.myPush(obj);   
            this.list.push(...myobj);         
            this.iconLoading = false;
          }, 650);
        } else {
          this.iconLoading = false;
        }
      });
    },

    geSummaryReport() {
      this.iconLoading = true;
      querySummary().then(({ data }) => {
        let obj = data;
        if (data.length > 0){
          this.mySummary =obj
          setTimeout(() => {       
            this.iconLoading = false;
          }, 650);
        } else {
          this.mySummary=[]
          this.iconLoading = false;
        }
      });
    },    
    

    myPush(obj) {
        // 使用 Fisher-Yates 洗牌算法來隨機排列陣列
        for (let i = obj.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            //const j = Math.floor(Math.random() * obj.length); // 範圍擴大到整個陣列
            [obj[i], obj[j]] = [obj[j], obj[i]]; // 交換元素位置
        }
        return obj;
    },

    getmore() {
      this.infiniteHandler();
    },
    openContent() {
      this.visible = true;
    },
    handelCancel() {
      this.visible = false;
    },
    openArie(){
      this.visible2=true;
    },    
    handelCancel2(){
      this.visible2=false
    },
    
    toArea(val){
      this.orgid = "N";
      this.area= process.env.VUE_APP_AREA+val;
      this.list = [];
      this.classtheme = "N";
      this.page = 1;
      this.infiniteHandler();
      this.$refs["slick"].goquery3(this.classtheme, this.area);
      this.visible2 = false;
    },    

    queryByOrg(item) {
      this.orgid = item.ids.toString();
      this.area= "N";
      this.list = [];
      this.classtheme = "N";
      this.page = 1;
      this.infiniteHandler();
      this.$refs["slick"].goquery2(this.classtheme, this.orgid);
      this.visible = false;
    },
    rload(val) {
      this.spinning = val;
    },
    setorgpageURL(obj) {
      return new Promise((resolve) => {
        resolve(this.setpageUrl(obj));
      });
    },

  },
  mounted() {
    this.orgid = "N";
    //this.infiniteHandler(); 
    setTimeout(() => {
  if (this.status.token !== "") {
    if (this.targetpageUrl !== null && this.targetpageUrl !== undefined) {
      if (this.targetpageUrl.id !== "" && this.targetpageUrl.id !== null) {
        let tmp = this.targetpageUrl;
        this.setorgpageURL(null)
        .then((val) => {
          return val;})
        .catch((val) => {return val;})
        if (tmp.name == "Actinfo") {
          router.push({ name: tmp.name, params: { id: tmp.id } }); // -> /user/123
        } else if (tmp.name == "Outorg") {
          router.push({name: tmp.name,params: { id: tmp.id, type: tmp.type },});
        }}}}}, 2500);	
  },
  async created() {
    const filteredData = this.twJson.filter(item => item.city === process.env.VUE_APP_AREA);
    const uniqueVillages = [...new Set(filteredData.map(item => item.village))];
    this.groupedData = uniqueVillages.map(village => ({ village }));
    const savedQueryState = JSON.parse(window.sessionStorage.getItem('queryState'));
    this.geSummaryReport();
      if (savedQueryState) {
      this.queryState = savedQueryState;
      this.page=this.queryState.page
      this.classtheme=this.queryState.classtheme
      this.orgid=this.queryState.orgid
      this.area=this.queryState.area
      this.list=this.queryState.list
      if(this.classtheme!=='N' && this.orgid=='N' && this.area=='N'){        
        setTimeout(() => {
          this.$refs["slick"].goquery(this.classtheme)
          }, 350);         
      }else if(this.orgid!=='N' && this.classtheme=='N' && this.area=='N'){ 
         setTimeout(() => {
          this.$refs["slick"].goquery2(this.classtheme, this.orgid)
          }, 350);          
      }else if(this.area!=='N' && this.orgid=='N' && this.classtheme=='N'){
        setTimeout(() => {
          this.$refs["slick"].goquery3(this.classtheme, this.area)
          }, 350);                  
      }      
      if(this.list.length===0 || this.list==null){
        this.page=1
        this.list=[];
        this.infiniteHandler();
      }   
      window.sessionStorage.removeItem('queryState');
    }else{
      window.sessionStorage.removeItem('queryState');
      this.page=1
      this.list=[];
      this.infiniteHandler(); 
    }   
  },
  beforeRouteLeave(to, from, next) {
    this.queryState={
        page: this.page,
        classtheme: this.classtheme,
        orgid: this.orgid,
        area:this.area,
        list: this.list,
    }
    window.sessionStorage.setItem('queryState', JSON.stringify(this.queryState));
    next();
  },  
  
};
</script>
<style scoped>
.contantdiv {
  margin: 5px;
  overflow-x: hidden;
}

body {
  margin: 0;
  overflow-x: hidden;
}

#head {
  height: 2rem;
  overflow-x: hidden;
}

#content {
  min-height: calc(75vh - 4rem);
  overflow-x: hidden;
  background: linear-gradient(-45deg, #ffffff, #d2edf8, #ffffff);    
}

#footer {
  height: 2rem;
}
.meta-button {
  position: fixed;
  bottom: 20px;
  right: 10px;
  color: white;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.meta-button-icon {
  width: 50px;
  height: 50px;
  background-image: url("../assets/meta-icon.png");
  background-size: cover;
  margin-right: 5px;
}
.meta-button-icon2 {
  width: 50px;
  height: 50px;
  background-image: url("../assets/meta-icon2.png");
  background-size: cover;
  margin-right: 5px;
}

.meta-button-text {
  font-size: 14px;
}

.spin-content {
  margin: 0px auto;
  width: 100%;
  font-size: 1.5rem;
  padding: 360px;
  content: "";
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, #ffffff, #d2edf8, #ffffff);
  animation: gradient 1s ease infinite;
  background-size: 400% 400%;
  z-index: 11;
  transform: translate3d(0, 0, 0);
}
.spin-content2 {
  margin: 0px auto;
  width: 100%;
  font-size: 1.5rem;
  padding: 360px 360px 360px 30px;
  content: "";
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, #ffffff, #d2edf8, #ffffff);
  animation: gradient 1s ease infinite;
  background-size: 400% 400%;
  z-index: 11;
  transform: translate3d(0, 0, 0);
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.loading-box {
  width: 220px;
  height: 220px;
  border: 13px solid #468cc5;
  border-radius: 10px 50px 35px 70px;

  margin: 0px auto;
  position: relative;
  z-index: 12;
  transform: translate(-50%, 50%);
}
.inter-load {
  width: 100px;
  height: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 12;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #468cc5;
  display: inline-block;
  height: 120px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>