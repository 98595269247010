<template>
  <div>
    <div class="ddic">
      審核狀態
      <div class="divs" >
        <a-table
          :columns="columns"
          :row-key="record => record.id"
          :data-source="data"
          :pagination="pagination"
          :loading="loading"
          :locale="locale"            
          @change="handleTableChange"
          :scroll="{ y: 230 }"
        >
          <template slot="sdate" slot-scope="record">
                <span v-text="' '+fdateformat(record.sdate)"></span>
          </template>
          <template slot="actitle" slot-scope="record">
            <a href="javascript:;" @click="showinfo(record.fid)">
              <span v-text="record.actitle"></span>
            </a>
          </template>
          <template slot="rsflows" slot-scope="record">
            <a-steps progress-dot :current="getstep(record.rsflows,record.statuse)">
                <a-step
                  v-for="item in record.rsflows"
                  :key="item.seq"
                  :title="retname(item,record.statuse)"
                  :description="item.ntname"
                />
              </a-steps>
          </template>
          <template slot="history" slot-scope="record">
            <a-button type="link" size="large" @click="openHistory(record)" shape="circle" icon="history" ></a-button>
          </template>                       
          <template slot="statuse" slot-scope="record">
            <span v-text="convert(record.statuse)"></span>
          </template>
        </a-table>
      </div>
    </div>
    <b-modal
      ref="my-loading"
      id="modal-1"
      size="xl"
      title="活動資訊"
      @hide="onHide"
      centered
      hide-footer
    >
      <div class="overflow-auto" style="height: 600px;width:1024px;" id="style-3">
        <div class="text-left">
          <Inforject v-if="objs!=='{}'" :obj="objs" />
        </div>
      </div>
    </b-modal>
    <b-modal ref="historyMsg" scrollable  id="modal-tall" title="審核歷程" @hide="onHide" centered hide-footer>
        <a-spin tip="資料載入中..." size="large" :spinning="historyShow">
          <div class="spin-content">
            <a-list
              bordered
              item-layout="vertical"
              :data-source="this.myHistory"
              :render-item="renderItem"
            >
            </a-list>            
          </div>
      </a-spin>
    </b-modal>      
  </div>
</template>
<script>
import { actService, macService } from "../../../_services";
import * as moment from "moment/moment";
import Inforject from "../../../actinfo/panel/Inforact.vue";
import { mapState } from "vuex";
import 'moment/locale/zh-tw';

const queryData = params => {
  return actService.FindCFMstatus(params);
};

const queryData2 = params => {
  return macService.macfind(params.id);
};
const queryHistory = (params) => {
  return actService.queryHistory(params);
};

const columns = [
  {
    title: "活動日期",
    dataIndex: "",
    width: "10%",
    ellipsis: true,
    scopedSlots: { customRender: "sdate" }
  },
  {
    title: "活動名稱",
    dataIndex: "",
    width: "35%",
    ellipsis: true,
    scopedSlots: { customRender: "actitle" }
  },
  {
    title: " 簽核流程 ",
    dataIndex: "",
    ellipsis: true,
    scopedSlots: { customRender: "rsflows" },
    width: "35%"
  },
  {
    title: "歷程",
    dataIndex: "",
    ellipsis: true,
    scopedSlots: { customRender: "history" },
    width: "10%"
  },   
  {
    title: "狀態",
    dataIndex: "",
    ellipsis: true,
    scopedSlots: { customRender: "statuse" },
    width: "10%"
  }
];

export default {
  name: "Verify_bottomtbar",
  components: {
    Inforject
  },
  data() {
    return {
      pagination: { defaultPageSize: 4 },
      columns,
      loading: false,
      historyShow:true,
      myHistory:[],      
      data: [],
      objs: {},
      current: 0,
      locale:{
        filterConfirm:'確定',
        filterReset: '重置',
        emptyText: '暫時無任何數據'        
      },        
    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
    ...mapState("makeorg", ["defaultval"])
  },
  mounted() {
    this.reload();
  },
  watch: {
    //defaultval : { "name": "雲林縣政府", "id": "b3c7a1dc-e6d4-4fac-b1f7-11a2d54e7a25" }
    defaultval() {
      //this.isBusy = !this.isBusy
      this.reload();
    }
  },
  methods: {
    reload() {
      this.fetch({
        results: 4,
        page: 1,
        pageSize: 4,
        uid: this.defaultval.id
      });
    },

    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        uid: this.defaultval.id,
        ...filters
      });
    },

    fetch(params = {}) {
      this.loading = true;
      queryData({
        ...params
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        pagination.total = data.info.totail;
        this.loading = false;
        this.data = data.results;
        this.pagination = pagination;
      });
    },

    fetch2(params = {}) {
      queryData2({
        ...params
      }).then(({ data }) => {
        this.objs = data;
      });
    },
    //F(結束) N(草稿) I(進行中) Y(上線) cfstate
    retname(va2,state) {
      let tmp = "";
      if(va2.cfstate=="F"){
        if(state=="N"){
          tmp ="草稿"
        }else{
          tmp ="已送呈"
        }
      }
      if(va2.cfstate=="R"){
        if(state=="N"){
          if(va2.seq==0){
            tmp ="草稿"
          }else{
            tmp =""
          }
        }else{
          tmp ="請審核"
        }
      } 
      
      if(va2.cfstate=="I"){
        if(state=="N"){
          tmp =""
        }else{
          tmp ="審核中"
        }
      }         

      if(va2.cfstate=="N"){
        if(state=="N"){
          tmp =""
        }else{
          tmp ="未簽核"
        }
      }

      if(va2.cfstate=="Y"){
        if(state=="N"){
          tmp =""
        }else{
          tmp ="已發佈"
        }
      }      
      return tmp;
    },

    getstep(rs,state) {
      let count = rs.length;
      let j = 0;
      for (var i in rs) {
        if (rs[i].cfstate == "R" && state!=='N') {
          return rs[i].seq;
        }
        if (rs[i].cfstate == "F" && state!=='N') {
          j++;
        }
      }
      if (count == j) {
        return j;
      }
    },

    showinfo(ids) {
      this.fetch2({ id: ids });
      this.$refs["my-loading"].show();
    },

    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    },

    fdateformat(val) {
      return moment(val).format("YYYY-MM-DD");
    },
    ctypes(val) {
      //return狀態 R請審核 未審核, Y已發佈, F結束, D下架
      let tmp = "";
      val == "F" ? (tmp = "已送呈") : "";
      val == "R" ? (tmp = "請審核") : "";
      val == "I" ? (tmp = "審核中") : "";      
      val == "N" ? (tmp = "未審核") : "";
      val == "Y" ? (tmp = "已發佈") : "";
      return tmp;
    },
    convert(val) {
      //return狀態 R請審核 未審核, Y已發佈, F結束, D下架
      let tmp = "";
      val == "N" ? (tmp = "草稿") : "";
      val == "F" ? (tmp = "活動結束") : "";
      val == "I" ? (tmp = "審核中") : "";      
      val == "Y" ? (tmp = "已發佈") : "";
      return tmp;
    },

    openHistory(val){
      this.myHistory=[];
      this.historyShow=true ;
      this.$refs["historyMsg"].show();
      setTimeout(async() => {
        this.myHistory=await this.GetHistory(val.fid); 
        this.historyShow=false;             
         clearInterval();
      }, 800);      
    },

    GetHistory(id) {
      let params = {id}            
      return new Promise((resolve) => {        
        resolve(          
          queryHistory({...params}).then(({ data }) => data)          
        );
      });
    },
    
    renderItem(item) {
      return (
        <a-list-item>
          <a-list-item-meta
            title={
              <div>
                <strong>審核者：</strong> {item.displayname}
                <span style="margin-left: 10px; color: {item.statuse === 'F' ? 'green' : 'red'};">
                  <strong>狀態：</strong> {this.getStatusText(item.statuse)}
                </span>
              </div>
            }
            description={
              <span>
                <strong>審核時間：</strong>{" "}
                {moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}
              </span>
            }
          />
          <div>
            <strong>說明：</strong> {item.desc}
          </div>
        </a-list-item>
      );
    }, 
    
    getStatusText(statuse) {
      return statuse === "F" ? "完成" : "退回";
    },      

  }
};
</script>
<style scoped>
.myTable thead {
  background-color: #000000;
}
#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #000000;
}

#style-3 {
  text-align: left;
}
.ddic {
  padding: 20px;
  font-size: 14px;
  text-align: left;
  border-radius: 15px;
  border-width: 1px;
  height: 400px;
  margin: 13px auto;
  border-style: dotted;
  border-color: #e0dedc;
  transition: all 0.3s ease;
}
.divs {
  max-height: 335px;
}
</style>
<style>
.hidden_cssd {
  border-top: 1px solid #f1f1f0;
  border-bottom: 3px solid #ebebe8;
  border-style: dotted;
}
</style>