<template>
  <div class="about">
    <div class="d-flex flex-column min-vh-100">
      <Evenmenubar :title="title" />
      <div class="tabbar">
        <a @click="selectitem('theme')" href="javascript: void(0)"
          ><b-icon icon="bookmark-check-fill" font-scale="1.2"></b-icon>
          選擇活動主題</a
        >
        <a @click="selectitem('type')" href="javascript: void(0)"
          ><b-icon icon="calendar3-range-fill" font-scale="1.2"></b-icon>
          選擇活動類型</a
        >
        <a @click="selectitem('info')" href="javascript: void(0)"
          ><b-icon icon="file-bar-graph-fill" font-scale="1.2"></b-icon>
          活動資訊</a
        >
        <a @click="selectitem('content')" href="javascript: void(0)"
          ><b-icon icon="file-earmark-text-fill" font-scale="1.2"></b-icon>
          活動內容</a
        >
        <a @click="selectitem('coach')" href="javascript: void(0)"
          ><b-icon icon="file-earmark-person-fill" font-scale="1.2"></b-icon>
          講師</a
        >
        <a @click="selectitem('volunteer')" href="javascript: void(0)"
          ><b-icon icon="file-earmark-person-fill" font-scale="1.2"></b-icon>
          工作人員</a
        >
        <a @click="selectitem('form')" href="javascript: void(0)"
          ><b-icon icon="back" font-scale="1.2"></b-icon> 設定報名表</a
        >
        <a @click="selectitem('mfee')" href="javascript: void(0)"
          ><b-icon icon="back" font-scale="1.2"></b-icon> 設定活動費用</a
        >
        <a
          v-if="edits == 'add'"
          @click="showChose()"
          href="javascript: void(0)"
        >
          <b-icon
            icon="file-earmark-plus-fill"
            font-scale="1.2"
            variant="warning"
          ></b-icon>
          複製該活動
        </a>
        <a
          v-if="edits !== 'add'"
          @click="confirms()"
          href="javascript: void(0)"
        >
          <b-icon
            icon="play-circle-fill"
            font-scale="1.2"
            variant="warning"
          ></b-icon>
          送出審核
        </a>
        <a @click="previous()" href="javascript: void(0)"
          ><b-icon icon="arrow-left-circle" font-scale="1.2"></b-icon>
          (回上一頁)</a
        >
      </div>
      <a-spin :spinning="spinning" tip="Loading...">
        <ul>
          <li>
            <div class="mt-3" v-if="show[0]">
              <Option :obj="objs" @updage="updage" ref="con0" />
            </div>
            <div class="mt-3" v-if="show[1]">
              <Actypes :obj="objs" @updage="updage" ref="con1" />
            </div>
            <div class="mt-3" v-if="show[2]">
              <Mactivity :obj="objs" @updage="updage" ref="con2" />
            </div>
            <div class="mt-3" v-if="show[3]">
              <Macdecs :obj="objs" @updage="updage" ref="con3" />
            </div>
            <div class="mt-3" v-if="show[4]">
              <Mcoach
                :obj="objs"
                @checkstaff="checkstaff"
                @updage="updage"
                ref="con4"
              />
            </div>
            <div class="mt-3" v-if="show[5]">
              <Mform :obj="objs" @updage="updage" ref="con5" />
            </div>
            <div class="mt-3" v-if="show[6]">
              <Mfee :obj="objs" @updage="updage" ref="con6" />
            </div>
            <div class="mt-3" v-if="show[7]">
              <Volunteer
                :obj="objs"
                @checkstaff="checkstaff"
                @updage="updage"
                ref="con7"
              />
            </div>
          </li>
        </ul>
      </a-spin>
      <div class="wrapper flex-grow-1"></div>
      <b-alert
        v-model="showTop"
        class="position-fixed fixed-bottom m-0 rounded-0"
        style="z-index: 2000"
        variant="warning"
        dismissible
      >
        資料更新成功
      </b-alert>
      <Efootbar msg="test" />
    </div>
    <b-modal
      ref="my-chose"
      id="modal-1"
      title="複製該活動"
      @hide="onHide"
      centered
      hide-footer
    >
      <div class="text-center">
        <a-button-group>
          <a-button size="large" @click="copies('N')" :loading="loadings"
            >存成草稿</a-button
          >
          <a-button
            size="large"
            @click="copies('I')"
            :loading="loadings"
            type="primary"
          >
            送出審核
          </a-button>
        </a-button-group>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { macService } from "../_services";
import * as moment from "moment/moment";
import Option from "../act/Option.vue";
import Actypes from "../act/Actypes";
import Mactivity from "../act/Mactivity";
import Macdecs from "../act/Macdecs";
import Mcoach from "../act/Mcoach";
import Volunteer from "../act/Volunteer";
import Mform from "../act/Mform";
import Mfee from "../act/Mfee";
import Evenmenubar from "@/components/Evenmenubar.vue";
import Efootbar from "@/components/Efootbar.vue";
import router from "../router";

const queryData = (params) => {
  return macService.macfind(params.id);
};

const updatedata = (obj) => {
  return macService.macupdate(obj);
};

const newData = (obj) => {
  return macService.macwrite(obj);
};

export default {
  name: "Actedite",
  components: {
    Evenmenubar,
    Efootbar,
    Option,
    Actypes,
    Mactivity,
    Macdecs,
    Mcoach,
    Volunteer,
    Mform,
    Mfee,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      title: { title: "活動調整", logo: "flag" },
      edits: "revise",
      objs: {},
      show: [false, false, false, false, false, false, false, false],
      uId: "",
      loadings: false,
      spinning: false,
      getini: 1,
      showTop: false,
      cstate: "N",
    };
  },
  async mounted() {
    //console.log(this.$route.query.userId);
    this.uId = this.$route.params.id;
    this.edits = this.$route.params.edit;
    await this.fetch({ id: this.uId });
  },
  methods: {
    fetch(params = {}) {
      queryData({
        ...params,
      }).then(({ data }) => {
        this.objs = data;
        if (this.getini == 1) {
          this.show = [false, false, false, false, false, false, false, false];
          this.show[0] = true;
        }
        this.getini == 0;
      });
    },

    async updata(params = {}) {
      return await updatedata({
        ...params,
      }).then(({ data }) => {
        let obj = data;
        return obj;
      });
    },

    async newsdata(params = {}) {
      return await newData({
        ...params,
      }).then(({ data }) => {
        let obj = data;
        return obj;
      });
    },

    fdateformat(val) {
      return moment(val).format("YYYY-MM-DD HH:mm");
    },

    previous() {
      router.go(-1);
    },

    showChose() {
      this.$refs["my-chose"].show();
    },

    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    },
    selectitem(val) {
      this.show = [false, false, false, false, false, false, false, false];
      val == "theme" ? (this.show[0] = true) : (this.show[0] = false);
      val == "type" ? (this.show[1] = true) : (this.show[1] = false);
      val == "info" ? (this.show[2] = true) : (this.show[2] = false);
      val == "content" ? (this.show[3] = true) : (this.show[3] = false);
      val == "coach" ? (this.show[4] = true) : (this.show[4] = false);
      val == "form" ? (this.show[5] = true) : (this.show[5] = false);
      val == "mfee" ? (this.show[6] = true) : (this.show[6] = false);
      val == "volunteer" ? (this.show[7] = true) : (this.show[7] = false);
    },
    async copies(va1) {
      this.spinning = true;
      this.objs.id = "";
      this.loadings = true;
      this.objs.states = va1;
      let rsults = await this.newsdata(this.objs);
      if (rsults.allow == true) {
        this.loadings = false;
        this.spinning = false;
        this.$refs["my-chose"].hide();
        this.$message.success("建立活動成功");
        this.showTop = true;
        setTimeout(() => {
          this.showTop = false;
          //window.location.href="about:blank"
          //window.close();
          router.go(-1);
          }, 1600);

      }
    },
    checkstaff(va1, va2) {
      let v1 = 0
      if(this.objs.coachs!==null){
        v1 = 
        this.objs.coachs.filter((el) => {
          return el.uid === va1[0].id;
        }).length;
      }

      let v2 = this.objs.volunteers.filter((el) => {
        return el.uid === va1[0].id;
      }).length;
      if (v1 == 0 && v2 == 0) {
        if (va2 == "coach") {
          this.$refs["con4"].merrors = "";
          this.$refs["con4"].form.name =
            va1[0].username == "" ? va1[0].display_name : va1[0].username;
          this.$refs["con4"].form.url = va1[0].pri_url;
          this.$refs["con4"].form.uid = va1[0].id;
        } else {
          this.$refs["con7"].merrors = "";
          this.$refs["con7"].form.name =
            va1[0].username == "" ? va1[0].display_name : va1[0].username;
          this.$refs["con7"].form.url = va1[0].pri_url;
          this.$refs["con7"].form.uid = va1[0].id;
        }
      } else {
        let tmp = va1[0].username == "" ? va1[0].display_name : va1[0].username;
        if (va2 == "coach") {
          this.$refs["con4"].merrors = tmp + "該人員已被設定講師或工作人員";
        } else {
          this.$refs["con7"].merrors = tmp + "該人員已被設定講師或工作人員";
        }
      }
    },
    confirms() {
      this.updage(8, null);
    },
    async updage(type, rs) {
      /*console.log(type)
      console.log(rs[0].value)*/
      this.objs.states = this.cstate;
      if (type === 0) {
        //活動主題
        this.objs.theme = rs[0].value;
        if (this.edits == "revise") {
          let rsults = await this.updata({ kinds: type, acts_mact: this.objs,allow:false });
          console.log(rsults);
          rsults.allow == true ? (this.showTop = true) : (this.showTop = false);
          setTimeout(() => {
            if (rsults.allow == true) {
              this.$refs["con0"].updone();
              this.showTop = false;
            }
          }, 1600);

        } else {
          this.$refs["con0"].updone();
        }
      } else if (type === 1) {
        //活動類型
        this.objs.tid = rs.id;
        this.objs.title = rs.title;
        this.objs.vals = rs.vals;
        this.objs.aid = rs.items_type.id;
        this.objs.atitle = rs.items_type.title;
        this.objs.descs = rs.items_type.descs;
        this.objs.broker = rs.items_type.broker;
        this.objs.coach = rs.items_type.coach;
        this.objs.volunteer = rs.items_type.volunteer;
        this.objs.actor = rs.items_type.actor;
        if (this.edits == "revise") {
          let rsults = await this.updata({ kinds: type, acts_mact: this.objs,allow:false });
          rsults.allow == true ? (this.showTop = true) : (this.showTop = false);
          setTimeout(() => {
            if (rsults.allow == true) {
              this.$refs["con1"].updone();
              this.showTop = false;
            }
          }, 1600);

        } else {
          this.$refs["con1"].updone();
        }
      } else if (type === 2) {
        //主題資訊
        this.objs.url = rs.url;
        this.objs.actitle = rs.actitle;
        this.objs.sdate = rs.sdate;
        this.objs.stime = rs.stime.split(":").slice(0, 2).join(":");
        this.objs.edate = rs.edate;
        this.objs.etime = rs.etime.split(":").slice(0, 2).join(":");
        this.objs.email = rs.email;
        this.objs.hphone = rs.hphone;
        this.objs.mphone = rs.mphone;
        this.objs.address = rs.address;
        this.objs.sinkurl = rs.sinkurl;
        this.objs.finkurl = rs.finkurl;
        this.objs.lat = rs.center.lat;
        this.objs.lng = rs.center.lng;
        this.objs.weekeds = rs.weeked;
        this.objs.checked = rs.limits.checked;
        this.objs.value = rs.limits.value;
        this.objs.datetype = rs.datetype.checked;
        this.objs.multiples = rs.multiple;
        if (this.edits == "revise") {
          let rsults = await this.updata({ kinds: type, acts_mact: this.objs,allow:false });
          rsults.allow == true ? (this.showTop = true) : (this.showTop = false);
          setTimeout(() => {
            if (rsults.allow == true) {
              this.$refs["con2"].updone();
              this.showTop = false;
            }
          }, 1600);

        } else {
          this.$refs["con2"].updone();
        }
      } else if (type === 3) {
        //主題內容
        this.objs.content = rs.content;
        this.objs.desc = rs.desc;
        if (this.edits == "revise") {
          let rsults = await this.updata({ kinds: type, acts_mact: this.objs,allow:false });
          rsults.allow == true ? (this.showTop = true) : (this.showTop = false);
          setTimeout(() => {
            if (rsults.allow == true) {
              this.$refs["con3"].updone();
              this.showTop = false;
            }
          }, 1600);          
        } else {
          this.$refs["con3"].updone();
        }
      } else if (type === 4) {
        // 講師
        this.objs.coachs = rs;
        if (this.edits == "revise") {
          let rsults = await this.updata({ kinds: type, acts_mact: this.objs,allow:false });
          rsults.allow == true ? (this.showTop = true) : (this.showTop = false);
          setTimeout(() => {
            if (rsults.allow == true) {
              if(rs==null){
                this.$refs["con4"].updone2();
              }else{
                this.$refs["con4"].updone();
              }
              this.showTop = false;
            }
          }, 1600);

        } else {
          this.$refs["con4"].updone();
        }
      } else if (type === 5) {
        // 報名表
        this.objs.options = rs;
        if (this.edits == "revise") {
          let rsults = await this.updata({ kinds: type, acts_mact: this.objs,allow:false });
          rsults.allow == true ? (this.showTop = true) : (this.showTop = false);
          setTimeout(() => {
            if (rsults.allow == true) {
              this.$refs["con5"].updone();
              this.showTop = false;
            }
          }, 1600);

        } else {
          this.$refs["con5"].updone();
        }
      } else if (type === 6) {
        // 設定活動費用
        let tmp = [];
        for (var dobj in rs.item) {
          let tdata = {
            id: rs.item[dobj].id,
            desc: rs.item[dobj].desc,
            title: rs.item[dobj].title,
            fee: parseInt(rs.item[dobj].fee),
            url: rs.item[dobj].url,
          };
          tmp.push(tdata);
        }
        this.objs.fee = rs.fee;
        this.objs.bcode = rs.bcode;
        this.objs.account = rs.account;
        this.objs.items = tmp;
        if (this.edits == "revise") {
          let rsults = await this.updata({ kinds: type, acts_mact: this.objs,allow:false });
          rsults.allow == true ? (this.showTop = true) : (this.showTop = false);
          setTimeout(() => {
            if (rsults.allow == true) {
              this.$refs["con6"].updone();
              this.showTop = false;
            }
          }, 1600);          

        } else {
          this.$refs["con6"].updone();
        }
      } else if (type === 7) { // 設定工作人員
        this.objs.volunteers = rs;
        if (this.edits == "revise") {
          let rsults = await this.updata({ kinds: type, acts_mact: this.objs,allow:false });
          rsults.allow == true ? (this.showTop = true) : (this.showTop = false);
          setTimeout(() => {
            if (rsults.allow == true) {
              this.$refs["con7"].updone();
              this.showTop = false;
            }
          }, 1600);
        } else {
          this.$refs["con7"].updone();
        }
      } else if (type == 8) { //設定為審核狀態
        this.spinning = true;
        this.objs.states = "I";
        let rsults = await this.updata({ kinds: type, acts_mact: this.objs,allow:false });
        rsults.allow == true ? (this.showTop = true) : (this.showTop = false);
        setTimeout(() => {
          if (rsults.allow == true) {
            this.showTop = false;
            this.spinning = false;
            router.go(-1);
          }
        }, 1600);

      }
    },
  },
};
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.tabbar {
  margin-top: 4px;
  background-color: #077381;
  overflow: hidden;
}

.tabbar a {
  float: left; /* 浮動式表示讓顯示由左而右顯示 */
  color: #f2efef;
  text-align: center;
  padding: 14px 14px;
  text-decoration: none; /*文字裝飾器*/
  font-size: 14px;
}

.tabbar a:hover {
  background-color: #b1b0b0;
  color: black;
}

.tabbar a.active {
  background-color: #a262fa;
}
</style>

