<template>
  <div class="container-fluid">
    <div class="row">
        <h6 class="mb-0">
          <span v-text="'活動名稱: '+jdata.actitle + ' | 活動起訖時間 : '+jdata.sdate+'~'+jdata.edate" />
        </h6>
        <a-calendar
          :defaultValue="currentTime"
          :style="{ height:'49.8rem' ,border: '1px solid #d9d9d9', borderRadius: '4px' }"
          :locale="locale"
          :fullscreen="true"  
          class="overflow-hidden"
        >
          <div slot="dateCellRender" slot-scope="value" class="events" style="overflow-x: hidden;overflow-y:hidden;">
            <div v-for="item  in getListData(value)" :key="item.content"  >
              <div class="notes_css" @click="showDetail(item)" :style="{'background-color': item.type==='Y'?'#09628e':'#444647','cursor':'pointer'}">   
                <div style="color:#f8fafb;font-size:10px;text-align: center;margin-top: 0.45rem;" v-html="getpeople(item.actstaff)" />                           
              </div>
            </div>
          </div> 
          <template slot="monthCellRender" slot-scope="value">
            <div v-if="getMonthData(value)" class="notes-month">
              <section>{{ getMonthData(value) }}</section>
              <span>Backlog number</span>
            </div>
          </template>
        </a-calendar>

        <b-modal
          ref="my-detail"
          id="modal-1"
          title="活動人員資料"
          @hide="onHide"
          scrollable
          centered
          hide-footer
        >
      <div class="text-center">
        <a-list v-if="sData!==null" bordered :data-source="sData.actstaff">
          <a-list-item slot="renderItem" slot-scope="item">
            <span v-html="convertxt(item)" />
          </a-list-item>
        </a-list>
      </div>
      </b-modal>       

    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import * as moment from "moment/moment";
import 'moment/locale/zh-tw';
import { actService } from "@/_services";

const queryData = params => {
  return actService.findActIdes(params.id);
};

export default {
  name: "ActDate",
  props: {
    roCid: {
      type: String
    },
  },
  data() {
    return {
      jdata: {},
      information: [],
      uid: "",
      sData:null,
      locale:{
        lang:{
          month:'月',
          year:'年',
        }
      },
      currentTime: moment()
    };
  },
  computed: {
    ...mapState("account", ["status", "user"])
  },
  methods: {
    getListData(value) {
      let listData = [];
      if (this.jdata.acts_actclass != undefined) {
        this.jdata.acts_actclass.forEach(item => {
          if (this.getdate(item.sdate) == value.format("YYYY-MM-DD")) {
            listData.push({
              type:
                this.getime(item.sdate) >=
                this.currentTime.format("YYYY-MM-DD HH:mm")
                  ? "Y"
                  : "N",
              aid: item.aid,
              id: this.jdata.id,
              orgid: this.jdata.orgid,
              title: this.jdata.actitle,
              fid: this.jdata.fid,
              url: this.jdata.url,
              sdate: item.sdate,
              edate: item.edate,
              status: item.status,
              actstaff: item.acts_actstaff,
              limits: item.limits
            });            
          }
        });
        return listData || [];
      }
      return listData || [];
    },

    getpeople(rs){
          let d1=0,d2=0,d3=0,d4=0          
          for(var j in rs){
            rs[j].roles=='volunteer'? d1=d1+1:''
            rs[j].roles=='coach'? d2=d2+1:''
            rs[j].roles=='actor'? d3=d3+1:''
            rs[j].roles=='actor' && rs[j].sid>0? d4=d4+1:''
          }  
          //return '講師 :'+ d2 +'  工作人員 : '+ d1 +'<br/>  參加者 : ' +  d3  + ' 報到 : ' + d4; 
          return   '報名<br/> '+d3 +''  + '<br/>報到<br/> '+ d4 +'';               
    }, 

    showDetail(obj){
      this.sData = obj
      this.$refs["my-detail"].show();
    },

    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    },        
    
    convertxt(item){
       let tmp
       item.roles=='coach'? tmp='講師 : '+item.username:'' 
       item.roles=='volunteer'? tmp='工作人員 : '+item.username:''
       item.roles=='actor'? tmp='參加者 : '+item.username:''
       item.sid>0?tmp+=' <font style="color:green">(已報到)</font>':tmp+=' <font style="color:red">(未報到)</font>'       
       return tmp

    },

    onclick(val) {
      console.log(val);
    },

    onSelect(e) {
      var A = Date.now();
      let cdate = moment(e).format("YYYY-MM-DD");
      let nowdate = moment(A).format("YYYY-MM-DD");
      let edate = moment(this.jdata.edate).format("YYYY-MM-DD");      
      if (cdate >= nowdate && cdate <= edate) {
        let tmpArr = this.jdata.acts_actclass.filter(
          rs => this.getdate(rs.sdate) == moment(e).format("YYYY-MM-DD")
        );
        if (tmpArr.length == 0) {
          console.log('無資料, 可新增')
        }
      }
    },

    getMonthData(value) {
      if (value.month() === 8) {
        return 1394;
      }
    },
    getdate(val) {
      return moment(val).format("YYYY-MM-DD");
    },

    getime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm");
    },

    fetch(params = {}) {
      queryData({
        ...params //...解壓縮出來
      }).then(({ data }) => {
        this.jdata = data;
        this.$emit('done',false)
      });
    },
    
    loadData(ids){
      this.fetch({ id: ids });
    }
  },
};
</script>
<style >
.notes_css {
  position: absolute;
  border-radius: 8px;
  top: 25%;
  left: 0;
  width: 80%;
  height: 70%;
  text-align: center; /* 文本水平居中 */
  vertical-align: middle; /* 文本垂直居中 */  
  cursor:pointer;
}
  .ant-fullcalendar-content {
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .ant-fullcalendar-header .ant-radio-group {
      display: none !important;
  }
  .ant-popover{
    z-index: 9999;
  }
</style>