<template>
  <div>
    <div class="ddic">        
        <b-icon icon="collection-fill" scale="1" variant="secondary"></b-icon>
        組織團體審核紀錄
        <div class="divs"> 
          <a-table
          :columns="columns"
          :row-key="record => record.id"
          :data-source="data"
          :pagination="pagination"
          :loading="loading"
          :locale="locale"  
          @change="handleTableChange"
          :scroll="{ y: 230 }"
        >
          <template slot="name" slot-scope="record">
            <a href="javascript:;" @click="showinfo(record)"> <span v-text="record.name"></span></a>
          </template>
          <template slot="type" slot-scope="record">
            <span v-text="convertname(record.type)"></span>
          </template>          
          <template slot="ntype" slot-scope="record">
            <span v-text="convertname(record.ntype)"></span>
          </template> 
          <template slot="history" slot-scope="record">
            <a-button type="link" size="large" @click="openHistory(record)" shape="circle" icon="history" ></a-button>
          </template>               
          <template slot="state" slot-scope="record">
              <span v-text="cstate(record.state)"></span>                      
          </template>            
        </a-table>

        </div>           
    </div>
        <b-modal ref="my-loading" id="modal-1" size="md" title="組織單位資訊" @hide="onHide" centered hide-footer>        
          <div class="overflow-auto" style="height: 600px;" id="style-3">
            <div class="text-left">                     
                <Orginforject :obj="orgdatas" />
            </div>
          </div>         
        </b-modal> 
        <b-modal ref="historyMsg" scrollable  id="modal-tall" title="審核歷程" @hide="onHide" centered hide-footer>
        <a-spin tip="資料載入中..." size="large" :spinning="historyShow">
          <div class="spin-content">
            <a-list
              bordered
              item-layout="vertical"
              :data-source="this.myHistory"
              :render-item="renderItem"
            >
            </a-list>            
          </div>
      </a-spin>
    </b-modal>         
  </div>
  
</template>
<script>  
import { makorgService } from '../../../_services'
import Orginforject from "../../../actinfo/panel/Orginforject.vue"
import { mapState } from "vuex";
import * as moment from "moment/moment";
import 'moment/locale/zh-tw';

//const queryData = params => {
  //return makorgService.readorglist(params.id,params.modes) 
//this}; 

const queryData = params => {
  return makorgService.readorgpage(params) 
}; 
const queryHistory = (params) => {
  return makorgService.queryHistory2(params);
};

const columns = [
  {
    title: "組織名稱",
    dataIndex: "",
    width: "25%",
    ellipsis: true,
    scopedSlots: { customRender: "name" }
  },
  {
    title: "目前層級",
    dataIndex: "",
    width: "25%",
    ellipsis: true,
    scopedSlots: { customRender: "type" }
  },
  {
    title: " 上一階層 ",
    dataIndex: "",
    ellipsis: true,
    scopedSlots: { customRender: "ntype" },
    width: "20%"
  },
  {
    title: "歷程",
    dataIndex: "",
    ellipsis: true,
    scopedSlots: { customRender: "history" },
    width: "10%"
  },   
  {
    title: "狀態",
    dataIndex: "",
    ellipsis: true,
    scopedSlots: { customRender: "state" },
    width: "20%"
  }
];


export default {
  name: 'Check_bottomtbar',
  components: {
    Orginforject,
  },       
  data() {
    return {
      pagination: { defaultPageSize: 4 },
      columns,
      loading: false,
      data: [],
      locale:{
        filterConfirm:'確定',
        filterReset: '重置',
        emptyText: '暫時無任何數據'        
      },
      historyShow:true,
      myHistory:[],         
      orgdatas:{},
      objs: {}, 
      current:0,
      isBusy: false,     
    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
    ...mapState("makeorg", ["defaultval"])
  },
  mounted() {
    this.inid()
  },
  watch: {
    //defaultval : { "name": "雲林縣政府", "id": "b3c7a1dc-e6d4-4fac-b1f7-11a2d54e7a25" }
    defaultval(){  
      this.inid()         
    }
  },
  methods: {

    inid(){
      this.fetch({
        results: 4,
        page: 1,
        pageSize: 4,
        uid: this.defaultval.id,
        modes: 'Y'
      }); 
    },

    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        uid: this.defaultval.id,
        modes: 'Y',
        ...filters
      });
    },

    fetch(params = {}) {
      this.loading = true;
      queryData({
        ...params
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        pagination.total = data.info.totail;
        this.loading = false;
        this.data = data.results;
        this.pagination = pagination;
      });
    },   

    showinfo(value) {
      this.orgdatas = value
      this.$refs['my-loading'].show()
    },  
    


    onHide(evt) {
        if(evt.trigger === "backdrop"){
            evt.preventDefault();
        }
    },  

    convertname(va1){
      let tmp=""
      va1=="org"? tmp="據點/團體組織":"";
      va1=="town"? tmp="局處/鄉鎮公所":"";
      va1=="county"? tmp="縣市政府":"";
      va1=="admin"? tmp="最高權限管理群組":"";
      return tmp
    },

    cstate(val){
      //return狀態 N草稿, I審核中, R發佈中, F結束, D下架
      let tmp=""
      val=="N"? tmp="尚未審核通過":"";
      val=="Y"? tmp="已審核":"";
      return tmp
    },
    ctypes(val){
      //return狀態 N草稿, I審核中, R發佈中, F結束, D下架
      let tmp=""
      val=="N"? tmp="請審核":"";
      val=="Y"? tmp="已審核":"";
      return tmp
    },

    openHistory(val){
      this.myHistory=[];
      this.historyShow=true ;
      this.$refs["historyMsg"].show();
      setTimeout(async() => {
        this.myHistory=await this.GetHistory(val.id); 
        this.historyShow=false;             
         clearInterval();
      }, 800);      
    },

    GetHistory(id) {
      let params = {id}            
      return new Promise((resolve) => {        
        resolve(          
          queryHistory({...params}).then(({ data }) => data)          
        );
      });
    },
    getStatusText(statuse) {
      return statuse === "Y" ? "審核通過" : "撤銷";
    },     
    renderItem(item) {
      return (
        <a-list-item>
          <a-list-item-meta
            title={
              <div>
                <strong>審核者：</strong> {item.displayname}
                <span style="margin-left: 10px; color: {item.statuse === 'Y' ? 'green' : 'red'};">
                  <strong>狀態：</strong> {this.getStatusText(item.statuse)}
                </span>
              </div>
            }
            description={
              <span>
                <strong>審核時間：</strong>{" "}
                {moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}
              </span>
            }
          />
          <div>
            <strong>審核單位：</strong> {item.pidname}
          </div>
        </a-list-item>
      );
    },     
  },
};
</script>
<style scoped>
.myTable thead {
   background-color: #000000;
}
#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #000000;
}

#style-3{
    text-align: left;
}
.ddic{
    padding:20px;
    font-size: 14px;
    text-align: left;
    border-radius: 15px;
    border-width:1px;
    height:400px;
    margin: 13px auto;
    border-style:dotted;
    border-color:#e0dedc;
	transition: all .3s ease;
}
.divs{
    overflow: auto;
    height:385px;
}
</style>
<style>
.hidden_cssd {
  border-top:1px solid #f1f1f0; 
  border-bottom:3px solid #ebebe8;
  border-style:dotted;
}
</style>