<template>
    <div>   

        <div class="card text-center">
            <div class="card-header">
                <h6><b-icon icon="front"></b-icon>活動類型管理</h6>
                <ul class="nav nav-tabs card-header-tabs">
                    <li class="nav-item">
                        <span class="nav-link active" aria-current="true">     
                            類別清單                                                   
                        </span>
                    </li>           
                </ul>
            </div>
            <div class="card-body">
                <div class="text-left" style="padding:15px;">
                    

                    <a-button-group style="margin-bottom: 0.3rem;">
                        <a-button class="bton"  @click="openedit(null)" >新增基本檔</a-button>
                        <a-button type="primary" @click="GetReport()">出表</a-button>
                    </a-button-group>

                    <p class="card-text">
                    <a-table
                        :columns="columns"
                        :row-key="record => record.id"
                        :data-source="data"
                        :pagination="pagination"
                        :loading="loading"
                        :locale="locale"
                        @change="handleTableChange"
                        bordered
                    >

                        <p slot="expandedRowRender" slot-scope="record" style="margin: 0;">
                                <a-button class="editable-add-btn" icon="plus-square" @click="additem(record)" type="dashed">
                                新增子類別
                                </a-button>
                            <template v-if="record.items_type.length > 0">
                                <div class="ddic">
                                    <a-table :columns="decolumn" :locale="locale" :data-source="record.items_type" :row-key="record => record.id" :pagination="paginationx" bordered>
                                        <a slot="action" slot-scope="obj">
                                            <a class="nav-link active" aria-current="true" @click="editdetail(obj,record)" href="javascript:;"><span style="color:#1fac07;">修改</span></a>
                                        </a>
                                    </a-table>
                                </div>
                            </template>
                        </p>  

                        <template slot="models" slot-scope="models"> 
                        <div v-show="models=='N'"><span class="text-danger" style="cursor: pointer;">未確認</span></div>
                        <div v-show="models=='Y'"><span class="text-success">已確認</span></div>
                        </template>                       
                        <template slot="created_at" slot-scope="created_at"> {{ fdateformat(created_at) }}</template>
                        <a slot="action" slot-scope="obj">
                            <a class="nav-link active" aria-current="true" @click="openedit(obj)" href="javascript:;"><span style="color:#ac0e0e;">調整</span></a>
                        </a>
                    </a-table>
                    </p>
                </div>
            </div>
        </div>               
            
      <b-modal ref="my-loading" id="modal-1" title="新增類型基本檔" @hide="onHide" centered hide-footer>
        <div class="text-center">  
            <a-alert v-if="mess!=''" :message="mess" type="success" />
        </div>                       
          <div class="text-left">  
                <b-form-group id="input-group-1" style="width:90%;" label="輸入標題(中文)*" label-for="input-1">
                    <b-form-input
                        id="input-1"
                        v-model="form.title"
                        type="text"
                        maxlength=10
                        placeholder="輸入標題(中文)"
                        autocomplete="off"
                        :state="ctitleState"                        
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group id="input-group-2" style="width:90%;" label="輸入標題(英文)*" label-for="input-2">
                    <b-form-input
                        id="input-2"
                        v-model="form.vals"
                        type="text"
                        maxlength=15
                        placeholder="輸入標題(英文)"
                        autocomplete="off"
                        :state="etitleState"                        
                        required
                    ></b-form-input>
                </b-form-group>                                   
          </div>
        <div class="text-center">
           <a-button class="bton" shape="round" v-if="vshow" @click="submits('add')"  icon="plus-circle" :disabled="!ctitleState || !etitleState" :loading="iconLoading">新增</a-button>&emsp;
           <a-button class="bton" shape="round" v-if="!vshow" @click="submits('up')"  icon="cloud-sync"   :disabled="!ctitleState || !etitleState" :loading="iconLoading">更新</a-button>&emsp; 
           <a-button class="bton" shape="round" v-if="!vshow" @click="submits('del')"  icon="delete"      :disabled="!ctitleState || !etitleState"   :loading="iconLoading">刪除</a-button>                                
        </div>        
      </b-modal>  

      <b-modal ref="my-loading2" id="modal-2" title="新增子類別基本檔" @hide="onHide" centered hide-footer>
        <div class="text-center">  
            <a-alert v-if="mess2!=''" :message="mess2" type="success" />
        </div>                       
          <div class="text-left">  
                全部學分 : <span style="color:#0b07ac;" v-text="parseInt(dform.broker)+parseInt(dform.coach)+parseInt(dform.volunteer)+parseInt(dform.actor)"></span>&emsp; 
                <span style="color:#dc0b1f;" v-if="(parseInt(dform.broker)+parseInt(dform.coach)+parseInt(dform.volunteer)+parseInt(dform.actor))>100" v-text="errs"></span>
                <b-form-group id="inp-1" style="width:90%;" label="子類別名稱*" label-for="inp-1">
                    <b-form-input
                        id="inp-1"
                        v-model="dform.title"
                        type="text"
                        maxlength=50
                        placeholder="輸入子類別名稱"
                        :disabled="!vshow2"
                        autocomplete="off"
                        :state="dtitleState"                        
                        required
                    ></b-form-input>
                </b-form-group>

                <b-form-group id="inp-2" style="width:90%;" label="子類別描述*" label-for="inp-2">
                <b-form-textarea
                        id="inp-2"
                        v-model="dform.descs"
                        type="text"
                        maxlength=250
                        rows="4"
                        placeholder="輸入子類別描述"
                        autocomplete="off"
                        :state="descsState"                        
                        required
                    ></b-form-textarea>
                </b-form-group>  
                           

                <b-form-group id="inp-3" style="width:30%;" label="主辦單位學分*" label-for="inp-3">
                    <b-form-input
                        id="inp-3"
                        v-model="dform.broker"
                        type="number"
                        max="99"
                        min="1"
                        placeholder="輸入 : 主辦單位學分"
                        autocomplete="off"
                        :state="brokerState"                        
                        required
                    ></b-form-input>
                </b-form-group> 

                <b-form-group id="inp-4" style="width:30%;" label="講師學分*" label-for="inp-4">
                    <b-form-input
                        id="inp-4"
                        v-model="dform.coach"
                        type="number"
                        max="99"
                        min="1"
                        placeholder="輸入 : 講師員學分"
                        autocomplete="off"
                        :state="coachState"                        
                        required
                    ></b-form-input>
                </b-form-group>   

                <b-form-group id="inp-5" style="width:30%;" label="工作人員學分*" label-for="inp-5">
                    <b-form-input
                        id="inp-5"
                        v-model="dform.volunteer"
                        type="number"
                        max="99"
                        min="1"
                        placeholder="輸入 : 工作人員學分"
                        autocomplete="off"
                        :state="volunteerState"    
                        required
                    ></b-form-input>
                </b-form-group>                

                <b-form-group id="inp-6" style="width:30%;" label="參加人員學分*" label-for="inp-6">
                    <b-form-input
                        id="inp-6"
                        v-model="dform.actor"
                        type="number"
                        max="99"
                        min="1"
                        placeholder="輸入 : 參加人員學分"
                        autocomplete="off"
                        :state="actorState"                        
                        required
                    ></b-form-input>
                </b-form-group>
                                  
          </div>
        <div class="text-center">
            <hr class="hr-dashed-fixed">  
             <a-button class="bton" shape="round" v-if="vshow2" @click="submits2('add')"  icon="plus-circle" :disabled="!actorState || !volunteerState || !coachState || !brokerState || !dtitleState || !descsState" :loading="iconLoading2">新增</a-button>&emsp;
             <a-button class="bton" shape="round" v-if="!vshow2" @click="submits2('up')"  icon="cloud-sync" :disabled="!actorState || !volunteerState || !coachState || !brokerState || !dtitleState || !descsState" :loading="iconLoading2">更新</a-button>&emsp;
             <a-button class="bton" shape="round" v-if="!vshow2" @click="submits2('del')"  icon="delete" :disabled="!actorState || !volunteerState || !coachState || !brokerState || !dtitleState || !descsState" :loading="iconLoading2">刪除</a-button>&emsp;
        </div>        
      </b-modal> 


    <b-modal
        ref="my-typeList"
        id="modal-2"
        title="選項"
        size="lg"
        @hide="onHide"
        scrollable
        centered
        hide-footer
        hide-header
      >
        <div class="text-center">
          <a-button @click="closeModal()" type="link" :disabled="iconLoading2" size="small">(關閉)</a-button>
          <a-button
            type="primary"
            :loading="iconLoading2"
            @click="runReport"
          >
          <b-icon style="margin-right: 0.3rem;" scale="1.1" icon="printer" variant="muted" />
          列印</a-button>
          <div ref="myContents" style="padding: 20px; /* 設置頁面內距 */">
            <table>
                <thead>
                <tr>
                    <th>主類別</th>
                    <th>主類別(ENG)</th>
                    <th>子類別</th>
                    <th>主類別說明</th>
                    <th>主辦單位</th>
                    <th>教練</th>
                    <th>義工</th>
                    <th>參加者</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in reportData" :key="index">
                    <td>{{ item.mtitle }}</td>
                    <td>{{ item.meng }}</td>
                    <td>{{ item.dtitle }}</td>
                    <td>{{ item.descs }}</td>                    
                    <td>{{ item.broker }}</td>
                    <td>{{ item.coach }}</td>
                    <td>{{ item.volunteer }}</td>
                    <td>{{ item.actor }}</td>
                </tr>
                </tbody>
            </table>
          </div>
        </div>
    </b-modal> 

    </div>
</template>

<script>
import { typeService } from '../../../_services'
import * as moment from "moment/moment";
import html2pdf from "html2pdf.js";
import {mapState} from 'vuex';
import 'moment/locale/zh-tw';

const postnew = obj => {
  return  typeService.postnew(obj)
}
const postdetail = obj => {
  return  typeService.postdetail(obj)
}

const putdata = obj =>{
   return  typeService.putdata(obj)   
}

const remtype = obj =>{
   return  typeService.remtype(obj)   
}

const queryData = params => {
  return typeService.finditems(params.page) 
}; 

const updata = obj => {
  return  typeService.updata(obj)
}

const deldata = obj => {
  return  typeService.deldata(obj.id)
}

const queryReport = ()=> {
  return typeService.findTypeList() 
}; 


const columns = [
  {
    title: '類別(中)',
    dataIndex: 'title',
    width: '30%',    
    scopedSlots: { customRender: 'title' },    
  }, 
  {
    title: '類別(英)',
    dataIndex: 'vals',
    width: '30%',
  },   
  {
    
    title: '狀態',
    dataIndex: 'models',
    width: '10%',    
    scopedSlots: { customRender: 'models' },     
  },      
  {
    title: '刊登日期',
    dataIndex: 'created_at',
    scopedSlots: { customRender: 'created_at' },    
    width: '20%',
  }, 
  { 
    title: ' 調整 ', 
    dataIndex: '', 
    key: 'id', 
    scopedSlots: { customRender: 'action' } ,
    width: '10%',
    },   

];


const decolumn = [
  {
    title: '子類別名稱',
    dataIndex: 'title',
    scopedSlots: { customRender: 'title' },
  },
  {
    title: '子類別描述',
    dataIndex: 'descs',
    scopedSlots: { customRender: 'descs' },
  },  
  {
    title: '主辦單位',
    className: 'column-money',
    dataIndex: 'broker',
        width: '12%',
  },
  {
    title: '講師',
    dataIndex: 'coach',
        width: '12%',
  },
  {
    title: '工作人員',
    dataIndex: 'volunteer',
        width: '12%',
  },  
  {
    title: '參加人員',
    dataIndex: 'actor',
        width: '12%',
  },  
  { 
    title: '修改', 
    dataIndex: '', 
    key: 'id', 
    scopedSlots: { customRender: 'action' } ,
    width: '15%',
  },
];


export default {
    name: 'Createdtype',    
    props:{
          page:{
              type:Number
          },
        },    
    data(){
        return {
            errors:'',
            tmpobj:null,
            tmpobj2:null,
            mtyps:'error',
            errs:'總學分超過100分請注意',
            uploadurl:process.env.VUE_APP_URL +'user/edimage', 
            iconLoading: false,
            iconLoading2: false,
            pagination: {defaultPageSize:4},
            paginationx : {defaultPageSize:4},
            loading: false,
            reportData:[],
            vshow:true, 
            vshow2:true,
            data: [],
            mess: '',
            mess2:'', 
            columns, 
            dedata:[],
            decolumn,                        
            form: {    
                 id:'',            
                 title:'', 
                 vals:'', 
                 states : '',
                 models:'',
                 items_type : null,                     
                },
            dform:{
                id:'',
                title:'',
                descs:'',
                broker:0,
                coach:0,
                volunteer:0,
                actor:0,
            },
            locale:{
                filterConfirm:'確定',
                filterReset: '重置',
                emptyText: '暫時無任何數據'        
            }, 
            pdfGenerated: false                         
        }
    },
    computed:{
      ...mapState('account',['status','user']),
    
          
      formdata(){    
          if(this.form.title!==''){
            //this.$emit('done',2)                        
            return true
          }else{
              return false 
          }
      },
      ctitleState() {
        return this.form.title.length > 0 ? true : false
      },  
      etitleState() {
        return this.form.vals.length > 0 ? true : false
      },  
      
                          
     dtitleState() {
        return this.dform.title.length > 0 ? true : false
      },
      
      descsState() {
        return this.dform.descs.length > 0 ? true : false
      },

     brokerState() {
        return this.dform.broker > 0 ? true : false
      },   
      
     coachState() {
        return this.dform.coach > 0 ? true : false
      }, 
      
     volunteerState() {
        return this.dform.volunteer > 0 ? true : false
      },   
      
     actorState() {
        return this.dform.actor > 0 ? true : false
      },       

    },    
    methods :{
      async submits(val) {
            this.iconLoading = true 
            this.mess =''
            let obj={}
            if(val=='add'){
                obj={
                    id:'',
                    title : this.form.title,
                    vals : this.form.vals,
                    states : 'N',
                    models : 'N',
                }                             

                postnew(obj).then(({data}) =>{
                        if(data.code==201){
                            this.inids()
                            this.mess='新增成功'
                            this.reload()
                            setTimeout(() => {   
                                this.iconLoading = false
                                this.mess=''
                                this.$refs['my-loading'].hide()
                                clearInterval();
                            }, 1000);                            
                            }else if(data.code==300){
                            this.mess='資料重覆'
                            setTimeout(() => {   
                                this.iconLoading = false
                                this.mess=''
                                clearInterval();
                            }, 1000);                              
                        }  
                    });  
            }else if(val=='up'){
                obj={
                    id:this.form.id,
                    title : this.form.title,
                    vals : this.form.vals,
                    states : 'N',
                    models : 'N',
                    items_type : this.form.item,
                }              
                updata(obj).then(({data}) =>{
                        if(data.code==200){
                            this.inids()
                            this.mess='更新成功'
                            this.data.find(item => item.id == obj.id).title = obj.title  
                            this.data.find(item => item.id == obj.id).vals = obj.vals
                            setTimeout(() => {   
                                this.iconLoading = false
                                this.mess=''
                                this.$refs['my-loading'].hide()
                                clearInterval();
                            }, 1000);                            
                        }else if(data.code==300){
                            this.mess='資料重覆'
                            setTimeout(() => {   
                                this.iconLoading = false
                                this.mess=''
                                clearInterval();
                            }, 1000);                              
                        }  
                    });
                                    
            }else if(val=='del'){
               let item={
                    id:this.form.id,
                }            
                deldata(item).then(({data}) =>{
                        if(data.code==200){
                            this.mess='刪除成功'
                            let index = this.data.findIndex(obj => obj.id == item.id) 
                            this.data.splice(index, 1);   
                            this.inids()
                            setTimeout(() => {   
                                this.iconLoading = false
                                this.mess=''
                                this.$refs['my-loading'].hide()
                                clearInterval();
                            }, 1000);                               
                        }
                    });                   
            }
        }, 

        async submits2(val){
            //this.iconLoading2 = true 
            this.mess2 =''
            let obj={
                 id:this.tmpobj2.id,            
                 title:this.tmpobj2.title, 
                 vals:this.tmpobj2.vals, 
                 states : this.tmpobj2.states,
                 models:this.tmpobj2.models,
                 items_type :[{
                        id:this.dform.id,
                        title:this.dform.title,
                        descs:this.dform.descs,
                        broker:parseInt(this.dform.broker), 
                        coach:parseInt(this.dform.coach),
                        volunteer:parseInt(this.dform.volunteer),
                        actor:parseInt(this.dform.actor),
                    }] ,                     
            }            
            
            if(val=='add'){
                postdetail(obj).then(({data}) =>{
                        if(data.code==200){
                            this.inid2()
                            this.mess2='新增成功'
                            console.log(data.data.items_type[0])
                            this.data.find(item => item.id == obj.id).items_type.push(data.data.items_type[0])
                            //this.reload()
                            setTimeout(() => {   
                                this.iconLoading2 = false
                                this.mess2=''
                                clearInterval();
                            }, 1000);                            
                            }else if(data.code==300){
                            this.mess2='資料重覆'
                            setTimeout(() => {   
                                this.iconLoading2 = false
                                this.mess2=''
                                clearInterval();
                            }, 1000);                              
                        }  
                    });  
            }else if(val=='up'){
                putdata(obj).then(({data}) =>{
                        if(data.code==200){
                            this.mess2='更新成功'
                            //this.data.find(item => item.id == obj.id).items_type.find(rs => rs.id == obj.items_type[0].id)= obj.title  
                            this.data.find(item => item.id == obj.id).items_type.find(rs => rs.id == obj.items_type[0].id).descs =obj.items_type[0].descs
                            this.data.find(item => item.id == obj.id).items_type.find(rs => rs.id == obj.items_type[0].id).broker =obj.items_type[0].broker
                            this.data.find(item => item.id == obj.id).items_type.find(rs => rs.id == obj.items_type[0].id).coach =obj.items_type[0].coach
                            this.data.find(item => item.id == obj.id).items_type.find(rs => rs.id == obj.items_type[0].id).volunteer =obj.items_type[0].volunteer
                            this.data.find(item => item.id == obj.id).items_type.find(rs => rs.id == obj.items_type[0].id).actor =obj.items_type[0].actor
                            setTimeout(() => {   
                                this.iconLoading2 = false
                                this.mess2=''
                                this.inid2()
                                this.$refs['my-loading2'].hide()
                                clearInterval();
                            }, 1000);                            
                        } 
                    });
                                    
            }else if(val=='del'){         
                remtype(obj.items_type[0].id).then(({data}) =>{
                        if(data.code==200){
                            this.mess2='刪除成功'
                            //this.data.splice(index, 1);   
                            let tmp = this.data.find(item => item.id == obj.id) 
                            let index=0
                            for (let itme in tmp.items_type) {
                                if(tmp.items_type[itme].id==obj.items_type[0].id){                                
                                  break;
                                }
                                index++
                            }                
                            this.data.find(item => item.id == obj.id).items_type.splice(index,1)                                                             
                            setTimeout(() => {   
                                this.iconLoading2 = false
                                this.mess2=''
                                this.$refs['my-loading2'].hide()
                                clearInterval();
                            }, 1000);                               
                        }
                    });                   
            }
        },
        
        inids(){
            this.form.id =''
            this.form.title='' 
            this.form.vals='' 
            this.mess=''
        },
        
        inid2(){
            this.dform.id=''
            this.dform.title=''
            this.dform.descs=''
            this.dform.broker=0
            this.dform.coach=0
            this.dform.volunteer=0
            this.dform.actor=0
        },
        
        fdateformat(val){
            return moment(val).format("YYYY-MM-DD"); 
        },        

        openedit(obj){
            this.vshow = obj==null? true:false
            this.tmpobj=obj            
            this.inids();
            if(obj!=null){
                this.form.title = obj.title
                this.form.vals = obj.vals
                this.form.status = obj.status
                this.form.id = obj.id
                this.items_type = obj.items_type
            }
            this.$refs['my-loading'].show() 
        },  
        editdetail(obj,record){
            this.vshow2 = obj==null? true:false
            this.tmpobj2 =record
            this.inid2();
            if(obj!=null){
                this.dform.id=obj.id
                this.dform.title=obj.title
                this.dform.descs=obj.descs
                this.dform.broker=obj.broker
                this.dform.coach=obj.coach
                this.dform.volunteer=obj.volunteer
                this.dform.actor=obj.actor
            }
            this.$refs['my-loading2'].show()             
        },   
                  
        onHide(evt) {
              if(evt.trigger === "backdrop"){
                  evt.preventDefault();
              }
          },

        handleTableChange(pagination, filters, sorter) {
            //console.log(pagination);
            const pager = { ...this.pagination };
            pager.current = pagination.current;
            this.pagination = pager;
            //console.log(this.pagination);
            this.fetch({
                results: pagination.pageSize,
                page: pagination.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters,
            });
            }, 
        fetch(params = {}) {
        this.loading = true;
        queryData({
            ...params,
        }).then(({ data }) => {
            const pagination = { ...this.pagination };
            // Read total count from server
            // pagination.total = data.totalCount;
            pagination.total = data.info.totail;
            //pagination.total = 200;
            this.loading = false;
            this.data = data.results;
            this.pagination = pagination; 
        });
        },          
          
        reload(rs){
            if(rs==null){
            this.fetch({results:4,page:1,pageSize: 4 });
            }else{
            this.fetch(rs);
            }
        }, 
        
        GetReport(){
            this.$refs['my-typeList'].show()  
            this.loading = true;    
            queryReport().then(({ data }) => {
            this.loading = false;
            this.reportData = data;
            });                          
        },
        closeModal(){
            this.$refs['my-typeList'].hide()  
        },

        runReport(){
            const content = this.$refs.myContents;
            html2pdf().set({ pagebreak: { mode: 'avoid-all' } }).from(content).set({ margin: [10, 10, 15, 10] }).save();
        },

        additem(obj){
            this.vshow2=true
            this.tmpobj2=obj
            this.inid2()
            this.$refs['my-loading2'].show() 
        }

    }, 
    mounted() {
        this.reload()
    },  

}
</script>
<style scoped>
.card-body{
    padding:5px 0px 5px 0px;
}

.card-3 {
    padding:20px;
    border-radius: 6px;
    text-align: left;
    border-width:0px;
    margin: 25px auto;
    border-style:solid;
    border-color:#e0dedc;
	transition: all .3s ease;
}

.ddic {
  border-radius: 10px;
  box-shadow: 1px 1px 15px -5px rgb(206, 204, 204);
  transition: all 0.3s ease;
}

.hr-dashed-fixed {
    border: 0;
    padding-top: 1px;
    background: repeating-linear-gradient(to right, #969aa1 0px, #a2a9b6 4px, transparent 0px, transparent 10px);
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px; /* 設置表格中文字的大小 */
}

th, td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
@page {
  margin-top: 50px; /* 設置每個新頁面的頂部距離 */
}


</style>

