<template>
    <div>   
            <br>
            <b-card
                header="同意說明書"
                border-variant="light"
                header-border-variant="light"
                header-bg-variant="light"                
                align="center"  
                class="card-3"          
                >

            <b-card-body style="width:980px;height:460px;" class="overflow-auto" id="style-3">
            <b-card-text >
            <h5>【連江健康活動參與平台, 活動上架發佈規範及建議】</h5> <div style="margin-left:1rem;">
               
                <div class=WordSection1>

                    <p class=MsoNormal style='background:white'><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>「連江健康活動參與平台」係受連江縣政府委託宸緯資訊有限公司運營維護管理。</span><span style='font-family:
                    "Corbel","sans-serif";color:black'> </span></p>

                    <p class=MsoNormal style='background:white'><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>活動主辦單位</span><span lang=EN-US style='font-family:"Corbel","sans-serif";
                    color:black'>(</span><span style='font-family:"Microsoft JhengHei;","serif";color:black'>以下簡稱為主辦單位</span><span
                    lang=EN-US style='font-family:"Corbel","sans-serif";color:black'>) </span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>使用「連江健康活動參與平台」網路服務。</span></p>

                    <p class=MsoNormal style='background:white'><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>為了保障您的權益，請在註冊使用平台服務及建立活動前詳閱並同意本《活動上架發佈</span></p>

                    <p class=MsoNormal style='background:white'><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>規範及建議》。</span></p>

                    <p class=MsoNormal style='margin-top:9.0pt;margin-right:0cm;margin-bottom:9.0pt;
                    margin-left:0cm;background:white'><b><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>一、規範說明：</span></b></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>1</span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>個人註冊使用平台服務，須填寫</span><span style='font-family:"Corbel","sans-serif";
                    color:black'> </span><span style='font-family:"Microsoft JhengHei;","serif";color:black'>「</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>帳號、密碼及所屬縣市鄉鎮區村里</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>」</span><span style='font-family:
                    "Microsoft JhengHei;","serif";color:black'>，街道名稱門牌</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp; </span><span style='font-family:
                    "Microsoft JhengHei;","serif";color:black'>號碼可不填寫。</span><span style='font-family:"Corbel","sans-serif";
                    color:black'> </span><span style='font-family:"Microsoft JhengHei;","serif";color:black'>建議自主選擇綁定自己手機</span><span
                    lang=EN-US style='font-family:"Corbel","sans-serif";color:black'>Line</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>帳號方便未來於手機操作。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>2</span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>主辦單位需先填寫基本資料經上級審核通過後始可開始於平台建立活動。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>3</span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>主辦單位填寫活動內容送出申請，須經上級單位審核通過後才可公開發佈接受民眾</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp; </span><span style='font-family:
                    "Microsoft JhengHei;","serif";color:black'>報名。</span><span style='font-family:"Corbel","sans-serif";
                    color:black'> </span><span style='font-family:"Microsoft JhengHei;","serif";color:black'>審核將會針對活動內容及分類妥適性嚴加審查，若經審查發現不妥之處，</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp; </span><span style='font-family:
                    "Microsoft JhengHei;","serif";color:black'>將一律審核不予通過，系統會自動透過</span><span lang=EN-US
                    style='font-family:"Corbel","sans-serif";color:black'>Email</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>郵件回覆不通過理由。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>4</span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>活動分類及對應健康學分，由連江縣政府主管單位統一設定，委由「連江健康活動參與</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp; </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>平台」管理。</span><span style='font-family:"Corbel","sans-serif";
                    color:black'> </span><span style='font-family:"Microsoft JhengHei;","serif";color:black'>主辦單位如有需要增加活動項目及分類，請聯繫宸緯資訊有限公司協助</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp; </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>處理。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>5</span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>主辦單位上架發佈之活動內容，若有違反以下任何禁止條款規定，遭到舉報或發現，</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp; </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>經稽查屬實後</span><span style='font-family:"Microsoft JhengHei;","serif";color:black'>「</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>連江健康活動參與平台</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>」</span><span style='font-family:
                    "Microsoft JhengHei;","serif";color:black'>將可依維護平台運作之權力，對主辦單位</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp; </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>發佈之活動進行以下限制。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp;&nbsp; 5.1 </span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>主辦單位違規活動將無法獲得</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>「</span><span style='font-family:
                    "Microsoft JhengHei;","serif";color:black'>連江健康活動參與平台</span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>」</span><span style='font-family:"Microsoft JhengHei;","serif";color:black'>全渠道推薦宣傳資源</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>（包含網站、</span><span lang=EN-US
                    style='font-family:"Corbel","sans-serif";color:black'>App</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>、</span><span lang=EN-US
                    style='font-family:"Corbel","sans-serif";color:black'>EDM</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>、社群平台等）。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp;&nbsp; 5.2 </span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>若主辦單位舉辦的活動違反法律規範，或嚴重危害平台利益，</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>「</span><span style='font-family:
                    "Microsoft JhengHei;","serif";color:black'>連江健康活動</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>參與平台</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>」</span><span style='font-family:
                    "Microsoft JhengHei;","serif";color:black'>保有直接下架之權利，並有解釋嚴重危害平台利益定義之權利。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>6</span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>因違反規定而受到限制之活動，將不會主動通知主辦單位。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>7</span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>自發佈新規範日起，此規範針對「連江健康活動參與平台」，所有已上架中的活動均適用，</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp; </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>需請主辦單位特別留意。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>8</span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>主辦單位無法針對已結束的活動進行修改</span><span lang=EN-US style='font-family:"Corbel","sans-serif";
                    color:black'>/</span><span style='font-family:"Microsoft JhengHei;","serif";color:black'>下架</span><span
                    lang=EN-US style='font-family:"Corbel","sans-serif";color:black'>/</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>刪除的動作，如果有相關需求，須向宸緯</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp; </span><span style='font-family:
                    "Microsoft JhengHei;","serif";color:black'>資訊有限公司提出此收費性的服務，如有需求請洽宸緯資訊有限公司客服。</span></p>

                    <p class=MsoNormal style='margin-top:9.0pt;margin-right:0cm;margin-bottom:9.0pt;
                    margin-left:0cm;background:white'><b><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>二、活動基本資訊：</span></b></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>1 </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>活動名稱及活動</span><span lang=EN-US style='font-family:"Corbel","sans-serif";
                    color:black'>Banner</span><span style='font-family:"Microsoft JhengHei;","serif";color:black'>圖不得有違反以下規定之內容，禁止的內容為：</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp; 1.1 </span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>無上傳</span><span lang=EN-US
                    style='font-family:"Corbel","sans-serif";color:black'>Banner</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>圖。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp; 1.2 </span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>腥膻色資訊。如</span><span lang=EN-US
                    style='font-family:"Corbel","sans-serif";color:black'>18</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>禁文字、血腥暴力等內容資訊。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp; 1.3 </span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>非法直銷性質活動。如試圖拉攏入會或認購非來自於銷售價值的企業自產產品。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp; 1.4 </span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>非法博弈資訊。如非法設置之娛樂場所或非法娛樂之行為。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp; 1.5 </span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>公開活動的資訊不齊全。如測試、未完成、複製</span><span
                    lang=EN-US style='font-family:"Corbel","sans-serif";color:black'>...</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>等文字。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp; 1.6 </span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>任何違法及容易使用戶產生疑問的資訊，「連江健康活動參與平台」保有解釋</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>易使用戶產生疑問定義之權利。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>2</span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>因台灣觀光旅行產業屬特許行業，「連江健康活動參與平台」禁止上架套裝旅遊行程</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp; (</span><span style='font-family:
                    "Microsoft JhengHei;","serif";color:black'>如多點停靠、含交通住宿以及行程安排等</span><span lang=EN-US
                    style='font-family:"Corbel","sans-serif";color:black'>)</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>3</span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>建議</span><span lang=EN-US style='font-family:"Corbel","sans-serif";
                    color:black'>Banner</span><span style='font-family:"Microsoft JhengHei;","serif";color:black'>圖能將活動重點資訊及亮點加入，完成圖文並茂的活動</span><span
                    lang=EN-US style='font-family:"Corbel","sans-serif";color:black'>Banner</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp; </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>具有亮點且經過設計的視覺，更容易抓住他人目光。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>4</span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>建議活動標題清楚、準確說明您的活動計畫，字元數建議不超過</span><span lang=EN-US
                    style='font-family:"Corbel","sans-serif";color:black'>30</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp; </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>能使人建立良好的活動第一印象，提升搜尋準確率。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>5</span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>請確認您的活動日期時間地點及活動分類是否為正確無誤。</span><span style='font-family:"Corbel","sans-serif";
                    color:black'> </span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>6</span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>活動內容需填選講師及工作人員</span><span lang=EN-US style='font-family:"Corbel","sans-serif";
                    color:black'>(</span><span style='font-family:"Microsoft JhengHei;","serif";color:black'>工作人員</span><span
                    lang=EN-US style='font-family:"Corbel","sans-serif";color:black'>)</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>，可透過選擇其個人</span><span
                    lang=EN-US style='font-family:"Corbel","sans-serif";color:black'>Line</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>帳號完成之。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp; </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>惟講師及工作人員</span><span lang=EN-US style='font-family:"Corbel","sans-serif";
                    color:black'>(</span><span style='font-family:"Microsoft JhengHei;","serif";color:black'>工作人員</span><span
                    lang=EN-US style='font-family:"Corbel","sans-serif";color:black'>)</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>須事先於平台完成個人註冊並綁定手機</span><span
                    lang=EN-US style='font-family:"Corbel","sans-serif";color:black'>Line</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>帳號。</span></p>

                    <p class=MsoNormal style='margin-top:9.0pt;margin-right:0cm;margin-bottom:9.0pt;
                    margin-left:0cm;background:white'><b><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>三、活動內容：</span></b></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>1 </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>活動內容不得少於</span><span lang=EN-US style='font-family:"Corbel","sans-serif";
                    color:black'>100</span><span style='font-family:"Microsoft JhengHei;","serif";color:black'>字元</span><span
                    lang=EN-US style='font-family:"Corbel","sans-serif";color:black'>(</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>含圖片內文字</span><span lang=EN-US
                    style='font-family:"Corbel","sans-serif";color:black'>)</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>2 </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>相關圖片有像素及檔案大小規範，主辦單位須配合為之。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>3 </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>若主辦單位放入未經過授權，具版權爭議的圖片、影片或文字等，導致侵犯或危害他人</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp; </span><span style='font-family:
                    "Microsoft JhengHei;","serif";color:black'>版權利益，後續責任將由主辦單位自行承擔。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>4</span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>任何違法及危害平台利益之資訊，「連江健康活動參與平台」保有解釋危害平台利益定義</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp; </span><span style='font-family:
                    "Microsoft JhengHei;","serif";color:black'>之權利。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>5</span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>建議具圖文並茂、排版整齊清楚、活動資訊條列明確的活動，包括：活動簡介、活動時間、</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp; </span><span style='font-family:
                    "Microsoft JhengHei;","serif";color:black'>活動形式、活動地點、活動參與人數、活動收費標準、活動嘉賓、活動亮點等，更能</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp; </span><span style='font-family:
                    "Microsoft JhengHei;","serif";color:black'>使用戶增加報名參與意願。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>6 </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>建議若有較為繁雜冗長的資訊，如：嘉賓介紹、逐一展品說明</span><span lang=EN-US
                    style='font-family:"Corbel","sans-serif";color:black'>...</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>等，可妥善利用活動頁</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp; &nbsp;</span><span style='font-family:
                    "Microsoft JhengHei;","serif";color:black'>步驟中活動嘉賓介紹功能，或編輯中的表格功能輔助，使版面清楚。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>7 </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>建議文字排版可妥善利用編輯中的大小、對齊、顏色、粗寫、字體背景、底線、分隔線</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp; </span><span style='font-family:
                    "Microsoft JhengHei;","serif";color:black'>等功能，使人方便閱讀。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>8</span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>主辦單位可複製活動予以編修內容</span><span lang=EN-US style='font-family:"Corbel","sans-serif";
                    color:black'> (</span><span style='font-family:"Microsoft JhengHei;","serif";color:black'>如日期時間地點</span><span
                    lang=EN-US style='font-family:"Corbel","sans-serif";color:black'>)</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>後發佈為新活動，但仍要通過審核</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp; </span><span style='font-family:
                    "Microsoft JhengHei;","serif";color:black'>才會正式上架讓民眾報名。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;</span></p>

                    <p class=MsoNormal style='margin-top:9.0pt;margin-right:0cm;margin-bottom:9.0pt;
                    margin-left:0cm;background:white'><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>四、活動報名：</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>1 </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>「連江健康活動參與平台」僅提供活動申請發佈、民眾報名點名及得到健康學分等服務</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp; </span><span style='font-family:
                    "Microsoft JhengHei;","serif";color:black'>機制，不涉及金流交易及開立發票給參與主辦單位活動的用戶。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>2 </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>活動參加者須先於網站完成註冊始可使用網站平台服務。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp; </span><span style='font-family:
                    "Microsoft JhengHei;","serif";color:black'>可隨時自主選擇綁定自己手機</span><span lang=EN-US
                    style='font-family:"Corbel","sans-serif";color:black'>Line</span><span
                    style='font-family:"Microsoft JhengHei;","serif";color:black'>帳號方便未來於手機操作。</span><span
                    style='font-family:"Corbel","sans-serif";color:black'> </span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>3 </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>活動參加者可透過網站平台或手機</span><span lang=EN-US style='font-family:"Corbel","sans-serif";
                    color:black'>Line@</span><span style='font-family:"Microsoft JhengHei;","serif";color:black'>做活動報名、取消報名及活動現場接受點名</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;&nbsp; </span><span style='font-family:
                    "Microsoft JhengHei;","serif";color:black'>完成報到及取得健康學分。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>4 </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>活動參加者可透過手機</span><span lang=EN-US style='font-family:"Corbel","sans-serif";
                    color:black'>Line@ </span><span style='font-family:"Microsoft JhengHei;","serif";color:black'>隨時查詢曾參加過活動紀錄及健康學分。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>5 </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>若網站規範資訊無法解決主辦單位的問題，請聯繫宸緯資訊有限公司客服中心進行咨詢。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;</span></p>

                    <p class=MsoNormal style='margin-top:9.0pt;margin-right:0cm;margin-bottom:9.0pt;
                    margin-left:0cm;background:white'><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>五、附註：</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>1 </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>當主辦單位使用「連江健康活動參與平台」發佈活動服務時，即表示主辦單位已閱讀、</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp; </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>了解並同意接受本規範之所有內容。「連江健康活動參與平台」有權於任何時間修改或</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp; </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>變更本約定書之內容，若有重大項目變更將同步公告於主辦中心，建議主辦單位應注意</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp; </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>修改或變更。</span><span style='font-family:"Corbel","sans-serif";
                    color:black'> </span><span style='font-family:"Microsoft JhengHei;","serif";color:black'>主辦單位於任何修改或變更後繼續使用「連江健康活動參與平台」服務時，</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp; </span><span style='font-family:"Microsoft JhengHei;","serif";
                    color:black'>將視為主辦單位已閱讀、瞭解並同意接受修改或變更。</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;</span></p>

                    <p class=MsoNormal style='background:white'><span lang=EN-US style='font-family:
                    "Corbel","sans-serif";color:black'>&nbsp;</span></p>

                </div>


            </div> 
            </b-card-text>
            </b-card-body>
                <br>
                <a-switch v-model="disableds" @change="onChange" checked-children="同意" un-checked-children="不同意"  /> 我已同意上述規範
            </b-card>

            <br>
            
    </div>
</template>
<script>
export default {
    name: 'Consent',
    props:{
          page:{
              type:Number
          },
          cobj:{ 
            type:Object
          },          
        },    
    data(){
        return {
            disableds:false
        }
    },
    watch: {
    cobj() {
      if (this.cobj!==undefined) {
        if(this.cobj.id>0){
            this.disableds = this.cobj.allow
            let rs={
                pages: 0,
                state:this.cobj.allow,
                data : {allow:true}
            }
            if(this.cobj.allow){
                this.$emit('done',rs)
            }
        }
      }
    },
  },     
    methods :{
        onChange(checked) {
            //console.log(`a-switch to ${checked}`);
            let rs={
                pages: 0,
                state:checked,
                data : {allow:true}
            }
            this.$emit('done',rs)
        },
    },
}
</script>
<style scoped>
#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #000000;
}

#style-3{
    text-align: left;
}
.card-3 {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
</style>