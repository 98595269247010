import { userService } from '../../_services'
import router from '../../router'
import Vue from 'vue'
import VueCookies from 'vue-cookies'

//JSON.parse(myJSON)：將資料由 JSON 格式字串轉回原本的資料內容及型別。
const user = JSON.parse(localStorage.getItem('user'))
/*const state = user ? 
    {status:{loggedIn: true, token:user.data.token,use: user.data.use},user}:{status:{loggedIn:false,token:'',use:false},user:null}*/


const state = {
        status: {
            loggedIn: user !==null ? true : false, 
            token: user !==null ? user.data.token : '', 
            use:  user !==null ? user.data.use : '',  
        },
        user : user !==null ? user : null, 
        targetpage:null,
        targetpageUrl:null,
        linecheck:false,
        luser:{v1:'',v2:'',v3:'',v4:'',v5:''},        
      };

const actions = {

    login({commit},{username,password}){
        userService.login(username, password)
        .then(
            user =>{
                commit('loginSucces', user);
                if(user.data.use===false){
                    if (router.currentRoute.path !== '/') router.replace('/').catch(err=>{err})
                    if (router.currentRoute.path === '/') router.replace('/replace').catch(err=>{err})
                }else{
                    router.replace('/setting').catch(err=>{err})
                }
            },
            error => {
                commit('loginFailure', error);
                //dispatch('alert/error', error, {root:true});
            }
        )
    },

    binLogin({commit},{username,password,targetPage}){
        userService.login(username, password)
        .then(
            user =>{
                commit('loginSucces', user);
                if(user.data.use===false){
                    if(targetPage!==""){
                        router.replace({ name: 'Temporary', params: { id: targetPage } }).catch(err => {err;});
                    }else{
                        if (router.currentRoute.path !== '/') router.replace('/').catch(err=>{err})
                        if (router.currentRoute.path === '/') router.replace('/replace').catch(err=>{err})
                    }
                }else{
                    router.replace('/setting').catch(err=>{err})
                }
            },
            error => {
                commit('loginFailure', error);
                //dispatch('alert/error', error, {root:true});
            }
        )
    },
    loginline({dispatch,commit},{token,id}){
        userService.loginline(token)
        .then(
            user => {
                commit('loginSucces', user);
                let obj ={id:id}
                commit('setpage', obj);
                if(user.data.use===false){  
                    if(id=="Home" || id==''){
                        router.replace('/').catch(err=>{err})                        
                    }else{
                        router.replace({ name: id}).catch(err=>{err})
                    }                               
                    //if (router.currentRoute.path !== '/') router.replace('/').catch(err=>{err})
                }else{
                    router.replace('/setting').catch(err=>{err})
                }
            },
            error => {
                commit('loginFailure', error);
                dispatch('alert/error', error, {root:true});
            }
        );
    },
    //router.replace:
    //跟router.push很像，唯一不同是，它不會向history添加新記錄，而是跟它的方法名一樣替換掉當前的history記錄。
    loginfb({dispatch,commit},{token}){
        userService.loginfb(token)
        .then(
            user => {
                commit('loginSucces', user);
                if(user.data.use===false){
                    if (router.currentRoute.path !== '/') router.replace('/').catch(err=>{err})
                }else{
                    router.replace('/setting').catch(err=>{err})
                }
            },
            error => {
                commit('loginFailure', error);
                dispatch('alert/error', error, {root:true});
            }
        );
    },    

    logout({commit}){
        commit('logout');
        userService.logout();
        router.replace('/replace').catch(err=>{err})
    },

    reLogin({commit}){
        commit('logout');
        userService.logout();
    },    

    getAccount({commit}){
        commit('account')
    },
    
    checktoken({commit,state}){
        //console.log(router.currentRoute.path)
        if(state.status.token!==''){     
                 
            try {  
                //console.log(Vue.$jwt.decode(String(state.status.token),"johnnyisgoodman"))    
                //Vue.$jwt.decode(String(state.status.token),"johnnyisgoodman")      
                let verify= Vue.$jwt.decode(String(state.status.token),"johnnyisgoodman")                     
                if(verify===null){
                    commit('logout');
                    if (router.currentRoute.path !== '/') router.replace('/').catch(err=>{err})
                }
              }
              catch (exception_var) {
                commit('logout');
                if (router.currentRoute.path !== '/') router.replace('/').catch(err=>{err})
              }                    
        }
    },
    

};

const getters ={
    getAccount(state){
        return state.user
    }
}

const mutations ={
    loginSucces(state,user){
        //console.log(user);
        VueCookies.remove('user')
        VueCookies.remove('status')
        state.status = {loggedIn:true,token:user.data.token,use:user.data.use};
        state.user = user;
        VueCookies.set('user',state.user);
        VueCookies.set('status',state.status);
    },
    loginFailure(state){
        VueCookies.remove('user')
        VueCookies.remove('status')
        state.status = {loggedIn:false, token:'',use: false}
        //state.user = {}
        state.user = null
        //state.targetpage = null
        VueCookies.set('user',state.user);
        VueCookies.set('status',state.status);
    },

    logout(state){
        //localStorage.clear();
        //console.log(state.user)
		localStorage.removeItem('user');		
        VueCookies.remove('user')
        VueCookies.remove('status')
        state.status = {loggedIn:false,token:'',use: false}
        //state.user = {}
        state.user = null
        //state.targetpage = null
        VueCookies.set('user',state.user);
        VueCookies.set('status',state.status);
    },

    updateProfile(state,playload){
        state.status.use = playload.use
        //state.user.password = playload.password
        //state.user.data.user.username = playload.account
        state.user.data.user.email = playload.email
        state.user.data.user.address= playload.address
        state.user.data.user.display_name = playload.display 
        state.user.data.user.pri_url = playload.priUrl
        state.user.data.user.sex = playload.sex
        state.user.data.user.birth = playload.birth
        state.user.data.user.mphone = playload.mphone
        state.user.data.user.hphone = playload.hphone
        state.user.data.user.address = playload.address  
        state.user.data.user.citys = playload.citys
        state.user.data.user.villages = playload.villages
        state.user.data.user.towns = playload.towns                
        //console.log(state.user.data.user.profile)   
    },

    updateProfileByLine(state,playload){
        state.user.data.user.display_name = playload.display
        state.user.data.user.hphone = playload.hphone
        state.user.data.user.address= playload.address
        state.user.data.user.birth = playload.birth
        state.user.data.user.sex = playload.sex
        state.user.data.user.citys = playload.citys
        state.user.data.user.villages = playload.villages
        state.user.data.user.towns = playload.towns                
    },    

    updatePassword(state,playload){
        state.user.data.user.password = playload.password
    },    

    updateLine(state,playload){
        state.user.data.user.utoken = playload.utoken
        state.user.data.user.display_name = playload.display 
        state.user.data.user.pri_url = playload.priUrl        
    },

    updateLove(state,playload){
        state.user.data.user.acts_loves=playload     
    },

    setpage(state,obj){          
          return new Promise((resolve)=>{  
            state.targetpage = obj.id
            resolve(state.targetpage)            
          })          
    },  

    setpageUrl(state,obj){          
        return new Promise((resolve)=>{  
          state.targetpageUrl = obj          
          resolve(state.targetpageUrl)            
        })          
    },     
    
    setParameter(state,playload){        
        return new Promise((resolve)=>{  
            state.luser.v1 = playload.v1
            state.luser.v2 = playload.v2
            state.luser.v3 = playload.v3
            state.luser.v4 = playload.v4
            state.luser.v5 = playload.v5
            resolve(state.luser)            
          })         
    },

    setClearns(state){ 
        return new Promise((resolve)=>{  
            state.luser.v1 = ''
            state.luser.v2 = ''
            state.luser.v3 = ''
            state.luser.v4 = ''
            state.luser.v5 = ''
            resolve(state.luser)            
          })        
    },

    getLinecheck(state){        
        if(state.status.token!==''){
            state.linecheck=true            
            let verify= Vue.$jwt.decode(String(state.status.token),"johnnyisgoodman")                    
            if(verify===null){
                state.linecheck=false
            }            
        }else{
            state.linecheck=false
        }
    }


}

export const account ={
    namespaced : true,
    state,
    actions,
    mutations,
    getters
}