<template>
  <div class="act">
    <a-spin :spinning="spinning">
        <div class="d-flex flex-column min-vh-100">
            <div class="row justify-content-center">
            <div class="col-md-12" >   
                <div class="mt-12 text-left" style="padding:0.15rem;">
                    <b-button-group>
                        <b-button variant="success" @click="madeGroup" style="padding:0.2rem;width: 8.8rem;" pill>建置新群組</b-button>
                    </b-button-group>
                </div>                       
                <b-card header="我的工作人員群組" class="text-center">
                    <b-card-text>
                        <b-container fluid>
                            <b-row>
                                <b-col cols="12" md="3" v-for="item in myData" :key="item.id">
                                    <b-card              
                                        :title="item.groupname"
                                        :style="{  'margin': '5px', 'width':'40rem', 'max-width' : '100%', 'border-radius':'20px', 'box-shadow':' 0 4px 8px 0 rgba(0,0,0,0.2)' }"
                                        class="card-transition card-container mb-3">                       
                                        <b-list-group flush style="text-align: left;" >
                                            <b-list-group-item>                                              
                                              <a-popover title="" trigger="click">
                                                <template slot="content">
                                                  <div class="overflow-auto" style="height: 250px;"  id="style-3">
                                                    <div v-for="data in item.acts_grouplist" :key="data.id" class="item-container">
                                                      <b-avatar 
                                                        :src="data.pri_url" 
                                                        variant="primary" 
                                                        text="data.displayname" 
                                                        size="2.5rem" 
                                                        class="align-baseline avatar">
                                                      </b-avatar>
                                                      <div class="text-container">
                                                        <span style="font-size: 14px;font-weight: bold;"
                                                         class="displayname" v-text="data.displayname"></span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </template>
                                                <a-button type="link">
                                                  <span style="font-size: 16px;font-weight: bold;margin-left: -1.01rem;margin-bottom: 1.01rem;" v-text="'群組人數: '+item.acts_grouplist.length"/>
                                                </a-button>
                                              </a-popover>
                                            </b-list-group-item>
                                            <b-list-group-item><span style="font-size: 16px;font-weight: bold;margin-left: -0.01rem;" v-text="'授予點名: '+(item.checked?'是':'否')"/></b-list-group-item>
                                            <b-list-group-item><span style="font-size: 16px;font-weight: bold;margin-left: -0.01rem;" v-text="'是否獲得學分: '+(item.getscore?'是':'否')"/></b-list-group-item>
                                            <b-list-group-item><span style="font-size: 16px;font-weight: bold;margin-left: -0.01rem;" v-text="'建置日期:'+dateFormat(item.created_at)"/></b-list-group-item>
                                        </b-list-group>                                            
                                              <b-button type="primary" style="margin-left: -0.01rem;" @click="editItems(item)"  block>修改</b-button>
                                              <b-button type="danger"  style="margin-left: -0.01rem;" @click="delItems(item)"   block>刪除</b-button>                                                                                    
                                    </b-card>
                                </b-col>
                            </b-row>
                        </b-container>

                    </b-card-text>
                </b-card>
            </div>
            </div>
        <div class="wrapper flex-grow-1"></div>      
        </div>

        <b-modal ref="my-Groups" id="modal-admin" title="Loading" size="lg" @hide="onHide" centered hide-footer hide-header>
          <div class="text-center">       
            <div  style="width: 100%;" id="style-3">
            <div>
            <b-input-group prepend="義工群組名稱">
              <b-form-input
                autocomplete="off"
                v-model="groupName"
                type="text"
                placeholder="請輸入義工群組名稱"
                aria-label="Small text input with custom switch"
              ></b-form-input>
            </b-input-group>
            <!--<span v-if="isGroupNameTaken" style="color:brown;font-weight: bold;" v-text="'該名稱已存在'" />
            <span v-if="isGroupNameAvailable" style="color:brown;font-weight: bold;" v-text="'該名稱( '+ groupName +' )可用'" />--> 
            <div style="margin-top:5px;">
                <b-icon-person-lines-fill></b-icon-person-lines-fill><span v-text="'授予點名 : '"></span>
                <a-switch checked-children="是" style="margin-right: 1rem;" un-checked-children="否" @change="orderChange" :checked="checked" default-unchecked />
                <b-icon-award-fill></b-icon-award-fill><span v-text="'是否獲得學分 : '"></span> 
                <a-switch checked-children="是" un-checked-children="否" @change="scoreChange" :checked="getscore" default-unchecked />
            </div> 
            </div>
            <a-input-search placeholder="請輸入查詢名稱" style="margin-bottom: 10px;margin-top:10px;width: 200px" enter-button @search="onSearch" />
              <a-table 
                :columns="columns"
                :row-key="record => record.id"
                :data-source="udata"
                :pagination="upagination"
                :loading="uloading"
                :row-selection="{ type: 'checkbox', selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                @change="uhandleTableChange"
                bordered
            >                                                
                <template slot="pri_url" slot-scope="pri_url"> 
                    <b-avatar :src="pri_url" variant="primary" text="" size="2.5rem" class="align-baseline"></b-avatar>                                 
                </template> 
                <template slot="username" slot-scope="record">
                    <div>
                        <span v-if="record.username != ''" style="color:#F08C3C;" v-text="maskName(record.username)"></span>                                   
                    </div>
                </template> 
                <template slot="display_name" slot-scope="record">
                    <div>
                        <span v-if="record.display_name != ''" style="color:#2c7190;" v-text="record.display_name"></span>                                    
                    </div>
                </template>                                                                                
            </a-table>

            </div>
              <div style="padding:0.3rem;" ></div>
              <a-button-group >
                <a-button @click="closeModal()" :loading="loading">
                  <b-icon  style="margin-right: 0.3rem;" scale="1.1" icon="x-circle" variant="secondary" />
                   取消 
                </a-button>                 
                <a-button
                  type="primary"
                  @click="createdGroup()"
                  :disabled="selectedRowKeys.length==0 || groupName==''"
                  :loading="loading"
                >                
                <b-icon  style="margin-right: 0.3rem;" scale="1.1" icon="arrow-repeat" variant="secondary" />
                  <span v-text="myEditId==0?'建置':'更新'" />
                </a-button>                                              
              </a-button-group>  
          </div>
        </b-modal>          

    </a-spin>
  </div>
</template>
<script>

import router from '../../../router'
import * as moment from "moment/moment";
import { userService,cmessService } from '../../../_services'
import {mapState,mapActions} from 'vuex';

const queryData = obj => {
  return userService.myGroups(obj);
}; 

const queryName = obj => {
  return userService.queryGroupName(obj);
}; 


const postGroup = obj => {
  return userService.postGroup(obj);
}; 

const queryuser = params => {
  return cmessService.findusListName(params.page,params.name)
}

const deleteItem = obj => {
  return userService.deleteGroup(obj);
}; 



const columns = [
  {
    title: '圖像',
    dataIndex: 'pri_url',
    width: '20%',    
    scopedSlots: { customRender: 'pri_url' },    
  },       
  {
    title: '註冊帳號',
    dataIndex: '',
    scopedSlots: { customRender: 'username' },      
    width: '30%',
  },   
  {
    title: 'Line顯示名稱',
    dataIndex: '',
    scopedSlots: { customRender: 'display_name' },      
    width: '30%',
  },  

];

export default {
  name: 'MyGroup',
  components: {
  },
  data() {
    return {
        spinning: false,
        loading:false,
        uloading: false,
        upagination: {defaultPageSize:4},  
        selectedRowKeys: [],  
        groupName:'',
        isGroupNameTaken: false,
        isGroupNameAvailable: false, 
        checked:true,
        getscore:true,      
        columns,  
        name:"all",                     
        myData:[],
        udata: [],
        selectedItems: [],
        myEditId:0,
    };
  },
  watch: {
    defaultval(val) {
      console.log(val)
      this.myReload();
    }
  },
  methods:{
        ...mapActions('account',['checktoken']), 
        myReload() {
            this.spinning=true;
                queryData({id:this.defaultval.id
            }).then(({ data }) => {
                this.spinning = false; 
                if(data!==undefined){
                    this.myData = data//data.sort((a, b) => b.act - a.act);
                }
            });
        },
        query(rs){
          console.log(rs)
        }, 
        getwinsize(val){
          this.windowsize.width = val.width
          this.windowsize.height = val.height
        },
        clearItem(){
          this.selectedRowKeys=[]
          this.selectedItems=[]
          this.groupName=''
          this.checked=true
          this.getscore=true
          this.myEditId=0;
          //this.isGroupNameTaken=false
          //this.isGroupNameAvailable=false
        },
        madeGroup(){
          this.myEditId=0
          this.clearItem();
          this.$refs['my-Groups'].show()
        }, 
        closeModal(){
          this.$refs['my-Groups'].hide()
        }, 
        onHide(evt) {
            if(evt.trigger === "backdrop"){
                evt.preventDefault();
            }
        },
       async createdGroup(){
          this.loading=true;
          this.spinning=true;
          let mygroup =[];
          this.selectedItems.forEach((item) => {
            mygroup.push({
              uid: item.id,
              displayname: item.displayname,
              pri_url:item.pri_url,
              name:item.name,              
            });
          });          
          let tmp={     
            id:this.myEditId,
            groupname:this.groupName, //義工群組名稱            
            uid:this.defaultval.id,
            checked:this.checked,     //授予點名
            getscore:this.getscore,   //否獲得學分
            acts_grouplist:mygroup    //義工人員
          }

       await postGroup(tmp).then(({data}) =>{
                if(data.code==200){
                  setTimeout(() => {   
                      this.loading=false;
                      this.spinning=false;                        
                      this.clearItem();
                      this.myReload(); 
                      this.$refs['my-Groups'].hide()
                      clearInterval();
                    }, 2000);
                }
            });           
        },
        dateFormat(val){
          //return moment(val).format("YYYY-MM-DD HH:mm");
          return moment(val).format("YYYY-MM-DD"); 
        },        
        usfetch(params = {}) { 
          this.uloading = true;
          queryuser({
              //results: 4,
              ...params,
          }).then(({ data }) => {
              const pagination = { ...this.upagination };
              pagination.total = data.info.totail;
              this.uloading = false;
              this.udata = data.results;
              this.upagination = pagination;                 
          });
        },
        uhandleTableChange(pagination, filters, sorter) {
          const pager = { ...this.upagination };
          pager.current = pagination.current;
          this.upagination = pager;
          this.usfetch({
              results: pagination.pageSize,
              page: pagination.current,
              name: this.name,
              sortField: sorter.field,
              sortOrder: sorter.order,
              ...filters,
          });
        },
        async onSearch(value) {
          if(value==""){
            this.name = "all"
          }else{
            this.name = value
          }
          await this.usfetch({results:4,page:1,pageSize: 4,name: this.name});
        }, 


        onSelectChange(selectedRowKeys, selectedRows) {
          this.selectedRowKeys = selectedRowKeys;
          const selectedItemsMap = new Map(this.selectedItems.map(item => [item.id, item]));
          // Add new selected items
          selectedRows.forEach(row => {
            selectedItemsMap.set(row.id, {
              id: row.id,
              displayname: row.display_name,
              pri_url: row.pri_url,
              name: row.username
            });
          });
          // Remove deselected items
          this.selectedItems = Array.from(selectedItemsMap.values()).filter(item =>
            selectedRowKeys.includes(item.id)
          );
        },        
        
        maskName(name) {
            const len = name.length;
            const half = Math.ceil(len / 2);
            const maskedPart = '*'.repeat(half);
            const visiblePart = name.slice(half);
            return maskedPart + visiblePart;
        },
        async checkGroupName() {
          if(this.groupName!==''){
            await queryName({id:this.defaultval.id,name:this.groupName
              }).then(({ data }) => {
                  this.isGroupNameTaken = data;
                  this.isGroupNameAvailable = !this.isGroupNameTaken && this.groupName !== '';                
              });
          }          
        },
        orderChange(checked) {
            this.checked = checked
        },
        scoreChange(getscore) {
            this.getscore = getscore
        },
        
        editItems(item){
          this.clearItem();
          this.myEditId=item.id;
          this.groupName=item.groupname
          this.checked=item.checked
          this.getscore=item.getscore 
          const myData = item.acts_grouplist;
          const initialSelectedIds = myData.map(item => item.uid);
          this.selectedRowKeys = initialSelectedIds;
          this.selectedItems = myData
          .filter(item => initialSelectedIds.includes(item.uid))
          .map(item => ({
            id: item.uid,
            displayname: item.displayname,
            pri_url: item.pri_url,
            name:item.name, 
          }));
          this.$refs['my-Groups'].show()
        },  
        delItems(item){
          const self = this;
          this.$confirm({
            title:
              "您確定刪除該群組?",
            content: "群組名稱 : " + item.groupname,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            zIndex: 9999,
            async onOk() {
              self.spinning=true;
              await self.remove(item);
            },
            onCancel() {
              console.log("Cancel");
            }
          });
        },
        remove(item) {
          return new Promise((resolve) => {
              deleteItem({
              ...item //...解壓縮出來
            }).then(({ data }) => { 
              if(data.code==200){
                setTimeout(() => {
                  this.myReload(); 
                      this.spinning=false; 
                  }, 1000);                
                return resolve(true);
              }             
              return resolve(false);
            });
          })      
        }, 
    },
    computed:{
      ...mapState('account',['user']),
      ...mapState("makeorg", [ "defaultval"])
    },    
    async mounted(){ 
        if(this.user==null){
          router.replace('/')
        }else{
          this.checktoken(); 
          this.myReload();
          await this.usfetch({results:4,page:1,pageSize: 4,name: this.name});
        }
    }      
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

div.ex3 {
  width:800px;
  height: 460px;
  overflow: auto;
}

#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #000000;
}

#style-3{
    text-align: left;
}

.item-container {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.avatar {
  margin-right: 10px;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.displayname {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}
</style>