<template>
  <div class="overflow-hidden">
    <a-spin :spinning="spinnings" tip="資料載入中...">
      <div class="d-flex flex-column min-vh-100">
        <Linemenubar :data="orgdata" @ontype="ontype" @reget="reget" msg="test" ref="con1" />
        <ul>
          <li>
            <div class="mt-3">
              <div style="padding:1.7rem 0 0 0;" />
              <ViewScans v-if="shows"  @loads="loads" id="style-3" ref="con2" />
              <Actable v-if="!shows" :norg="norg" ref="con3" />
            </div> 
          </li>
        </ul>
        <div v-if="norg!==''" class="applyfooter">
          <div  style="width:100%;max-height:60px;" >      
            <div class="modbtn" @click="toGo()" > <b-icon icon="clock-history" aria-hidden="true"></b-icon> <span v-text="shows?'活動紀錄':'返回點名課程'" /></div>                   
          </div> 
        </div>
      </div>
    </a-spin>
    <a-drawer
      title=""
      :placement="'top'"
      :closable="false"
      :visible="vShow"
      :height="130"
    >
        <a-alert
        message="權限不足"
        description="您的權限不足, 系統將關閉視窗謝謝"
        type="warning"
        show-icon
      />      
    </a-drawer>    
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { myLineService,macService} from "../_services";
import * as moment from "moment/moment";
import Linemenubar from "@/lineorg/panel/Linemenubar.vue";
//import Viewper from "@/lineorg/panel/Viewper.vue";
import ViewScans from "@/lineorg/panel/ViewScans.vue";
import Actable from "./panel/AcTables"
import router from "../router";
import store from "../store/index";

const queryData = params => {
  return myLineService.finddata(
    params.page,
    params.size,
    params.id,
    params.mods
  );
};

const queryRole = params => {
  return macService.findrole(params);
}; 


export default {
  name: "Lineorg",
  components: {
    Linemenubar,
    //Viewper,
    ViewScans,
    Actable,
  },
  props: {
    msg: String
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)  
    return {
      page: 1,
      iconLoading: false,
      shows:true,
      list: [],
      uId: "",
      visible: false,
      vShow: false,
      answer: "",
      orgdata: [],
      itemval: "",
      spinnings: false,
      norg:"",
      objects:null,
      dDate:{},
      min: moment(minDate).format("YYYY-MM-DD"),       
    };
  },
  computed: {
    ...mapState("account", ["status", "user", "linecheck"]),
    ...mapState("makeorg", ["datas", "defaultval"])
  },
  async mounted() {
    let obj = { id: "Lineorg" };
    await this.setorgpage(obj)
      .then(val => {
        return val;
      })
      .catch(val => {
        return val;
      })
      .finally(() => {
        console.log("set page done");
      });
    let names = store.state.account.targetpage;
    if (names == "Lineorg") {
      this.orgint();
    }
  },
  methods: {
    ...mapActions("account", ["logout", "checktoken"]),
    ...mapMutations("account", ["setpage", "getLinecheck", "logout"]),
    ...mapActions("makeorg", ["readorg","getdorg"]),
    query(rs) {
      this.classtheme = rs; 
    },

    loads(va1){
      this.spinnings=va1
    },

    upload() {
      this.orgdata = this.datas;
      this.itemval = this.defaultval.name;
    },

    reget(){
      this.orgint();
    },

    setorgpage(obj) {
      return new Promise(resolve => {
        resolve(this.setpage(obj));
      });
    },

    toGo(){
      if(this.shows){
        this.shows=false
      }else{
        this.shows=true
        this.spinnings=true          
          setTimeout(() => {
            this.$refs["con2"].reload(this.objects);
            this.spinnings=false   
          }, 1200);          

      }
    },

    senDate(obj){
      this.dDate = obj
    },

    async orgint() {
      //let obj= {id:this.$route.name}
      //await this.setorgpage(obj).then((val)=>{return val}).catch((val)=>{return val}).finally(() => {console.log('set page done')})
      this.getLinecheck();
      if (this.linecheck == false) {
        this.logout();
        this.$refs["con1"].showline();
      } else {
        let msgs='主辦單位'
        this.uId = this.user.data.user.id;
        this.fetch();
        this.orgdata = await this.getorg().then(rs => rs);
        let orgtmp = await this.getorg2().then(rs => rs);
        if(orgtmp!==null){
          if(this.orgdata!==null){
            msgs = msgs+'/講師/工作人員'  
            for (let i = 0; i < this.orgdata.length; i++) {
              orgtmp = orgtmp.filter(item => item.id !==this.orgdata[i].id);
            }                      
            this.orgdata.push(...orgtmp)     
          }else{
            msgs = '講師/工作人員'
            this.orgdata=orgtmp
          }          
        }
        if(this.orgdata==null){
          this.vShow=true
            setTimeout(() => {
              this.closeWindow()  
            }, 1700);

        }else{
          if(this.user.data.use){
            router.replace('/setting').catch(err=>{err})
          }
        }
        let placement = 'bottomRight'
        this.$notification.open({
        message: 'Line@點名系統 ',
        description:
          '歡迎 ('+ msgs +') ' +this.user.data.user.username+'/'+this.user.data.user.display_name +'進入Line@點名系統.',
          placement,
          icon: <a-icon type="smile" style="color: #108ee9" />,
          style: {
          background: '#a9efd7',
          color: '#05696f'
        },
        duration: 3,
        });        

      }
    },

  async closeWindow(){
      await window.liff.init({
                liffId:"2006634407-7ON024Ke",
              }).then(()=>{
                window.liff.closeWindow();                               
              }).catch((error)=>{
                console.log(error)               
              })
              //window.location.href="about:blank"
              //window.close(); 

      },   

    getorg() {
      return new Promise(resolve => {
        let id = { id: this.uId };
        this.readorg(id).then(rs => {
          resolve(rs);
        });
      });
    },

    getorg2() {
      return new Promise(resolve => {
        let obj = { states: "Y", uid: this.uId, firstDate:this.min,lastDate:this.min};
        queryRole({
        ...obj
          }).then(({ data }) => {
            let obj = data;
            resolve(obj);
          });
      });
    },    

    getmore() {
      this.fetch();
    },

    ontype(val) {
      this.norg = val[0].id
      this.objects =val      
      if(!this.shows){ 
        this.$refs["con3"].reload2(val[0].id);
      }else{
        this.$refs["con2"].reload(val);
      }

    },
    fetch(
      params = {
        page: this.page,
        size: 3,
        id: this.uId,
        mods: "N"
      }
    ) {
      queryData({
        ...params
      }).then(({ data }) => {
        let obj = data.data;
        if (obj != null) {
          this.page += 1;
          setTimeout(() => {
            this.list.push(...obj);
            this.iconLoading = false;
          }, 100);
        } else {
          this.iconLoading = false;
        }
      });
    },

    fdateformat(val) {
      return moment(val).format("YYYY-MM-DD HH:mm");
    },

    savelove(val) {
      this.$refs["con2"].postdata(true, val);
    },

    scanCode(val) {
      this.answer = val;
      this.visible = true;
      setTimeout(() => {
      this.showTop = false;
          }, 4000);      
    }
  }
};
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.sidenav a:hover {
  color: #064579;
}
.applyfooter {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  color: #f0f8f8;
  text-align: center;
  z-index: 7;
}
.contantdiv {
  margin: 5px;
  overflow-x: hidden;
  overflow-y: hidden;
}

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(152, 167, 189, 0.3);
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
}

#style-3 {
  text-align: left;
}

.leftbtn {
    background-color: #aedbe3;
    color:#4b5152;
    width:17.5%; 
    height:60px; 
    float:left; 
    display:inline;
    text-align:center;
    line-height:60px;
}
.leftbtn:hover {
    background-color: #b6c8cc;
    color:#ffffff;
    cursor: pointer;
}
.modbtn{
    background-color: #30c272;
    color:#ffffff;
    width:100%; 
    height:60px; 
    float:left; 
    display:inline;
    text-align:center;
    line-height:60px;
}
.modbtn:hover {
    background-color: #63a380;
    color:#ffffff;
    cursor: pointer;
}

.rightbtn {
    background: linear-gradient(90deg, #65b5f7 0%, #4995d3 35%, #4184d0 100%);
    width:65%; 
    height:60px; 
    float:left; 
    display:inline;
    border-width:1px;
    border-color: #65b5f7;
    border-left-style:dotted;
    text-align:center; 
    line-height:60px;
}

.rightbtn:hover {
    background: linear-gradient(90deg, #7dc3fd 0%, #62b5f8 35%, #4aa0e7 100%);
    cursor: pointer;
}
</style>

