var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"ddic"},[_vm._v(" 活動摘要 "),_c('div',{staticClass:"divs",attrs:{"id":"style-3"}},[_c('a-table',{attrs:{"columns":_vm.columns,"row-key":record => record.id,"data-source":_vm.data,"pagination":_vm.pagination,"loading":_vm.loading,"locale":_vm.locale},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"sdate",fn:function(record){return [_c('span',{domProps:{"textContent":_vm._s(_vm.fdateformat(record.sdate))}})]}},{key:"actitle",fn:function(record){return [_c('router-link',{attrs:{"to":{
                    name:'Actinfo', 
                    params: { id: record.id}
                    },"target":"_blank"}},[_vm._v(_vm._s(record.actitle))])]}},{key:"states",fn:function(record){return [_c('span',{domProps:{"textContent":_vm._s(_vm.ctypes(record.states))}})]}},{key:"action",fn:function(record){return [_c('router-link',{attrs:{"to":{
                    name:'Joinlist', 
                    params: { id: record.id} 
                    }}},[_vm._v("請點選")])]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }