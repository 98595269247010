<template>
  <div>
    <a-spin size="large" :spinning="spinning" tip="產生列印資料...">
      <b-card
        header="活動報名人員清單"
        :img-src="items.url !== '' ? items.url : catImg('event_1080x540.png')"
        header-border-variant="light"
        header-bg-variant="light"
        align="center"
        class="card-3"
      >
        <div style="max-width: 980px; text-align: right">
          <b-button type="submit" squared variant="link" @click="exportToPDF">
            輸出報表
            <b-icon icon="printer" aria-hidden="true"></b-icon>
          </b-button>
        </div>
        <b-card-body
          style="max-width: 980px; padding: 15px 0px 5px 0px"
          ref="document"
          class="overflow-auto"
        >
          <b-card-text>
            <div class="text-left" style="padding: 5px 5px 5px 25px">
              <div class="classempy" id="word1head">
                  <div style="margin-bottom: -0.2rem;">
                  <strong><span style="font-size: 18px;" v-text="items.actitle"></span></strong>
                  </div>
                <b-icon icon="clock" variant="success"></b-icon> 活動時間 :
                <span
                  style="font-size: 14px; background-color: #ebf1f6"
                  v-text="
                    fdateformat(items.sdate) +
                    ' ~ ' +
                    fdateformat(items.edate) +
                    ' (人員報名資料如下) 目前人次 : ' +
                    items.acts_detrimal.length
                  "
                >
                </span>
              </div>
              <div
                id="style-3"
                style="max-height: 350px; overflow-x: hidden; overflow-y: auto"
              >
                <div
                  class="list-group-item"
                  :id="'word' + element.id"
                  style="padding: 2px; margin: 2px; max-height: 160px"
                  v-for="(element, index) in items.acts_detrimal"
                  :key="element.id"
                >
                  <div class="container">
                    <div class="row">
                      <hr
                        v-if="
                          index > 0 &&
                          items.acts_detrimal[index - 1].sdate !==
                            items.acts_detrimal[index].sdate
                        "
                        class="hr-text"
                        :data-content="formatDate(element.sdate)"
                      />
                      <span
                        style="padding: 0px; margin: 1px; font-size: 14px;margin-left: 10px;"
                        v-text="
                          '角色 : ' +
                          getRole(element.roles) +
                          ' | 課程時間 :' +
                          element.sdate +
                          '~' +
                          element.edate
                        "
                      />
                     
                      <span
                        v-for="item1 in element.byrsoptions"
                        :key="item1.id"
                      >
                            <span style="font-size: 14px" v-if="item1.oid==1 || item1.oid==2"
                              v-text="getdata(item1.oid , item1)"
                            />
                    </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-card-body>
        <template #footer> </template>
      </b-card>
    </a-spin>
  </div>
</template>   
<script>
import * as moment from "moment/moment";
import Formdata from "../../../assets/json/formdata.json";
import html2pdf from "html2pdf.js";

export default {
  name: "Jointable",
  props: {
    items: {
      type: Object,
    },
  },
  data() {
    return {
      i: 0,
      spinning: false,
      domList: [],
      myJson: [],
    };
  },
  computed: {},
  mounted() {},
  methods: {
    fdateformat(val) {
      return moment(val).format("YYYY-MM-DD HH:mm");
    },

    formatDate(val) {
      return moment(val).format("YYYY-MM-DD");
    },

    exportToPDF() {
      // pdf匯出設定
      this.spinning = true;
      const opt = {
        margin: [0.1, 0.3, 0.15, 0.3],
        filename: "document.pdf",
        image: { type: "jpeg", quality: 0.99 },
        html2canvas: { dpi: 192, letterRendering: true, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      };

      this.domList = [];
      // 抓取各個圖片的DOM元素，並把它裝入doc這個陣列裡
      
      let tmp = [];
      for (let item in this.items.acts_detrimal) {
        let element1 =null
        if(item==0){
          let newDiv = document.createElement("div");
          let head1 = document.getElementById("word1head");
          const clone1 = head1.cloneNode(true);
          newDiv.appendChild(clone1);
          newDiv.appendChild(document.getElementById("word" + this.items.acts_detrimal[item].id));
          element1 = newDiv
        }else{
          element1 = document.getElementById("word" + this.items.acts_detrimal[item].id);
        }
        const i = parseInt(item /12);
        let obj = {
          id: i,
          el: element1,
        };
        tmp.push(obj);
      }
      const grouped = this.groupBy(tmp, (pet) => pet.id); //Group by
      grouped.forEach((rs) => {
        let iDiv = document.createElement("div");
        rs.forEach((cs) => {
          const clone1 = cs.el.cloneNode(true);
          iDiv.appendChild(clone1);
          //console.log(cs.el,cs.id)
        });
        this.domList.push(iDiv);
      });
      let iDiv2 = document.createElement("div");
      this.domList.push(iDiv2);
      // 開始進行列印
      let doc = html2pdf().from(this.domList[0]).set(opt).toPdf();
      for (let j = 1; j < this.domList.length - 1; j++) {
        doc = doc
          .get("pdf")
          .then((pdf) => pdf.addPage())
          .from(this.domList[j])
          .toContainer()
          .toCanvas()
          .toPdf();
      }
      // 列印完成輸出done
      doc.save().then(() => (this.spinning = false));
      //
    },

    groupBy(list, keyGetter) {
      //group by
      const map = new Map();
      list.forEach(item => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    },    
    getdata(va1, va2) {
      //console.log(va2.items)
      if (va2.items !== "") {
        let tmp = this.myJson.filter((el) => {
          return el.id === parseInt(va1);
        });
        let str = "";
        if (
          va1 == "6" ||
          va1 == "9" ||
          va1 == "16" ||
          va1 == "8" ||
          va1 == "19" ||
          va1 == "7" ||
          va1 == "11" ||
          va1 == "12" ||
          va1 == "15" ||
          va1 == "18"
        ) {
          //radio
          let rs = tmp[0].list.filter((el) => {
            return el.value === va2.items;
          });
          str = tmp[0].name + " : " + rs[0].text;
        } else {
          // text
          str = tmp[0].name + " : " + va2.items;
        }
        //console.log("va2:",va2.items,"tmp:",tmp)
        return str;
      }
    },
    catImg(imgs) {
      return require(`../../../assets/${imgs}`);
    },

    getRole(val) {
      let tmp = "";
      val == "actor" ? (tmp = "參加者") : "";
      val == "broker" ? (tmp = "主辦單位") : "";
      val == "coach" ? (tmp = "講師") : "";
      val == "volunteer" ? (tmp = "工作人員") : "";
      val == "D" ? (tmp = "下架") : "";
      return tmp;
    },

    ctypes(val) {
      //return狀態 N草稿, I審核中, Y發佈中, F結束, D下架
      let tmp = "";
      val == "N" ? (tmp = "草稿") : "";
      val == "I" ? (tmp = "審核中") : "";
      val == "Y" ? (tmp = "發佈中") : "";
      val == "F" ? (tmp = "結束") : "";
      val == "D" ? (tmp = "下架") : "";
      return tmp;
    },
  },
  created() {
    this.myJson = Formdata;
    //console.log("created:",this.list1)
  },
};
</script>

<style scoped>
.myTable thead {
  background-color: #000000;
}
</style>
<style>
.hidden_cssd {
  border-top: 1px solid #f1f1f0;
  border-bottom: 3px solid #ebebe8;
  border-style: dotted;
}
.classempy {
  padding: 0;
}
.card {
}
.card-body {
}
.card-img {
  max-width: 980px;
}
.card-header {
  font-size: 18px;
  font-weight: bold;
  color: #b7bdc4;
}
.card-footer {
  padding: 1px;
}

.hrs {
  border: 0;
  padding-top: 1px;
  background: linear-gradient(to right, transparent, #b0b0b4, transparent);
}
#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: #bdc0c2;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}
.hr-text:before {
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}
.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: #bdc0c2;
  padding: 0 0.5em;
  line-height: 1em;
  color: #010e16;
  background-color: #fcfcfa;
}
</style>