import req from '../api/https.js'

export const makorgService = {
    orgwrite,
    uporgfile,
    findorg, 
    findorglevel,
    orgread,
    readorgdata,
    readorglist, 
    readorgpage,
    readorgbypage,
    putact,
    putorgdata,
    querymenber,
    queryfucntion,
    postorgman,
    deleteorgman,
    updateorgman,
    delItem,
    readorgItem,
    putOrgAdmins,
    queryHistory2,

}

function orgwrite(obj){
    return req('post','/manage/orgwrite',obj).then(rs=>{
        return rs.data
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function postorgman(obj){
    return req('post','/manage/postorgman',obj).then(rs=>{
        return rs
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function deleteorgman(obj){
    return req('delete','/manage/deleteorgman/'+obj.fid+'/'+obj.uid).then(rs=>{
        return rs
    }).catch(error =>{
        return Promise.reject(error);
    })
}


function delItem(obj){
    return req('delete','/manage/deletemyorg/'+obj.id+'/'+obj.oid+'/'+obj.uid).then(rs=>{
        return rs
    }).catch(error =>{
        return Promise.reject(error);
    })
}


function updateorgman(obj){
    return req('put','/manage/updateorgman',obj).then(rs=>{
        return rs
    }).catch(error =>{
        return Promise.reject(error);
    })
}


function uporgfile(obj){
    return req('put','/manage/uporgfile',obj).then(rs=>{  
        return rs.data
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function putorgdata(obj){
    return req('put','/manage/putorgdata',obj).then(rs=>{  
        return rs
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function putOrgAdmins(obj){
    return req('put','/manage/putOrgAdmins',obj).then(rs=>{  
        return rs
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function findorg(id){
    return req('get', '/manage/findOrg/'+id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function findorglevel(id){
    return req('get', '/manage/findOrgLevel/'+id).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function orgread(id){
    //console.log(id)
    return req('get','/manage/findOrges/'+id).then(rs=>{     
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })  
}

function readorgdata(typname){
    return req('get','/manage/findOrgtype/'+typname).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })  
}

function readorgItem(orgname){
    return req('get','/manage/findOrgName/'+orgname).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })  
}


function readorglist(id,state){
    return req('get','/manage/findOrgitems/'+id+"/"+state).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })  
}



function readorgpage(obj){
    return req('get','/manage/findUorgpage/'+obj.page+'/'+obj.uid+'/'+obj.modes).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })  
}

function readorgbypage(page,size,orgid){
    return req('get','/activity/findOrgpage/'+page+"/"+size+"/"+orgid).then(rs=>{
        return rs.data.results
    }).catch(error=>{
        return Promise.reject(error)
    })  
}


function putact(obj){
    return req('put','/manage/putact',obj).then(rs=>{
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    })     
}

function querymenber(id){
    return req('get', '/manage/querymenber/'+id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}  


function queryfucntion(id){
    return req('get', '/manage/findfunction/'+id).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function queryHistory2(p){
    return req('get', 'manage/historyLogs2/'+p.id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}
