<template>
  <div class="container-fluid">
    <a-spin tip="資料載入中..." :spinning="spinning">
      <div class="row">
        <div class="mt-3">
          <div style="margin:0px auto;max-width: 38rem;text-align:left;">          
            <b-card
              :img-src="jdata.url"
              img-alt="Image"
              img-top
              no-body
              tag="article"
              style="max-width: 38rem;"
            >
              <template #header>
                <h5 class="mb-0" style="text-align:center;">
                  <b-button variant="link" @click="toNext()" size="sm">
                    <b-icon icon="reply-all-fill" aria-hidden="true"></b-icon>回上一頁
                  </b-button>
                  <span class="Word_css" v-text="' 活動名稱 : '+jdata.title+' '"></span>
                </h5>
              </template>

              <a-tabs :default-active-key="jdata.type=='Y'?1:2">
                <a-tab-pane key="1" v-if="jdata.type=='Y'" tab="講師工作人員名單">
                  <b-list-group flush>
                    <b-list-group-item>
                      <span class="Word_css" v-text="' 活動開始時間 : '+jdata.sdate+' '"></span>
                    </b-list-group-item>
                    <b-list-group-item>
                      <span class="Word_css" v-text="' 活動結束時間 : '+jdata.edate+' '"></span>
                    </b-list-group-item>
                    <b-list-group-item v-for="element in sdata" :key="element.id">
                      <div style="display : block;float:left;">
                        <span
                          class="Word_css"
                          v-text="'角色 : ' + getRole(element.acts_signdetail[0].roles) + ' | 分數 :' + element.acts_signdetail[0].score"
                        />
                        <br />
                        <span class="Word_css" v-text="'顯示名稱 : ' + element.acts_signdetail[0].displayname" />
                      </div>
                      <div style="display : block;float:left;">
                        <b-button variant="link" v-if="jdata.type=='Y'" @click="delItem(element,jdata,element.roles)" size="sm">
                          <b-icon icon="trash" aria-hidden="true"></b-icon>刪除
                        </b-button>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                  <b-button-group>
                    <div class="text-center" style="margin-bottom: 1rem;">
                      <b-button v-if="jdata.type=='Y'" @click="showDrawer('新增講師','coach')" variant="success">新增講師</b-button>
                      <b-button v-if="jdata.type=='Y'" @click="showDrawer('新增工作人員','volunteer')" variant="secondary">新增工作人員</b-button>
                      <b-button v-if="jdata.type=='Y' && myData.length>0" @click="addGroup()" variant="warning">工作人員群組</b-button>                                            
                      <b-button v-if="jdata.type=='Y'" @click="upTime('更改時間','dTime')" variant="info">更改時間</b-button>
                      <b-button v-if="jdata.type=='Y' && firstData" @click="cancel(jdata)" variant="danger">{{counts>1?'取消該課程':'刪除整個活動'}}</b-button>
                      <b-button v-if="(getdate(jdata.sdate)>=getdate(currentTime))" @click="printQR(jdata)" variant="primary">列印QRCode</b-button>
                    </div>
                  </b-button-group>
                </a-tab-pane>
                <a-tab-pane key="2" tab="活動報到資訊">
                    <b-list-group flush ref="reportContent">
                      <b-list-group-item v-if="showrep" class="d-flex justify-content-end" style="margin-top:-1rem;margin-right: -1rem;" >                                              
                          <b-button-group>
                            <b-button v-if="(compareDate(jdata.edate,1)>=compareDate(currentTime,0))"  @click="printQR(jdata)" variant="primary">列印QRCode</b-button>
                            <b-button  @click="exportToPDF_PR" variant="info">
                            輸出報表
                            <b-icon icon="printer" aria-hidden="true"></b-icon>
                            </b-button>  
                            <b-button @click="exportToExcel" variant="success">
                              匯出 Excel 報表
                              <b-icon icon="file-earmark-excel" aria-hidden="true"></b-icon>
                          </b-button> 
                          </b-button-group>                        
                      </b-list-group-item>
                      <b-list-group-item v-if="showrep" >
                        <div class="checkbox-group horizontal">
                          <span >顯示資料 : </span>
                          <b-form-checkbox v-model="selectedFields" value="hphone">電話</b-form-checkbox>
                          <b-form-checkbox v-model="selectedFields" value="address">地址</b-form-checkbox>
                          <b-form-checkbox v-model="selectedFields" value="username">帳號名稱</b-form-checkbox>
                          <b-form-checkbox v-model="selectedFields" value="birth">生日</b-form-checkbox>
                          <b-form-checkbox v-model="selectedFields" value="sex">性別</b-form-checkbox>
                        </div>
                      </b-list-group-item>                       
                      <b-list-group-item v-if="!showrep" >
                        <span class="Word_css" v-text="' 活動名稱 : '+jdata.title+' '"></span>   
                      </b-list-group-item>          
                      <b-list-group-item >
                        <span class="Word_css" v-text="' 活動開始時間 : '+jdata.sdate+' '"></span>
                      </b-list-group-item>
                      <b-list-group-item >
                        <span class="Word_css" v-text="' 活動結束時間 : '+jdata.edate+' '"></span>
                      </b-list-group-item>                        
                      <b-list-group-item >
                        <span class="Word_css" v-text="' 報名人數 : '+sdata2.length+' ' + ' 報到人數 : '+doneMember(sdata2)"></span>
                      </b-list-group-item>  
                      
                      <b-list-group-item v-for="(element, index) in sdata2" :key="element.id" :class="{ 'page-break': (index + 1) % 10 === 0 }">
                        <div class="list-item-container">
                          <b-avatar v-if="showrep" variant="info" :src="element.pri_url" class="avatar"></b-avatar>
                          <div class="list-item-content">
                            <span class="Word_css" v-text="'角色 : ' + getRole(element.roles) + ' | 分數 :' + element.score"></span>
                            <br />
                            <span class="Word_css" v-text="'顯示名稱 : ' + element.displayname"></span>
                            <br />
                            <span v-text="'狀態 : '"></span>
                            <span class="Word_css" v-text="element.sid > 0 ? '已報到 (' + fdateformat(element.arrival) + ')' : '未報到'"></span>
                            <br />
                            <div v-for="field in selectedFields" :key="field" class="field-item">
                              <span>{{ getFieldLabel(field) }}:</span> {{ field === 'sex' ? formatSex(element[field]) : element[field] }}
                            </div>
                          </div>
                        </div>
                      </b-list-group-item>                      
                    </b-list-group>
                </a-tab-pane>
              </a-tabs>
            </b-card>
          </div>
        </div>
      </div>
      <a-drawer
        :title="ntitle"
        :width="720"
        :visible="visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="onClose"
      >
        <Getcoach :objs="sdata" :objs2="sdata2" :role="role" :jdata="jdata" @addMen="addMen" ref="con1" />
      </a-drawer>
      <b-modal
        ref="my-list"
        id="modal-2"
        title="選項"
        size="lg"
        @hide="onHide"
        scrollable
        centered
        hide-footer
        hide-header
      >
        <div class="text-center">
          <a-button-group >
            <a-button
              icon="close"
              :disabled="iconLoading2"
              @click="closeModal()"
            >(關閉)</a-button>            
            <a-button
              icon="printer"
              :disabled="selectedRows.length==0"
              :loading="iconLoading2"
              @click="exportToPDF('straight')" 
            >列印(直式)</a-button>
            <a-button
              icon="printer"
              :disabled="selectedRows.length==0"
              :loading="iconLoading2"
              @click="exportToPDF('horizontal')"
            >列印(橫式)</a-button>            
            <a-button 
            icon="plus-circle"
            :disabled="selectedRows.length==0"
            @click="plusitme" 
            >
            </a-button>
            <a-button
            icon="minus-circle"
            :disabled="selectedRows.length==1"
            @click="minusitme"
            >
            </a-button>
            <a-button
              icon="printer"
              :loading="iconLoading2"
              @click="exportToPDF('checkin')" wordCheckIn
            >報到步驟</a-button>            
          </a-button-group>
          <div class="classempy" id="word1head">
            <h6>
              <strong>
                <span v-text="'活動報到QRCode輸出 ( 份數'+ selectedRows.length +')'"></span>
              </strong>
            </h6>
          </div>
            <div v-if="myShow" id="word123">
            <div
              class="list-group-item"
              :id="'word'+element.uid"
              style="padding:1.1rem;margin:2px;"
              v-for="(element, index) in selectedRows"
              :key="element.uid"
            >
            <div v-if="(((index+1) % 4)==0)" style="padding:0.1rem;margin-bottom:0.1555rem;" />
              <b-container class="container" >
                <b-row>
                  <b-col style="padding:0;">
                    <div class="qrbody">
                    <vue-qr
                      ref="qrCode"
                      :text="element.value"
                      :logoSrc="logoPath"
                      :logoScale="20"
                      :size="mySize"
                      :margin="10"
                    />       
                  </div>                  
                  </b-col>
                  <b-col style="padding:0;text-align:left;">
                    <div style="display: flex;height: 300px;align-items: center; /* ★ */">
                      <strong>
                        <span
                        style="padding:1px;margin:1px;font-size: 20px;"
                        v-text="('活動名稱 : ' + element.title)"
                      /><br/>                        
                      <span
                        style="padding:1px;margin:1px;font-size: 20px;"
                        v-text="('活動日期(起) : ' + element.sDate)"
                      /><br/>
                      <span
                        style="padding:1px;margin:1px;font-size: 20px;"
                        v-text="('活動日期(訖) : ' + element.eDate)"
                      />
                      <br/>                                            
                    </strong>  
                    </div>
                </b-col>                  
                </b-row>
              </b-container>
              <div v-if="((index+1) % 3)==0 && index>1" style="padding:0.5rem;margin-bottom:0.1555rem;" />              
            </div>
          </div>
          <div v-if="!myShow" id="wordCheckIn">
            <img :src="catImg('checkin.png')" />
          </div>
        </div>
      </b-modal>
      <a-modal
      :title="'課程活動 : '+jdata.title"
      :visible="visible2"
      :closable="false"
      centered
    >
      <template slot="footer">       
        <a-button key="back" @click="handleCancel" :disabled="confirmLoading">
          取消
        </a-button>
        <a-button key="submit" type="primary" :loading="confirmLoading" :disabled="stime!='' && etime!='' && checkState" @click="handleOk">
          確定
        </a-button>
      </template>    
        <div>
          <a-alert v-if="msg!==''" :message="msg" type="success" />
          <div style="display : inline-block;margin-bottom: 0.5rem;">活動日期 : {{ getdate(jdata.sdate) }} <span v-if="stime!=='' && etime!==''" v-text="'開始時間 :'+stime.split(':').slice(0,2).join(':') +' 結束時間 :'+etime.split(':').slice(0,2).join(':')"></span></div><br/>
            開始時間 : <div style="display : inline-block;"><b-form-timepicker v-model="stime" size="sm" placeholder="請選擇時間"  :hour12=false locale="en" style="width:150px;"></b-form-timepicker></div>
            結束時間 : <div style="display : inline-block;"><b-form-timepicker v-model="etime" size="sm" placeholder="請選擇時間"  :hour12=false locale="en" style="width:150px;"></b-form-timepicker></div>
            <div style="display : inline-block;">
              <span v-if="checkState" style="color:#dd0606;"> ✘請確認活動時間</span> 
              <span v-if="stime!='' && etime!='' && !checkState" style="color:#0aae06;">✔資料正確</span> 
            </div>           
        </div>
    </a-modal>   
    
        <b-modal ref="my-group" id="modal-group" title="Loading"  @hide="onHide" centered hide-footer hide-header>
          <div class="text-center">       
            <div class="overflow-auto" style="height: 450px; width: 100%;" id="style-3">
              <a-radio-group v-model="selectedValue" class="radio-group">
                <div v-for="data in myData" :key="data.id" :class="data.id == selectedValue? 'cssSelected item-container':'cssUnSelected item-container'">
                  <div class="text-container">
                    <span class="displayname" v-text="data.groupname + '(群組人數: '+data.acts_grouplist.length+')'"></span>
                    <div class="container2">
                        <div v-for="dd in data.acts_grouplist" :key="dd.id" class="item-container2">
                            <b-avatar 
                            :src="dd.pri_url" 
                            variant="primary" 
                            text="data.displayname" 
                            size="2.5rem" 
                            class="align-baseline avatar">
                            </b-avatar>
                            <div class="text-container">
                            <span style="font-size: 14px; font-weight: bold;"
                            class="displayname" v-text="dd.displayname"></span>
                            </div>
                        </div>
                    </div>
                    <a-radio-button :value="data.id" class="radio-button">
                      選擇 <b-icon icon="pencil-square" scale="1" variant="primary"></b-icon>
                    </a-radio-button>
                  </div>
                </div>
              </a-radio-group>
            </div>
              <div style="padding:0.3rem;" ></div>
              <a-button-group >
                <a-button type="dashed" @click="cancelGroup()" :loading="loading2">
                  <b-icon  style="margin-right: 0.3rem;" scale="1.1" icon="x-circle" variant="secondary" />
                   取消 
                </a-button>                 
                <a-button
                  type="dashed"
                  @click="impGroup()" 
                  :disabled="selectedValue==null"
                  :loading="loading2"
                >                
                <b-icon  style="margin-right: 0.3rem;" scale="1.1" icon="arrow-repeat" variant="secondary" />
                  匯入 
                </a-button>                                              
              </a-button-group>  
          </div>
        </b-modal>     

    </a-spin>
  </div>
</template>
<script>
import { mapState } from "vuex";
import * as moment from "moment/moment";
import router from "../../router";
import { actService,userService,joinService } from "../../_services";
import Getcoach from "../../member/Getcoach.vue";
import VueQr from "vue-qr";
import logoImg from "@/assets/lineicon.svg";
import html2pdf from "html2pdf.js";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const queryData = params => {
  return actService.findActdetail(params.id, params.aid);
};

const queryData2 = params => {
  return actService.findActdetail3(params.id, params.aid);
};

const deleteItem = params => {
  return actService.delActclass(params.id, params.aid, params.fid);
};

const deleteItemam = params => {
  return actService.delMan(params.id, params.aid, params.fid, params.uid, params.roles);
};


const deleteItemAct = params => {
  return actService.delAct(params.id);
};

const inertmen = params => {
  return actService.inertMan(params);
};

const putData = params => {
  return actService.upAct(params);
};

const queryVolunteer = obj => {
  return userService.myGroups(obj);
}; 

const queryBaid = (params) => {
  return joinService.checkbyDate(params);
};

export default {
  name: "Act_details",
  components: {
    Getcoach,
    VueQr
  },
  props: ["rs"],
  data() {
    return {
      jdata: "",
      visible: false,
      visible2:false,
      spinning: false,
      iconLoading2: false,
      iconLoading: false, 
      loading2: false,           
      logoPath: logoImg,
      msg:"",
      objs:null,
      selectedRows: [],
      domList:[],
      ntitle: "",
      form: null,
      sdata: [],
      sdata2: [],
      role:'coach',
      information: [],
      myData: [], 
      currentTime: moment(),
      stime:"",
      etime:"",      
      counts:0,
      confirmLoading: false,
      mySize:310, 
      myShow:true, 
      firstData:true,
      selectedValue: null,    
      showrep:true,   
      selectedFields: [], 
    };
  },
  watch: {
    defaultval(val) {
      console.log(val)
      this.myReload();
    }
  },
  computed: {
    ...mapState("account", ["status", "user"]),
    ...mapState("makeorg", [ "defaultval"]),
    checkState() {
      let t1 = this.etime.split(':').slice(0,2).join(':')
      let t2 = this.stime.split(':').slice(0,2).join(':')
      if(t1 < t2){
        return true
      }else if(t1 === t2){
        return true
      }else{      
        return false
      }
    },    
  },
  async mounted() {
    this.jdata =
      this.$route.params.rs !== undefined ? this.$route.params.rs : [];
      this.counts = this.$route.params.counts
      this.firstData =  this.getdate(this.$route.params.sdate)==this.getdate(this.jdata.sdate)? false:true 
    if (this.jdata.id !== undefined && this.jdata.aid !== undefined) {
      this.fetch({ id: this.jdata.id, aid: this.jdata.aid });
    }
    this.myReload();
  },
  methods: {
    toNext() {
      router.go(-1);
    },

    onclick(val) {
      if (val.type == "Y") {
        router.replace({ name: "Act_details", params: { rs: val } });
      }
    },

    myReload() {
      queryVolunteer({id:this.defaultval.id
            }).then(({ data }) => {
                if(data!==undefined){
                    this.myData = data//data.sort((a, b) => b.act - a.act);
                }
            });
    },
    
    addGroup(){
      this.$refs['my-group'].show()
    },

    cancelGroup(){
      this.$refs['my-group'].hide()
    }, 
    
    async impGroup() {
        this.spinning = true;
        this.loading2 = true;
        let tmp = this.myData.filter(item => item.id === this.selectedValue);
        
        for (let row of tmp[0].acts_grouplist) {
            let rs = await this.GetAid(row.uid);
            if (rs === false) {
                let temp = {
                    uid: row.uid,
                    desc: "",
                    name: row.name,
                    title: "",
                    company: "",
                    finkurl: "",
                    iinkurl: "",
                    url: "",
                    getscore: tmp[0].getscore,
                };
                let rs = {
                    id: this.jdata.id,
                    fid: this.jdata.fid,
                    aid: this.jdata.aid,
                    uid: temp.uid,
                    desc: temp.desc == undefined ? "" : temp.desc,
                    name: temp.name,
                    title: temp.title == undefined ? "" : temp.title,
                    company: temp.company == undefined ? "" : temp.company,
                    finkurl: temp.finkurl == undefined ? "" : temp.finkurl,
                    iinkurl: temp.iinkurl == undefined ? "" : temp.iinkurl,
                    getscore: temp.getscore,
                    url: temp.url == undefined ? "" : temp.url,
                    sdate: this.jdata.sdate,
                    edate: this.jdata.edate,
                    types: 'volunteer'
                };
                await inertmen(rs);
            }
        }
        
        await this.fetch({ id: this.jdata.id, aid: this.jdata.aid });
        this.spinning = false;
        this.loading2 = false;
        this.$refs['my-group'].hide();
    },
  
    
    async handleOk() {
      this.confirmLoading = true;
      await this.toUpData().then((val)=>{return val}).catch((val)=>{return val})
    },

    Gemanaid(muid) {
     let params = {
        uid: muid,
        fid: this.jdata.fid,
        aid: this.jdata.aid,
      }      
      return queryBaid({
        ...params,
      }).then(({ data }) => data.info);
    },

    GetAid(muid) {
      return new Promise((resolve) => {        
        resolve(this.Gemanaid(muid));
      });
    },    

    toUpData(){

      return new Promise((resolve,reject)=>{ 
        let t1 = this.getdate(this.jdata.sdate)+' '+this.stime.split(':').slice(0,2).join(':')
        let t2 = this.getdate(this.jdata.edate)+' '+this.etime.split(':').slice(0,2).join(':')                
        let obj = {
          id:this.jdata.id,          
          aid:this.jdata.aid, 
          fid:this.jdata.fid,
          date: this.getdate(this.jdata.sdate),
          sdate:t1,
          edate:t2,
          stime:this.stime.split(':').slice(0,2).join(':'),
          etime:this.etime.split(':').slice(0,2).join(':')} 
        putData({
            ...obj,
          }).then(({ data }) => { 
              if(data.code==200){  
                this.msg = "資料更新成功"                
                setTimeout(() => {
                  this.jdata.sdate =  t1
                  this.jdata.edate = t2
                  this.confirmLoading = false;
                  this.visible2 = false
                  resolve(data)                   
                }, 1000);                 
              }else{
                this.msg = "資料更新失敗!"                
                setTimeout(() => {
                  this.confirmLoading = false;
                  this.visible2 = false                
                  reject(data)                                     
                }, 1000);                 
              } 
              this.stime="" 
              this.etime =""             
          });
        
        
      })
    },    

    handleCancel() {  
      this.msg = ""  
      this.stime="" 
      this.etime =""
      this.visible2 = false;
    },  
    
    upTime(){
      this.visible2 = true;
      this.msg="",
      this.stime =  moment(this.jdata.sdate).toDate().toTimeString().slice(0, 8)
      this.etime = moment(this.jdata.edate).toDate().toTimeString().slice(0, 8)    
    },

    async addMen(types, item) {
      this.form = item;
      this.visible = false;
      this.spinning = true;
      let self = this;
      let rs = {
        id: this.jdata.id,
        fid: this.jdata.fid,
        aid: this.jdata.aid,
        uid: this.form.uid,
        desc: this.form.desc==undefined ? "" : this.form.desc,
        name: this.form.name,
        title: this.form.title==undefined ? "" : this.form.title,
        company: this.form.company==undefined ? "" : this.form.company,
        finkurl: this.form.finkurl==undefined ? "" : this.form.finkurl,
        iinkurl: this.form.iinkurl==undefined ? "" : this.form.iinkurl,
        getscore:this.form.getscore,
        url: this.form.url==undefined ? "" : this.form.url,
        sdate: this.jdata.sdate,
        edate: this.jdata.edate,
        types
      };
      inertmen(rs).then(({ data }) => {
        if (data.code == 200) {
          self.spinning = false;
          this.fetch({ id: this.jdata.id, aid: this.jdata.aid });
        }
      });
    },

    getRole(val) {
      let tmp = "";
      val == "actor" ? (tmp = "參加者") : "";
      val == "broker" ? (tmp = "主辦單位") : "";
      val == "coach" ? (tmp = "講師") : "";
      val == "volunteer" ? (tmp = "工作人員") : "";
      val == "D" ? (tmp = "下架") : "";
      return tmp;
    },

    fetch(params = {}) {
      queryData({
        ...params //...解壓縮出來
      }).then(({ data }) => {
        this.sdata = data;
      });
      queryData2({
        ...params //...解壓縮出來
      }).then(({ data }) => {
        this.sdata2 = data;
      });
    },

    getdate(val) {
      return moment(val).format("YYYY-MM-DD");
    },

    fdateformat(val) {
      return moment(val).format("YYYY-MM-DD HH:mm");
    },  
    doneMember(obj){
      return obj.filter(item => item.sid > 0).length;
    },
    compareDate(val, kind) {
      if (kind === 1) {
        return moment(val).add(1, 'hours').format("YYYY-MM-DD HH:mm");
      } else {
        return moment(val).format("YYYY-MM-DD HH:mm");
      }
    },
    
    showDrawer(va1,va2) {
      this.visible = true;
      this.ntitle = va1;
      this.role = va2
      this.form = null;
      setTimeout(() => {
        this.$refs["con1"].clearn();                                   
      }, 200); 
    },
    onClose() {
      this.visible = false;
    },

    cancel(item) {
      const self = this;
      let msg ="您確定要取消該課程";
      if(self.counts==1){
        msg="您確定要刪除唯一課程, 刪除後該活動將同時被取消"
      }
      this.$confirm({
        title: msg,
        content: "活動名稱 : " + item.title,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        async onOk() {
          if(self.counts==1){
            await self.removeAct(item);
          }else{
            await self.remove(item);
          }
        },
        onCancel() {
          console.log("Cancel");
        }
      });
    },
    
    async removeAct(item) {
      try {
        const response = await deleteItemAct({ id: item.id });
        if (response.data.code == 200) {
          router.replace({ name: "Act_my" });
        } else {
          this.$message.warning("刪除發生錯誤");
        }
      } catch (error) {
        // 處理異常情況，比如網絡錯誤或服務器錯誤
        this.$message.error("操作失敗");
      }
    },
    
    async remove(item) {
      await deleteItem({ id: item.id, aid: item.aid , fid : item.fid}).then(({ data }) => {
        data.code == 200
          ? router.go(-1)
          : this.$message.warning("刪除發生錯誤");
      });
    },

    delItem(item, items,roles) {
      const self = this;
      this.$confirm({
        title:
          "您確定要刪除該講師/工作人員 : " + item.acts_signdetail[0].username + "?",
        content: "活動名稱 : " + item.acts_signdetail[0].actitle,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        async onOk() {
          await self.removem(item, items,roles);
        },
        onCancel() {
          console.log("Cancel");
        }
      });
    },   

    async removem(item, items,roles) {
      await deleteItemam({ id: items.id, aid: items.aid, fid:item.fid, uid: item.uid, roles }).then(
        ({ data }) => {
          data.code == 200
            ? this.fetch({ id: this.jdata.id, aid: this.jdata.aid })
            : this.$message.warning("刪除發生錯誤");
        }
      );
    },

    //----------------------------------------------------------------
    printQR(val){
      //console.log(val)
      this.selectedRows=[]
      let tmp =
      "actjoin:" +val.fid +
        "," +
        val.sdate +
        "," +
        val.edate +
        "," +
        val.aid +
        "," +
        this.user.data.user.id +
        "," +        
        val.limits.value
        ;
      this.selectedRows.push({uid:this.makeid(10),value:tmp,sDate:val.sdate,eDate:val.edate,title:val.title})
      this.$refs["my-list"].show();
    },
    closeModal() {
      this.$refs["my-list"].hide();
    },     

    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    },

    catImg(imgs) {
      return require(`../../assets/${imgs}`);
    },    
    
    exportToPDF(value) {
      let myShoots = 'word123'
      if(value=='checkin'){
        this.myShow=false;
        myShoots='wordCheckIn'
      }
      // pdf匯出設定  //straight 直式 horizontal 橫式
      this.mySize=value === 'horizontal' ? 510 : 350
      this.iconLoading2 = true;
      this.iconLoading = true;      
      setTimeout(() => {  
        const opt = {
        margin: 0.3,
        filename: "document.pdf",
        image: { type: "jpeg", quality: 0.99 },
        html2canvas: { dpi: 192, scale: value === 'horizontal' ? 2 : 1, letterRendering: true, useCORS: true }, // 增加了 scale 参数
        jsPDF: { unit: "in", format: "a4", orientation: value === 'horizontal' ? "landscape" : "portrait" }
      };

      this.domList = [];
      // 抓取各個圖片的DOM元素，並把它裝入doc這個陣列裡
      let newDiv = document.createElement("div");
      //let j = this.items.act_detrimal.length
      let head1 = document.getElementById("word1head");
      const clone1 = head1.cloneNode(true);
      newDiv.appendChild(clone1);
      let element1 = document.getElementById(myShoots);
      this.domList.push(element1);
      /*const imgElement = document.createElement("img");
      imgElement.src = this.catImg('checkin.png'); // 替換為您的PNG圖檔URL或路徑
      this.domList.push(imgElement);
      console.log(this.domList)*/
      // 開始進行列印
      let doc = html2pdf()
        .from(this.domList[0])
        .set(opt)
        .toPdf();
      for (let j = 1; j < this.domList.length-1; j++) {
        doc = doc
          .get("pdf")
          .then(pdf => pdf.addPage())
          .from(this.domList[j])
          .toContainer()
          .toCanvas()
          .toPdf();
      }
      // 列印完成輸出done
      doc
        .save()
        .then(() => ((this.iconLoading = false), (this.iconLoading2 = false)));
      //
      this.mySize=310
      this.myShow=true;         
      }, 150);             
    },

  exportToPDF_PR() {
    this.showrep = false;
    setTimeout(() => {
      this.$nextTick(() => {
        const element = this.$refs.reportContent;
        if (!element) {
          alert('無法找到要輸出的內容');
          return;
        }

        const options = {
          margin: 10,
          filename: '活動報表.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
          pagebreak: {
            mode: ['avoid-all'],   // 避免所有標籤在頁尾被分開
            avoid: ['.avoid-page-break'],  // 避免特定標籤內部分頁
          },
        };

        html2pdf().from(element).set(options).save().finally(() => {
          this.showrep = true;
        });
      });
    }, 150);
  },

  getFieldLabel(field) {
      const labels = {
        hphone: "電話",
        address: "地址",
        username: "帳號名稱",
        birth: "生日",
        sex: "性別",
      };
      return labels[field] || field;
    },
    formatSex(sex) {
      switch (sex) {
        case "female":
          return "女性";
        case "male":
          return "男性";
        case "other":
          return "其它";
        case "":
          return "未填寫";
        default:
          return sex;
      }
    },

    
    makeid(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result + Date.now();
    }, 
    
    
    plusitme(){
      let tmp  = this.selectedRows[0].value
      this.selectedRows.push({uid:this.makeid(10),value:tmp,sDate:this.selectedRows[0].sDate,eDate:this.selectedRows[0].eDate,title:this.selectedRows[0].title})
    },
    minusitme(){
      if(this.selectedRows.length>1){
        let tmp =this.selectedRows.slice();
        this.selectedRows = tmp.slice(0,tmp.length -1)
      }
    },
    
        // 匯出 Excel 方法
    exportToExcel() {
      // 預設欄位
      const baseFields = [
        { key: 'displayname', label: '顯示名稱' },
        { key: 'roles', label: '角色', format: (value) => this.getRole(value) },
        { key: 'score', label: '分數' },
        { key: 'arrival', label: '狀態', format: (value, element) => (element.sid > 0 ? `已報到 (${this.fdateformat(value)})` : '未報到') },
      ];

      // 動態加入勾選的欄位
      const dynamicFields = this.selectedFields.map((field) => ({
        key: field,
        label: this.getFieldLabel(field),
        format: field === 'sex' ? (value) => this.formatSex(value) : null,
      }));

      // 合併欄位
      const fields = [...baseFields, ...dynamicFields];

      // 生成 Excel 資料
      const data = this.sdata2.map((element) =>
        fields.reduce((row, field) => {
          row[field.label] = field.format ? field.format(element[field.key], element) : element[field.key];
          return row;
        }, {})
      );

      // 建立工作簿與工作表
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, '活動報表');

      // 將工作簿轉換為 Blob 並下載
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(blob, '活動報表.xlsx');
    },


  }
};
</script>
<style scoped>
.Word_css {
  padding: 1px;
  margin: 1px;
  font-size: 14px;
}

.checkboxcss{
  background-color:#f0f2f5;
  margin:6px;
  padding:5px;
  border-style:dotted;
  border-width:1px;
  border-color:#d0d2d5;
}

.overflow-auto {
  overflow-y: auto;
}

.radio-group {
  display: flex;
  flex-direction: column;
}

.item-container {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.avatar {
  margin-right: 10px;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.displayname {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.radio-button {
  display: flex;
  align-items: center;
}

.cssSelected {
  font-size:16px;
  font-weight:bold;
  padding-top:0.28rem;
  background: linear-gradient(-45deg, #ffffff, #73bcda, #bfe7f7);
}
.cssUnSelected {
  font-size:15px;
  padding-top:0.28rem;
  background: linear-gradient(-45deg, #ffffff, #d8edf5, #ffffff);
}
.container2 {
  display: flex;
  flex-wrap: wrap;
}

.item-container2 {
  width: 25%; /* 每行四个项目 */
  box-sizing: border-box;
  padding: 10px; /* 根据需要调整 */
}
.page-break {
  page-break-after: always;
}

.checkbox-group {
  margin-bottom: -2px;
}

.checkbox-group.horizontal {
  display: flex;
  gap: 10px;
}

.field-item {
  margin-bottom: 5px;
}

.list-item-container {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.avatar {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
}

.list-item-content {
  flex-grow: 1;
}


</style>