var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-table',{attrs:{"columns":_vm.columns,"row-key":record => record.id,"data-source":_vm.data,"pagination":_vm.pagination,"loading":_vm.loading,"locale":_vm.locale},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"sdate",fn:function(record){return [_c('router-link',{attrs:{"to":{name:'Act_date',params:{ id: record.id}}}},[_c('b-button',{attrs:{"variant":"link","size":"sm"}},[_c('b-icon',{attrs:{"icon":"calendar3","aria-hidden":"true"}}),_c('span',{domProps:{"textContent":_vm._s(' '+_vm.fdateformat(record.sdate))}})],1)],1)]}},{key:"actitle",fn:function(record){return [_c('router-link',{attrs:{"to":{
                    name:'Actinfo', 
                    params: { id: record.id}
                    },"target":"_blank"}},[_vm._v(_vm._s(record.actitle))])]}},{key:"states",fn:function(record){return [_c('span',{domProps:{"textContent":_vm._s(_vm.ctypes(record.states))}})]}},{key:"action",fn:function(record){return [(record.states=='N')?_c('a',{attrs:{"href":"javascript: void(0)"},on:{"click":function($event){return _vm.del(record)}}},[_c('b-icon',{attrs:{"icon":"trash","font-scale":"1.2","variant":"danger"}}),_vm._v(" 刪除 ")],1):_vm._e(),(record.states=='N')?_c('router-link',{attrs:{"to":{
              name:'Actedite', 
              params: { id: record.id, edit:'revise'}
              }}},[_c('b-icon',{attrs:{"icon":"pencil-square","font-scale":"1.2"}}),_vm._v("編輯 ")],1):_vm._e(),_c('a',{attrs:{"href":"javascript: void(0)"},on:{"click":function($event){return _vm.copyAct(record)}}},[_c('b-icon',{attrs:{"icon":"files","font-scale":"1.2"}}),_vm._v(" 複製 ")],1),(record.states=='Y' && _vm.fdateformat(record.sdate)>_vm.fdateformat(_vm.currentTime) )?_c('a',{attrs:{"href":"javascript: void(0)"},on:{"click":function($event){return _vm.del(record)}}},[_c('b-icon',{attrs:{"icon":"trash","font-scale":"1.2","variant":"danger"}}),_vm._v(" 刪除 ")],1):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }