<template>
  <div>
    <div class="ddic">
      活動審核
      <div class="divs">
        <a-table
          :columns="columns"
          :row-key="record => record.id"
          :data-source="data"
          :pagination="pagination"
          :loading="loading"
          :locale="locale"  
          @change="handleTableChange"
          :scroll="{ y: 230 }"
        >
          <template slot="sdate" slot-scope="record">
            <span v-text="' '+fdateformat(record.sdate)"></span>
          </template>
          <template slot="actitle" slot-scope="record">
            <a href="javascript:;" @click="showinfo(record.fid)">
              <span v-text="record.actitle"></span>
            </a>
          </template>
          <template slot="rsflows" slot-scope="record">
            <a-steps progress-dot :current="getstep(record.rsflows)">
              <a-step
                v-for="item in record.rsflows"
                :key="item.seq"
                :title="retname(item.cfstate)"
                :description="item.ntname"
              />
            </a-steps>
          </template>
          <template slot="history" slot-scope="record">
            <a-button type="link" size="large" @click="openHistory(record)" shape="circle" icon="history" ></a-button>
          </template>          
          <template slot="statuse" slot-scope="record">
            <a-button @click="opencfm(record)" icon="carry-out">請審核</a-button>
          </template>
        </a-table>
      </div>
    </div>
    <b-modal
      ref="my-loading"
      id="modal-1"
      size="xl"
      title="活動資訊"
      @hide="onHide"
      centered
      hide-footer
    >
      <div class="overflow-auto" style="height: 600px;" id="style-3">
        <div class="text-left">
          <Inforject v-if="objs!=='{}'" :obj="objs" />
        </div>
      </div>
    </b-modal>

    <b-modal ref="confirm" id="modal-1" size="sm" title="活動審核" @hide="onHide" centered hide-footer>
      <div class="text-left">
        <a-alert v-if="message!==''" :message="message" type="success" />
        <span v-if="cfm!==null" v-text="'活動名稱 : ' +cfm.actitle" />
        <br />
        <span v-if="cfm!==null" v-text="'活動日期 : ' +fdateformat(cfm.sdate)" />
        <br />
        <p class="h6">
          <a-button-group :size="'large'">
            <a-button type="primary" @click="runcfm()" :loading="loadings">
              <a-icon type="check-circle" />審核通過
            </a-button>
            <a-button type="danger" @click="runcancel()" :disabled="desc==''" :loading="loadings">
              審核退件
              <a-icon type="close-circle" />
            </a-button>
          </a-button-group>
        </p>
        <p>
          <b-form-group id="input-group-1" style="width:99%" label label-for="input-1">
            <b-form-textarea
              id="input-1"
              v-model="desc"
              type="text"
              placeholder="請輸入備註事項 "
              rows="8"
              autocomplete="off"
            ></b-form-textarea>
          </b-form-group>
        </p>
      </div>
    </b-modal>
    <b-modal ref="historyMsg" scrollable  id="modal-tall" title="審核歷程" @hide="onHide" centered hide-footer>
        <a-spin tip="資料載入中..." size="large" :spinning="historyShow">
          <div class="spin-content">
            <a-list
              bordered
              item-layout="vertical"
              :data-source="this.myHistory"
              :render-item="renderItem"
            >
            </a-list>            
          </div>
      </a-spin>
    </b-modal>    
  </div>
</template>
<script>
import { actService, macService } from "../../../_services";
import * as moment from "moment/moment";
import Inforject from "../../../actinfo/panel/Inforact.vue";
import { mapState } from "vuex";
import 'moment/locale/zh-tw';

const queryData = params => {
  return actService.findUconfrimly(params);
};

const queryData2 = params => {
  return macService.macfind(params.id);
};

const putdata = obj => {
  return actService.putactype(obj);
};

const putcancel = obj => {
  return actService.putcancel(obj);
};

const queryHistory = (params) => {
  return actService.queryHistory(params);
};

const columns = [
  {
    title: "活動日期",
    dataIndex: "",
    width: "10%",
    ellipsis: true,
    scopedSlots: { customRender: "sdate" }
  },
  {
    title: "活動名稱",
    dataIndex: "",
    width: "35%",
    ellipsis: true,
    scopedSlots: { customRender: "actitle" }
  },
  {
    title: " 簽核流程 ",
    dataIndex: "",
    ellipsis: true,
    scopedSlots: { customRender: "rsflows" },
    width: "35%"
  },
  {
    title: "歷程",
    dataIndex: "",
    ellipsis: true,
    scopedSlots: { customRender: "history" },
    width: "10%"
  },  
  {
    title: "狀態",
    dataIndex: "",
    ellipsis: true,
    scopedSlots: { customRender: "statuse" },
    width: "10%"
  }
];

export default {
  name: "Verify_topbar",
  components: {
    Inforject
  },
  data() {
    return {
      pagination: { defaultPageSize: 4 },
      columns,
      loading: false,
      historyShow:true,
      data: [],
      myHistory:[],
      desc: "",
      objs: {},
      cfm: null,
      current: 0,
      message: "",
      loadings: false,
      locale:{
        filterConfirm:'確定',
        filterReset: '重置',
        emptyText: '暫時無任何數據'        
      },       
    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
    ...mapState("makeorg", ["defaultval"])
  },
  mounted() {
    this.reload();
  },
  watch: {
    defaultval() {
      this.reload();
    }
  },
  methods: {
    reload() {
      this.fetch({
        results: 4,
        page: 1,
        pageSize: 4,
        uid: this.defaultval.id,
        modes: "R"
      });
    },

    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        uid: this.defaultval.id,
        modes: "R",
        ...filters
      });
    },

    fetch(params = {}) {
      this.loading = true;
      queryData({
        ...params
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        pagination.total = data.info.totail;
        this.loading = false;
        this.data = data.results;
        this.pagination = pagination;
      });
    },

    fetch2(params = {}) {
      queryData2({
        ...params
      }).then(({ data }) => {
        this.objs = data;
      });
    },

    showinfo(ids) {
      this.fetch2({ id: ids });
      this.$refs["my-loading"].show();
    },

    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    },

    retname(va2) {
      let tmp = "";
      va2 == "F" ? (tmp = "已送呈") : "";
      va2 == "R" ? (tmp = "請審核") : "";
      va2 == "R" ? (tmp = "審核中") : "";          
      va2 == "N" ? (tmp = "未簽核") : "";
      va2 == "Y" ? (tmp = "已簽核") : "";
      return tmp;
    },

    getstep(rs) {
      for (var i in rs) {
        if (rs[i].cfstate == "R") {
          return rs[i].seq;
        }
      }
    },

    fdateformat(val) {
      return moment(val).format("YYYY-MM-DD");
    },
    ctypes(val) {
      //return狀態 R請審核 未審核, Y已審核, F結束, D下架
      let tmp = "";
      val == "F" ? (tmp = "已送呈") : "";
      val == "R" ? (tmp = "請審核") : "";
      val == "I" ? (tmp = "審核中") : "";          
      val == "N" ? (tmp = "未審核") : "";
      val == "Y" ? (tmp = "已審核") : "";
      return tmp;
    },

    isShow(val) {
      for (var i in val.rsflows) {
        if (
          val.rsflows[i].ntypeid == this.defaultval.id &&
          val.rsflows[i].seq > 1 &&
          val.rsflows[i].ntypeid !== ""
        ) {
          if (val.rsflows[i - 1].cfstate == "N") {
            return false;
          } else {
            return true;
          }
        }
      }
      return true;
    },
    opencfm(val) {
      this.cfm = val;
      this.desc =''
      this.$refs["confirm"].show();
    },

    openHistory(val){
      this.myHistory=[];
      this.historyShow=true ;
      this.$refs["historyMsg"].show();
      setTimeout(async() => {
        this.myHistory=await this.GetHistory(val.fid); 
        this.historyShow=false;             
         clearInterval();
      }, 800);      
    },

    GetHistory(id) {
      let params = {id}            
      return new Promise((resolve) => {        
        resolve(          
          queryHistory({...params}).then(({ data }) => data)          
        );
      });
    },
    
    renderItem(item) {
      return (
        <a-list-item>
          <a-list-item-meta
            title={
              <div>
                <strong>審核者：</strong> {item.displayname}
                <span style="margin-left: 10px; color: {item.statuse === 'F' ? 'green' : 'red'};">
                  <strong>狀態：</strong> {this.getStatusText(item.statuse)}
                </span>
              </div>
            }
            description={
              <span>
                <strong>審核時間：</strong>{" "}
                {moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}
              </span>
            }
          />
          <div>
            <strong>說明：</strong> {item.desc}
          </div>
        </a-list-item>
      );
    }, 
    
    getStatusText(statuse) {
      return statuse === "F" ? "完成" : "退回";
    },    

    runcfm() {
      this.loadings = true;
      let obj = null;
      for (var i in this.cfm.rsflows) {
        if (this.cfm.rsflows[i].cfstate == "R") {
          obj = this.cfm.rsflows[i];
          if (obj !== null) {
            obj.desc = this.desc;
            obj.uid=this.user.data.user.id
            putdata(obj).then(({ data }) => {
              if (data.code == 200) {
                this.message = "執行成功";
                //this.fetch({ id: this.defaultval.id, modes: "R" });
                this.$emit("reload");
                setTimeout(() => {
                  this.message = "";
                  this.loadings = false;
                  this.$refs["confirm"].hide();
                  clearInterval();
                }, 1500);
              }
            });
          }
          break;
        }
      }
    },
    runcancel() {
      this.loadings = true;
      let obj = null;
      for (var i in this.cfm.rsflows) {
        if (this.cfm.rsflows[i].cfstate == "R") {
          obj = this.cfm.rsflows[i];
          if (obj !== null) {
            obj.desc = this.desc;
            obj.uid=this.user.data.user.id
            putcancel(obj).then(({ data }) => {
              if (data.code == 200) {
                this.message = "執行成功"; 
                //this.fetch({ id: this.defaultval.id, modes: "R" });              
                this.$emit("reload");
                setTimeout(() => {
                  this.message = "";
                  this.desc = "";
                  this.loadings = false;
                  this.$refs["confirm"].hide();
                  clearInterval();
                }, 1500);
              }
            });
          }
          break;
        }
      }
    },
    runcfm2(val) {
      //let tmp = JSON.parse(val)
      let qty = val.rsflows.length;
      this.isBusy = true;
      let checkstate = "Y";
      for (var obj in val.rsflows) {
        if (
          val.rsflows[obj].cfstate == "N" &&
          val.rsflows[obj].ntypeid >0 &&
          val.rsflows[obj].ntypeid == this.defaultval.id
        ) {
          val.rsflows[obj].cfstate = "Y";
          for (var i in val.rsflows) {
            if (
              val.rsflows[i].cfstate == "N" &&
              val.rsflows[i].ntypeid > 0
            ) {
              checkstate = "N";
              break;
            }
          }
          let cfmorg = "";
          if (obj < qty) {
            cfmorg = val.rsflows[obj].ntypeid;
          }
          let contents =
            "活動名稱 : " +
            val.actitle +
            "<br/> 活動日期 : " +
            this.fdateformat(val.sdate) +
            "<br/> 申請單位 :" +
            val.rsflows[0].orgname;

          let tmp = {
            id: val.id,
            fowid: val.flowid,
            seq: val.rsflows[obj].seq,
            ntypeid: val.rsflows[obj].ntypeid,
            userid: this.user.data.user.id,
            done: checkstate,
            nextcfm: cfmorg,
            rcontents: contents,
            rtitle: val.actitle,
            fid: val.fid,
            orgid: val.rsflows[0].orgid,
            fstate: checkstate == "N" ? "I" : checkstate
          };
          putdata(tmp).then(({ data }) => {
            if (data.code == 200) {
              //this.fetch({ id: this.defaultval.id, modes: "R" });
              this.$emit("reload");
              setTimeout(() => {
                this.isBusy = false;
                clearInterval();
              }, 1000);
            }
          });

          break;
        }
      }
    }
  }
};
</script>
<style scoped>
.myTable thead {
  background-color: #000000;
}
#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #000000;
}

#style-3 {
  text-align: left;
}
.ddic {
  padding: 20px;
  font-size: 14px;
  text-align: left;
  border-radius: 15px;
  border-width: 1px;
  height: 400px;
  margin: 13px auto;
  border-style: dotted;
  border-color: #e0dedc;
  transition: all 0.3s ease;
}
.divs {
  max-height: 335px;
}
</style>
<style>
.hidden_cssd {
  border-top: 1px solid #f1f1f0;
  border-bottom: 3px solid #ebebe8;
  border-style: dotted;
}
</style>