<template>
  <div class="container-fluid">
    <a-spin tip="資料載入中..." :spinning="spinning">
    <div class="row">
      <b-card
      >
      <template #header>
        <h6 class="mb-0">
          <span v-text="'活動名稱: '+jdata.actitle + ' | 活動起訖時間 : '+jdata.sdate+'~'+jdata.edate" />
          <b-button variant="link" @click="toNext()" size="sm">
                    <b-icon icon="reply-all-fill" aria-hidden="true"></b-icon>回上一頁
          </b-button>
        </h6>
      </template>      
        <a-calendar
          :value="currentTime"
          :style="{ height:'49.8rem' ,border: '1px solid #d9d9d9', borderRadius: '4px' }"
          :locale="locale"
          @select="onSelect"
          @panelChange="handlePanelChange"
          class="overflow-hidden"
        >
          <ul slot="dateCellRender" slot-scope="value" class="events">
            <li v-for="item  in getListData(value)" :key="item.content">
              <div
                class="notes_css"
                @click="onclick(item)"
                :style="{'background-color': item.type==='Y'?'#1371de':'#858785'}"
              >
                <div style="color:#e3eefc;font-size:12px;" v-html="getpeople(item.actstaff)" />
              </div>
            </li>
          </ul>
          <template slot="monthCellRender" slot-scope="value">
            <div v-if="getMonthData(value)" class="notes-month">
              <section>{{ getMonthData(value) }}</section>
              <span>Backlog number</span>
            </div>
          </template>
        </a-calendar>
      </b-card>
      <a-modal
      :title="'課程活動 : '+jdata.actitle"
      :visible="visible"
      :closable="false"
      centered
    >
      <template slot="footer">       
        <a-button key="back" @click="handleCancel" :disabled="confirmLoading">
          取消
        </a-button>
        <a-button key="submit" type="primary" :loading="confirmLoading" :disabled="checkState" @click="handleOk">
          確定
        </a-button>
      </template>    
        <div>
          <div style="display : inline-block;margin-bottom: 0.5rem;">活動日期 : {{ selectDate }} <span v-if="stime!=='' && etime!==''" v-text="'開始時間 :'+stime.split(':').slice(0,2).join(':') +' 結束時間 :'+etime.split(':').slice(0,2).join(':')"></span></div><br/>
            開始時間 : <div style="display : inline-block;"><b-form-timepicker v-model="stime" size="sm" placeholder="請選擇時間"  :hour12=false locale="en" style="width:150px;"></b-form-timepicker></div>
            結束時間 : <div style="display : inline-block;"><b-form-timepicker v-model="etime" size="sm" placeholder="請選擇時間"  :hour12=false locale="en" style="width:150px;"></b-form-timepicker></div>
            <div style="display : inline-block;">
              <span v-if="checkState" style="color:#dd0606;"> ✘請確認活動時間</span> 
              <span v-if="!checkState" style="color:#0aae06;">✔資料正確</span>
            </div>          
        </div>
    </a-modal>
    </div>
  </a-spin>
  </div>
</template>
<script>
import { mapState } from "vuex";
import * as moment from "moment/moment";
import 'moment/locale/zh-tw';
import router from "../../router";
import { actService } from "../../_services";

const queryData = params => {
  return actService.findActIdes(params.id);
};

const postData = params => {
  return actService.addAct(params);
};

export default {
  name: "Act_date",
  //props: ["repository"],
  data() {
    return {
      jdata: {},
      spinning: true,
      visible: false,
      confirmLoading: false,
      information: [],
      uid: "",
      selectDate:"",
      stime:"",
      etime:"",
      msg:"",
      msgType:"success",
      locale:{
        lang:{
          month:'月',
          year:'年',
        }
      },
      currentTime: moment(),
      nowCurrentTime: moment(),
    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
    checkState() {
      if(this.etime=='' || this.stime==''){
        return true
      }
      let t1 = this.etime.split(':').slice(0,2).join(':')
      let t2 = this.stime.split(':').slice(0,2).join(':')
      if(t1 < t2){
        return true
      }else if(t1 === t2){
        return true
      }else{
        console.log("else")        
        return false
      }
    }

  },
  async mounted() {
    //this.jdata = this.$route.params.repository!==undefined? JSON.parse(this.$route.params.repository):[]
    this.uid = this.$route.params.id;
    this.fetch({ id: this.uid });
  },
  methods: {
    getListData(value) {
      let listData = [];
      if (this.jdata.acts_actclass != undefined) {
        this.jdata.acts_actclass.forEach(item => {
          if (this.getdate(item.sdate) == value.format("YYYY-MM-DD")) {
            listData.push({
              type:
                this.getime(item.sdate) >=
                this.nowCurrentTime.format("YYYY-MM-DD HH:mm")
                  ? "Y"
                  : "N",
              aid: item.id,
              id: this.jdata.id,
              orgid: this.jdata.orgid,
              title: this.jdata.actitle,
              fid: this.jdata.id,
              url: this.jdata.url,
              sdate: item.sdate,
              edate: item.edate,
              status: item.status,
              actstaff: item.acts_actstaff,
              limits: {
                checked: item.checked,
                value: item.value
              }
            });            
          }
        });
        return listData || [];
      }
      return listData || [];
    },

    getpeople(rs){
          let d1=0,d2=0,d3=0,d4=0          
          for(var j in rs){
            rs[j].roles=='volunteer'? d1=d1+1:''
            rs[j].roles=='coach'? d2=d2+1:''
            rs[j].roles=='actor'? d3=d3+1:''
            rs[j].roles=='actor' && rs[j].sid>0? d4=d4+1:''
          }  
          return '講師 :'+ d2 +'  工作人員 : '+ d1 +'<br/>  參加者 : ' +  d3  + ' 報到 : ' + d4;                
    },    

    onclick(val) {
      if (val.type == "Y" || val.type == "N") {
        router.push({ name: "Act_details", params: { rs: val, counts : this.jdata.acts_actclass.length ,sdate: this.jdata.sdate } });
      }
    },

    onSelect(e) {
      this.selectDate =""
      this.msg = ""
      var A = Date.now();
      let cdate = moment(e).format("YYYY-MM-DD");
      let nowdate = moment(A).format("YYYY-MM-DD");
      let edate = moment(this.jdata.edate).format("YYYY-MM-DD"); 
      let sdate = moment(this.jdata.sdate).format("YYYY-MM-DD");            
      if (cdate >= nowdate && cdate < edate && cdate > sdate) {
      //if (cdate >= nowdate && cdate <= edate) {
        let tmpArr = this.jdata.acts_actclass.filter(
          rs => this.getdate(rs.sdate) == moment(e).format("YYYY-MM-DD")
        );
        if (tmpArr.length == 0 && this.jdata.states!=="F" && this.jdata.acts_actclass.length>0) {
          this.selectDate =  moment(e).format("YYYY-MM-DD")
          this.msg ='新增一筆活動課程 ( '+this.selectDate + ' )'
          this.visible = true;
        }
      }
    },

   async handleOk() {
      this.confirmLoading = true;
      await this.toAddData().then((val)=>{return val}).catch((val)=>{return val})
    },
    handleCancel() {
      this.selectDate =""
      this.msg = ""    
      this.stime="" 
      this.etime =""
      this.visible = false;
    },    

    toAddData(){
      return new Promise((resolve,reject)=>{         
        let obj = {
          id:this.jdata.id, 
          date:this.selectDate,
          stime:this.stime.split(':').slice(0,2).join(':'),
          etime:this.etime.split(':').slice(0,2).join(':')
        } 
        postData({
            ...obj,
          }).then(({ data }) => { 
              if(data.code==200){ 
                this.msg = "資料新增成功"
                this.fetch({ id: this.uid });                             
                setTimeout(() => {
                  this.confirmLoading = false;
                  this.visible = false
                  resolve(data)  
                }, 2000);                
                
              }else{
                this.msg = "資料新增失敗!"                
                setTimeout(() => {
                  this.confirmLoading = false;
                  this.visible = false                
                  reject(data)   
                }, 2000);
              } 
              this.stime="" 
              this.etime =""
          });
        
        
      })
    },       


    getMonthData(value) {
      if (value.month() === 8) {
        return 1394;
      }
    },
    getdate(val) {
      return moment(val).format("YYYY-MM-DD");
    },

    getime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm");
    },

    fetch(params = {}) {
      queryData({
        ...params //...解壓縮出來
      }).then(({ data }) => {
        let dateString = moment(data.sdate).set('date', 1);
        this.currentTime=dateString
        this.jdata = data;
        this.spinning = false;
      });
    },

    handlePanelChange(value) {
      this.currentTime = value;
    },    

    toNext(){
      router.go(-1)
    },    
  }
};
</script>
<style >
.notes_css {
  position: absolute;
  text-align: center;
  top: 2rem;
  right: 10px;
  bottom: 10px;
  left: 10px;
  padding: 1.5rem 0 0 0;
}
  .ant-fullcalendar-content {
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .ant-fullcalendar-header .ant-radio-group {
      display: none !important;
  }
</style>