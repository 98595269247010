<template>
  <div>
    <div class="lineHeader">
      <div class="input-group" id="searchbar">
          <span class="input-group-text">活動類別</span>
          <select
            v-model="selected"
            @change="ontype()"
            class="form-select"
            aria-label="First name"
          >
          <option value="all" >全部</option>
          <option v-for="list in myJson" :value="list.value" :key="list.value">{{list.title}}</option>
          </select> 
                
          <a-button type="primary"  @click="openProfile()"  :size="'large'">
            <b-icon icon="person-circle"></b-icon>            
          </a-button>
          <a-button type="dark"  @click="openArie()"  :size="'large'">
            <b-icon icon="geo-alt-fill"></b-icon>
          </a-button>             
          <a-button type="danger"  @click="openContent()" shape="round" icon="heart" :size="'large'" />   

      </div>
      
    </div>
      <b-modal ref="my-lines" size="sm" centered @hide="onHide" id="LineLg" title="Line 登入" hide-header hide-footer>
        <div class="text-center">
        <Linelogin />
        </div>
      </b-modal>  
      
      <a-modal
        :title= "'我的最愛❤'"
        :visible="visible"
        :closable=true   
        :maskClosable=false
        :okText="'刪除'"
        :okType="'danger'"
        :cancelText= "'取消'"
        :bodyStyle="{ padding:'10px'}"
        @cancel="handelCancel"
      >
      <div class="text-center overflow-auto">
         <div style="padding:0rem;max-height: 550px;" v-if="user!==null" class="text-left">
          <b-list-group>
            <b-list-group-item button v-for="data in user.data.user.acts_loves" @click="query(data)" :key="data.ids">
              <b-avatar variant="primary" :src="data.pri_url" :size="36" class="align-baseline"></b-avatar>
              <span v-text="data.name" style="padding-left: 0.5rem;font-size: medium;" />
            </b-list-group-item>
          </b-list-group>
         </div>
      </div>
      <template slot="footer">
          <div class="text-center">
          </div>          
      </template>
      </a-modal>

      <a-modal
        :title= "'我的區域'"
        :visible="visible2"
        :closable=true   
        :maskClosable=false
        :okText="'刪除'"
        :okType="'danger'"
        :cancelText= "'取消'"
        :bodyStyle="{ padding:'10px'}"
        @cancel="handelCancel2"
      >
      <div class="text-center overflow-auto">
         <div style="padding:0rem;max-height: 550px;" v-if="user!==null" class="text-center">
          <a-row type="flex" justify="center" :align="'middle'" >
              <a-col :span="10" v-for="data in groupedData" :key="data.village">                
                <a-button shape="round"  @click="toArea(data.village)" style="margin:0.4rem;" :size="'large'" >
                  <b-icon icon="geo-alt-fill"  aria-hidden="true"></b-icon>
                  <span v-text="data.village" style="padding-left: 0.5rem;font-size: medium;" />
                </a-button>
              </a-col>                  
          </a-row>  
         </div>
      </div>
      <template slot="footer">
          <div class="text-center">
          </div>          
      </template>
      </a-modal>

      <a-modal
        :title= "'基本資料調整'"
        :visible="visible3"
        :closable=true   
        :maskClosable=false
        :okText="'刪除'"
        :okType="'danger'"
        :cancelText= "'取消'"
        :bodyStyle="{ padding:'10px'}"
        @cancel="handelCancel3"
      >
      <div class="text-center overflow-auto">
        <a-alert v-if="messages!==''" :message="messages" type="info" close-text="Close Now" />
        <a-spin :spinning="iconLoading">
        <div class="spin-content" v-if="user!==null">           
        <blockquote class="blockquote mb-0">
          <b-avatar  variant="info" :src="user.data.user.pri_url" size="4rem" class="avatar" style="margin: 0.5rem;"></b-avatar>          
                      <b-form @submit="onSubmit">
                        <b-form-group id="input-group-3" description label-for="input-9">
                          <label class="d-flex align-items-left" for="input-9">
                            <span style="font-size: 15px;" v-text="'建立顯示名稱 ( 請填寫真實姓名 ) : '"></span>
                          </label>
                          <b-form-input
                            id="input-9"
                            v-model="form.displayname"
                            autocomplete="off"
                            placeholder="請填寫真實中文姓名"
                            required
                          ></b-form-input>
                        </b-form-group>

                        <Addresscom @toaddress="toaddress" style="width:160px;float:left;" />
                        <div style="padding:57px;"></div>
                        <b-form-group id="input-group-2" description label-for="input-8">
                          <label class="d-flex align-items-left" for="input-8">
                            <code>*</code>
                            <span style="color:crimson;font-size: 15px;" v-text="'輸入地址 : ⚠街道路號可選填'"></span>
                          </label>
                          <div style="display: flex; justify-content: space-between;">
                          <b-form-input
                            id="input-8"
                            v-model="form.address"
                            autocomplete="off"
                            :disabled="true"
                            style="width:50%"
                            placeholder="選擇縣市鄉鎮"
                            required
                          ></b-form-input>
                          <b-form-input
                            id="input-8"
                            v-model="otherAddress"
                            autocomplete="off"
                            style="width:50%"
                            placeholder="輸入街道路號"
                          ></b-form-input>                     
                          </div> 
                        </b-form-group>
                        <b-form-group id="input-group-11" description label-for="input-sex">
                          <label class="d-flex align-items-left" for="input-sex">
                            <code>*</code>
                            <span style="color:crimson; font-size: 15px;">輸入性別 (男/女)</span>
                          </label>
                          <b-form-select
                            id="input-sex"
                            v-model="form.sex"
                            :options="[{ value: 'male', text: '男' }, { value: 'female', text: '女' }, { value: 'other', text: '其它' }]"
                            placeholder="請選擇性別"
                            required
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group id="input-group-12" description label-for="input-birth">
                          <label class="d-flex align-items-left" for="input-birth">
                            <code>*</code>
                            <span style="color:crimson; font-size: 15px;">輸入生日 (格式：YYYY-MM-DD)</span>
                          </label>
                          <b-form-input
                            type="date"
                            id="input-birth"
                            v-model="form.birth"
                            autocomplete="off"
                            placeholder="請輸入生日"
                            required
                          ></b-form-input>
                        </b-form-group>                        
                        
                        <b-form-group id="input-group-13" description label-for="input-phone">
                          <label class="d-flex align-items-left" for="input-phone">
                            <code>*</code>
                            <span style="color:crimson; font-size: 15px;">輸入手機號碼</span>
                          </label>
                          <b-form-input
                            v-model="form.hphone"
                            type="number"   
                            placeholder="輸入手機 "   
                            autocomplete="off"
                            required
                          ></b-form-input>
                        </b-form-group>                          

                        <a-button-group>
                          <a-button
                            type="primary"
                            html-type="submit"
                            style="width: 220px; height: 50px; font-size: 16px;"
                            :disabled=" form.address==='' || form.sex==='' || form.birth==='' || form.displayname==='' || form.hphone===''"
                          >更新</a-button>
                        </a-button-group>
                      </b-form>
                    </blockquote>
                  </div>
                </a-spin>                    
      </div>
      <template slot="footer">
          <div class="text-center">
          </div>          
      </template>
      </a-modal>      

  </div>
</template>

<script>
import Linelogin from "../../auth/Linelogin.vue";
import MY_JSON from '../../assets/json/optiondata.json'
import TW_JSON from '../../assets/json/twdata.json'
import Addresscom from "../../components/Addresslist.vue";
import { userService } from "../../_services/";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "Linemenubar",
  components: {
    Linelogin,
    Addresscom
  },
  props: {
    msg: String,
  },
  data() {
    return {
      myJson: MY_JSON, 
      twJson: TW_JSON,
      visible:false, 
      visible2:false,  
      visible3:false,            
      groupedData:[],
      show: null,
      selected:null,
      iconLoading: false, 
      messages:"",
      otherAddress:"",        
      form: {
        displayname:"",
        account: "",
        address: "",
        birth:"",
        sex:"",
        hphone:"",
        citys:'', 
        villages:'', 
        towns:'',         
      },      
    };
  },
  methods: {
    ...mapActions("account", ["checktoken", "logout"]),
    ...mapMutations("account", ["updateProfileByLine"]),
    showline() {
      this.$refs["my-lines"].show();      
    },

    closeline() {
      this.$refs["my-lines"].hide();      
    },    
 
    onHide(evt) {
          if(evt.trigger === "backdrop"){
              evt.preventDefault();
          }
      },
    ontype(){
      this.$emit("ontype",this.selected)
    },
    openContent(){
      this.visible=true;
    },
    handelCancel(){
      this.visible=false
    },
    openArie(){
      this.visible2=true;
    },
    openProfile(){
      this.messages='';
      this.visible3=true;
    },    
    handelCancel2(){
      this.visible2=false
    },    
    handelCancel3(){
      this.visible3=false
    },    
    query(item){
      this.$emit("onByOrg",item)
      this.handelCancel()
    },
    toArea(val){
      this.$emit("getArea",process.env.VUE_APP_AREA+val)
      this.handelCancel2()
    },
    toaddress(val,city,village,town) {
      this.form.address = val;
      this.form.citys=city
      this.form.villages = village
      this.form.towns = town 
    }, 
    async onSubmit(event) {
      event.preventDefault();
        this.iconLoading = true;
        var playload = {
          display: this.form.displayname!==""?this.form.displayname:this.displayname,
          address: this.form.address+this.otherAddress.toString().trim(),
          birth:this.form.birth,
          sex:this.form.sex,
          hphone: this.form.hphone,
          citys:this.form.citys,   
          villages:this.form.villages,
          towns:this.form.towns,   
        };
        this.updateProfileByLine(playload);
        this.user.data.user.display_name = this.form.displayname!==""?this.form.displayname:this.displayname;
        this.user.data.user.hphone = this.form.hphone;
        this.user.data.user.address = this.form.address+this.otherAddress.toString().trim();
        this.user.data.user.birth=this.form.birth;
        this.user.data.user.sex=this.form.sex;
        this.user.data.user.citys =this.form.citys;
        this.user.data.user.villages = this.form.villages;
        this.user.data.user.towns =this.form.towns;   
        await userService
          .upprofile(this.user.data.user)
          .then(rs => {
            if(rs.status === 201) { 
              this.messages='更新成功';          
            }else {
              this.messages='更新失敗';   
            }                         
            setTimeout(() => {
              this.messages='';
              this.iconLoading = false; 
              this.visible3=false;
            }, 1200);            
           
          });
    },     
       
   
  },
  mounted() {    
    if (this.user!==null) {
      this.form.hphone = this.user.data.user.hphone;
      this.form.displayname = this.user.data.user.display_name;
      this.form.address = this.user.data.user.address;
      this.form.birth=this.user.data.user.birth;
      this.form.sex=this.user.data.user.sex;
      this.form.citys = this.user.data.user.citys;
      this.form.villages = this.user.data.user.villages;
      this.form.towns = this.user.data.user.towns;
    }
  },  
  async created() {
    const filteredData = this.twJson.filter(item => item.city === process.env.VUE_APP_AREA);
    const uniqueVillages = [...new Set(filteredData.map(item => item.village))];
    this.groupedData = uniqueVillages.map(village => ({ village }));
  },

  computed: {
    ...mapState("account", ["status", "user"]),
  },
};
</script>
<style scoped>
.lineHeader {
   position: fixed;
   left: 0;
   top: 0;
   z-index: 7;
   opacity:1;
   width: 100%;
   height: 3.3rem;
   padding: 0.5rem;
   background-color: #3b1b75;
   color: #f0f8f8;
   text-align: center;
   
}
#searchbar{
  width:80%;
  margin: 0 auto;
}
</style>
<style>
#LineLg > .modal-dialog > .modal-content > .modal-body {
  border-color: transparent;
  background-color: transparent !important;
  color: white;
} 
#LineLg > .modal-dialog > .modal-content {
  border-color: transparent;  
  background-color: transparent !important;
} 
</style>