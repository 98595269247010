<template>
    <div>   
            <br>
            <b-card
                header="選擇活動主題"
                border-variant="light"
                header-border-variant="light"
                header-bg-variant="light"
                class="card-3 text-center"      
                >

            <b-card-body style="width:980px;" class="overflow-auto" id="style-3">
            <b-card-text > 
                <div class="text-center">
                    <a-row type="flex" >
                        <a-col :span="8" v-for="data in mydata" :key="data.value">
                            <div :class="['ddib', data.expanded ? 'ddiv' : '']">
                                <!--<a-avatar  :style="{ 'background': data.expanded ? 'MediumSeaGreen' : 'white' , cursor: 'pointer'}" shape="square" @click="toggleClass(data.value)" :size="94" :src="catImg(data.url)"  />-->
                                <a-avatar :style="{ cursor: 'pointer'}"  shape="square" @click="toggleClass(data.value)" :size="94" :src="catImg(data.url)"  />         
                                <br>{{data.title}}
                            </div>
                        </a-col>                  
                    </a-row>  
                </div>             
                            
            </b-card-text>
            </b-card-body>
            </b-card>
            <br>
            <div class="sava" v-if="JSON.stringify(obj)!=='{}'" >  
                 <a-button type="primary" shape="round" icon="save" @click="upsave" size="large" :disabled="idata===null" :loading="loading" block>
                     存檔變更                              
                 </a-button>
            </div>
    </div>
</template>
<script>

import MY_JSON from '../assets/json/optiondata.json'
export default {
    name: 'Option',
    components : {
    },
    props:{
          page:{
              type:Number
          },
          obj:{ 
              type:Object
          }, 
          cobj:{ 
            type:Object
          },                    
        },    
    data(){
        return {
            loading:false,
            disableds:false,
            selected: '',
            selecitem: '',
            myJson: MY_JSON,
            mydata :[], 
            idata:null,        
        }
    },
    watch: {
        cobj() {
            if (this.cobj!==undefined) {
                if(this.cobj.id>0){
                    this.onupdate()
                }
            }
        },
    },  
    methods :{

        toggleClass(val){
            this.mydata=this.mydata.map(x=> { 
                        x.expanded = false
                        if(x.value === val){
                            x.expanded = !x.expanded
                        }
                        return x;
                        } );
            this.idata = this.mydata.filter((el)=> { return el.value === val})
            let rs={
                pages: 2,
                state:true,
                data: this.idata[0]
            }    
            if(JSON.stringify(this.obj)==='{}'){
                this.$emit('done',rs)  
            }                   
                                                        
        },   
        
        upsave(){
          this.loading = true 
          this.$emit('updage',0,this.idata)
        },
        updone(){
            this.loading = false
        },

        catImg(imgs) {
            return require(`../assets/img/${imgs}`);
        },        
        cdone(rs){
            this.$emit('done',rs)
        },

        getdata(){
          let rs = this.$refs.chon1.getdata()
          return rs
        },

        close(){
            this.selected=''
        },
        inidata(){
            let data = this.mydata.map(x=> { 
              x.expanded=false;
              return x;
            } );
            return data;
        },
        onChange(e) {
            console.log(`checked = ${e.target.value}`);
            let rs={
                pages: 2,
                state:true,
            }              
            this.$emit('done',rs)
        }, 
        
        async onupdate(){
                       
            const self = this;
            setTimeout(() => {
                    if(JSON.stringify(self.cobj)!=='{}'){          
                            self.mydata=self.mydata.map(x=> { 
                                x.expanded = false
                                if(x.value === self.cobj.theme){
                                    x.expanded = !x.expanded
                                }
                                return x;
                            });                        
                            self.idata = self.mydata.filter((el)=> { return el.value === self.cobj.theme})
                            let rs={
                                pages: 2,
                                state:true,
                                data: self.idata[0]
                            } 
                            if(self.idata[0]!==null && self.idata[0]!==undefined){
                                self.$emit('done',rs)    
                            }
                        } 
                    }, "1000");
        },        
    },
    mounted () {
        this.mydata = this.myJson  
        this.inidata()
        if(JSON.stringify(this.obj)!=='{}'){            
            this.mydata=this.mydata.map(x=> { 
                x.expanded = false
                if(x.value === this.obj.theme){
                    x.expanded = !x.expanded
                }
                return x;
            });        
        }         
    },
    
}
</script>
<style scoped>
#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #000000;
}

#style-3{
    text-align: left;
}

.ddiv{
    padding:7px;
    border-radius: 17px;
    margin: 5px auto;
    box-shadow: 1px 1px 15px -5px rgb(46, 44, 44);
	transition: all .3s ease;
}
.card-3 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.sava {
  position: fixed;
  right: 0;
  top: 35%;
  width: 8em;
  margin-top: -2.5em;   
}
</style>