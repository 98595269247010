<template>
  <div class="menu">
    <div
      style="position: fixed;top:0; left:0;width: 100%;z-index:999;background: linear-gradient(-45deg,#024dc8 0%,#1757b8 40%,#7572e4 70%,#070a8b 100%);"
      class="bg-info"
    >
      <div class="container">
        <b-navbar toggleable="lg" type="dark">
          <!--<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>-->
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-brand @click="gohome()" href="javascript:;">
              <img alt="Vue logo" src="../assets/aclogo.png" />
              連江縣健康活動參與平台
            </b-navbar-brand>
          </b-collapse>
          <b-navbar-toggle
            v-show="!collapse.show"
            target
            style="padding:0px;border-width:0px;"
          >
            <b-navbar-brand @click="gohome()" href="javascript:;">
              <img alt="Vue logo" src="../assets/aclogo.png" />
              <span style="font-size: 14px;">連江縣健康活動參與平台</span>
            </b-navbar-brand>
          </b-navbar-toggle>
          <b-navbar-toggle
            target
            style="padding:4px;border-width:0px;"
            @click="queryitmes()"
          >
            <b-icon variant="light" font-scale="0.8" icon="search"></b-icon>
          </b-navbar-toggle>
          <b-collapse id="my-collapses" v-model="collapse.show" class="ml-auto">
            <b-nav-form v-show="collapse.show">
              <b-input-group size="sm">
                <b-form-input
                  autocomplete="off"
                  v-model="queryval"
                  type="search"
                  :style="{ 'border-radius': '13px', 'width' : windowsize.width>=720? '300px': '195px','z-index':'999999' }"
                  placeholder="請輸入關鍵字或行政區域"
                  aria-label="Small text input with custom switch"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    size="sm"
                    text="Button"
                    @click="queryitmes()"
                    style="padding:5px"
                    variant="link"
                  >
                    <b-icon variant="light" font-scale="1.5" icon="play-circle"></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-nav-form>
          </b-collapse>
          <b-navbar-toggle
            target="sidebar-variant"
            class="ml-auto"
            style="padding:0px;border-width:0px;"
          ></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <button class="btn btn-link" type="button" @click="queryitmes()">
                <b-icon variant="light" font-scale="1" icon="search"></b-icon>
                <span style="font-size: 12px;color: azure;" v-text="'(活動查詢)'"></span>
              </button>
              <b-navbar-nav>
                <!--<router-link class="nav-link" :to="{name:'About'}">關於我們</router-link>-->
                <b-nav-item v-if="!show" href="#" @click="showlogin()">登入</b-nav-item>
                <b-dropdown
                  size="sm"
                  variant="link"
                  v-if="show"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <b-avatar
                      :badge="gemess"  
                      badge-offset="-1.6em"
                      badge-variant="danger"
                      variant="link"
                      :size="26"
                    >
                      <b-icon icon="chat-quote-fill" variant="light"></b-icon>
                    </b-avatar>
                  </template>
                  <b-dropdown-text style="width: 300px;padding:0;">
                    <b-icon icon="chat-quote-fill" variant="success"></b-icon>&ensp;訊息
                  </b-dropdown-text>
                  <b-dropdown-group style="padding:10px;">
                    <a-list item-layout="horizontal" :data-source="items">
                      <a-list-item slot="renderItem" slot-scope="item">
                        <a-list-item-meta description>
                          <a
                            slot="title"
                            href="#"
                            style="margin-left:-0.5rem;"
                            @click="openInfor(item.id,'items')"
                          >{{ txtmess(item.title,15) }}</a>                          
                          <b-avatar variant="success" size="sm" slot="avatar" icon="envelope" />
                        </a-list-item-meta>
                      </a-list-item>
                    </a-list>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item href="#">
                      <router-link :to="{name:'Info_center'}">訊息/公告中心</router-link>
                    </b-dropdown-item>
                  </b-dropdown-group>
                </b-dropdown>

                <b-dropdown
                  size="sm"
                  variant="link"
                  v-if="show"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <b-avatar
                      :badge="alertmess"
                      badge-offset="-1.6em"
                      badge-variant="danger"
                      variant="link"
                      :size="26"
                    >
                      <b-icon icon="bell-fill" variant="light"></b-icon>
                    </b-avatar>
                  </template>
                  <b-dropdown-text style="width: 300px;padding:0;">
                    <b-icon icon="bell-fill" variant="warning"></b-icon>&ensp;公告
                  </b-dropdown-text>
                  <b-dropdown-group style="padding:10px;">
                    <a-list item-layout="horizontal" :data-source="altdata">
                      <a-list-item slot="renderItem" slot-scope="item">
                        <a-list-item-meta description>
                          <a
                            slot="title"
                            style="margin-left:-0.5rem;"
                            href="#"
                            @click="openInfor(item.id,'altdata')"
                          >{{ txtmess(item.title,15) }}</a>
                          <b-avatar variant="warning" style="color: #ffffff;" size="sm" slot="avatar" icon="calendar2-check-fill" />
                        </a-list-item-meta>
                      </a-list-item>
                    </a-list>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item href="#">
                      <router-link :to="{name:'Info_center'}">訊息/公告中心</router-link>
                    </b-dropdown-item>
                  </b-dropdown-group>
                </b-dropdown>
                <b-dropdown
                  size="sm"
                  variant="link"
                  v-if="show"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <!--<b-nav-item-dropdown size="sm" v-if="show" right>
                  Using 'button-content' slot-->
                  <template #button-content>
                    <em>
                      <b-avatar variant="link" :alt="displayname" :size="28" :src="purl"></b-avatar>
                    </em>
                  </template>
                  <b-dropdown-group id="dropdown-group-1" header="管理活動">
                    <!--<b-icon icon="gear-fill" aria-hidden="true"></b-icon>-->
                    <b-dropdown-item-button size="sm">
                      <router-link :to="{name:'Center'}">主辦中心</router-link>
                    </b-dropdown-item-button>
                  </b-dropdown-group>
                  <b-dropdown-divider></b-dropdown-divider>                  
                  <b-dropdown-group id="dropdown-group-1" header="報表">
                    <!--<b-icon icon="gear-fill" aria-hidden="true"></b-icon>-->
                    <b-dropdown-item-button size="sm">
                      <router-link :to="{name:'Reports'}">健康學分</router-link>
                    </b-dropdown-item-button>
                  </b-dropdown-group>                  
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-group id="dropdown-group-2" header="設定">
                    <!--<b-icon icon="gear-fill" aria-hidden="true"></b-icon>-->
                    <b-dropdown-item-button size="sm">
                      <router-link :to="{name:'Edit'}">個人資料</router-link>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button v-if="user.data.user.utoken!==''" size="sm">
                      <router-link :to="{name:''}" @click.native="toBind()">Line綁定</router-link>
                    </b-dropdown-item-button>
                    <!--<b-dropdown-item-button v-if="user.data.user.utoken!==''" size="sm">
                      <router-link :to="{name:'MyGroup'}">我的義工群組</router-link>
                    </b-dropdown-item-button>-->                                        
                    <!--<b-dropdown-item-button size="sm">帳號管理</b-dropdown-item-button>-->
                  </b-dropdown-group>
                  <b-dropdown-divider></b-dropdown-divider>

                  <b-dropdown-group id="dropdown-group-2" header="我的紀錄">
                    <b-dropdown-item-button size="sm">
                      <router-link :to="{name:'Myacts'}">我的報名</router-link>
                    </b-dropdown-item-button>
                  </b-dropdown-group>
                  <b-dropdown-group id="dropdown-group-2" header>
                    <b-dropdown-item-button size="sm">
                      <router-link :to="{name:'Myscore'}">我的健康學分</router-link>
                    </b-dropdown-item-button>
                  </b-dropdown-group>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-group id="dropdown-group-3" header>
                    <b-dropdown-item-button size="sm">
                      <router-link :to="{name:''}" @click.native="toOrder">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          fill="currentColor"
                          class="bi bi-qr-code"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 2h2v2H2V2Z" />
                          <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z" />
                          <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z" />
                          <path
                            d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z"
                          />
                          <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z" />
                        </svg>QRCode
                      </router-link>
                    </b-dropdown-item-button>
                  </b-dropdown-group>

                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="logouts" value="登出">
                    <b-icon icon="power" aria-hidden="true"></b-icon>登出
                  </b-dropdown-item>
                </b-dropdown>
                <router-link v-if="show" class="nav-link" :to="{name:'Makact'}">辦活動</router-link>
              </b-navbar-nav>
            </b-navbar-nav>
          </b-collapse>
          <!--</div>-->
        </b-navbar>
      </div>

      <b-sidebar
        id="sidebar-variant"
        ref="my-sidebar"
        aria-labelledby="sidebar-no-header-title"
        v-if="windowsize.width<=991"
        no-header
        shadow
      >
        <template #default="{ hide }">
          <div>
            <b-card no-body style="max-width: 20rem;">
              <template #header>
                <a-avatar
                  slot="avatar"
                  :src="purl"
                  :size="42"
                  @click="hide"
                  style="cursor: pointer;backgroundColor:#87d068"
                  icon="user"
                ></a-avatar>
                <span
                  v-if="show"
                  @click="logouts"
                  style="cursor: pointer;color:#096e80;font-size:13px;"
                >(登出)</span>
                <span
                  v-if="!show"
                  @click="showlogin"
                  style="cursor: pointer;color:#096e80;font-size:13px;"
                >(登入)</span>
              </template>

              <b-list-group flush>
                <b-list-group-item v-if="show">
                  <div style="text-align:left;">
                    <b-icon icon="person-fill"></b-icon>設定
                    <ul>
                      <li v-if="user.data.user.utoken!==''" size="sm">
                      <router-link :to="{name:''}" @click.native="toBind()">Line綁定</router-link>
                      </li>                        
                      <li>
                        <router-link :to="{name:'Edit'}">個人資料</router-link>
                      </li>
                      <li>
                      <router-link :to="{name:'Reports'}">健康學分(報表)</router-link>
                      </li>                     
                      <li>
                        <router-link :to="{name:'Myacts'}">我的報名</router-link>
                      </li>
                      <li>
                        <router-link :to="{name:'Myscore'}">我的健康學分</router-link>
                      </li>
                      <li>
                        <router-link :to="{name:''}" @click.native="toOrder">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            fill="currentColor"
                            class="bi bi-qr-code"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2 2h2v2H2V2Z" />
                            <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z" />
                            <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z" />
                            <path
                              d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z"
                            />
                            <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z" />
                          </svg>
                          QRCode
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div style="text-align:left;">
                    <b-icon icon="info-circle-fill"></b-icon>聯絡客服如下資訊
                    <ol>
                      <li>宸緯資訊有限公司 客服中心</li>
                      <li>服務信箱：glong168@gmail.com</li>
                      <li>服務時間：週一至週五 Mon.-Fri. 10:00-18:30</li>
                    </ol>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div style="text-align:left;">
                    <b-icon icon="filter-circle-fill"></b-icon>隱私政策
                    <ol>
                      <li>建置中</li>
                    </ol>
                  </div>
                </b-list-group-item>
              </b-list-group>

              <b-card-footer>歡迎來到連江縣健康活動參與平台</b-card-footer>
              <!--<b-card-footer>This is a footer</b-card-footer>-->
            </b-card>
          </div>
        </template>
      </b-sidebar>

      <b-modal ref="my-inform" id="modal-2" title @hide="onHide" centered hide-footer hide-header>
        <div class="text-right">
          <a-button type="link" @click="InfoClose()" shape="round" size="large">《確認關閉》</a-button>
        </div>
        <div class="text-center">
          <InforObj :ids="ids" />
        </div>
      </b-modal>
    </div>
    <div v-if="showbar"
      style="margin-top: 54px;position: fixed; left:0;width: 100%;z-index:998;background-color:#c9e0e4;max-height: 80px;
box-shadow: -1px -1px 14px 4px rgba(0,0,0,0.35);
-webkit-box-shadow: -1px -1px 14px 4px rgba(0,0,0,0.35);
-moz-box-shadow: -1px -1px 14px 4px rgba(0,0,0,0.35);"       
    >
      <!--:arrows="true" :dots="true"-->
      <VueSlickCarousel
        class="btn-group"
        ref="c2"        
        :centerMode="true"   
        :asNavFor="$refs.c1"
        :slidesToShow="windowsize.width>=720? 6: 3"
        :focusOnSelect="false"
      >
        <div class="button" v-for="data in myJson" :key="data.value">
          <div @click="queryitme(data.value)">
            <span>
              <a-avatar :size="24" :src="catImg(data.url)" />
              <span v-text="windowsize.width>400?data.title:fixtxt(data.title)"></span>
            </span>
          </div>
        </div>
        <template #prevArrow="">
          <button class="arrow-btn1">
          </button>
        </template>
        <template #nextArrow="">
          <button class="arrow-btn2">
          </button>
        </template>  
      </VueSlickCarousel>
    </div>
    <div :style="showbar ? 'padding: 55px;' : 'padding: 30px;' "></div>
    <b-modal ref="my-login" @hide="onHide" id="modal-1" title="請登入" hide-footer>
      <Login :uname="pagename" @change="closelogin" />
      <p class="forgot-password text-right mt-2 mb-4">
        <b-button variant="link" @click="showrg">註冊</b-button>
        <!--|
        <b-button variant="link" @click="showfg">忘記密碼?</b-button>
        <router-link to="/register"> 註冊 </router-link> 
        <router-link to="/forgot-password"> 忘記密碼? </router-link>-->
      </p>
    </b-modal>

    <b-modal ref="my-regist" @hide="onHide" id="modal-2" title="請註冊" hide-footer>
      <Registered @change="transform" />
      <p class="forgot-password text-right mt-2 mb-4">
        <b-button variant="link" @click="showlg">請登入</b-button>
      </p>
    </b-modal>

    <b-modal ref="my-forget" @hide="onHide" id="modal-3" title="忘記密碼?" hide-footer>
      <Forget />
    </b-modal>

    <b-modal
      ref="my-lines"
      size="sm"
      centered
      @hide="onHide"
      id="modal-4"
      title="Line 登入"
      hide-header
      hide-footer
    >
      <div class="text-center">
        <Linelogin />
      </div>
    </b-modal>

    <b-modal ref="my-qr" centered @hide="onHide" id="modalQrXy" title hide-header hide-footer>
      <div class="text-center" id="cbodyer">
        <div v-if="!list" @click="toclose()" style="cursor: pointer;">
          <div class="qrheader">
            <span v-text="'關閉QRCoder 請直接點選圖片'" style="margin-right: 1rem;" />
            <a-button
              type="link"
              icon="printer"
              style="margin-left: 1rem;font-size: 16px;color: white;"
              :loading="iconLoading2"
              @click="exportToPDF"
            >列印</a-button>
          </div>
          <div class="qrbody">
            <vue-qr
              ref="qrCode"
              :text="textValue"
              :logoSrc="logoPath"
              :logoScale="40"
              :size="qrSize"
              :margin="10"
            />
            <div class="classempy" id="word1head" v-show="iconLoading2">
              <div id="word123" class="text-center">
                <span style="font-size: 28px;margin-bottom: 2rem;" v-text="displayname"></span>
                <vue-qr
                  ref="qrCode"
                  :text="textValue"
                  :logoSrc="logoPath"
                  :logoScale="40"
                  :size="650"
                  :margin="10"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <a-modal
          title="帳號邦定"
          :visible="showModal"
          @cancel="closeModal"
          :confirm-loading="false"
          :centered="false"  
          :mask-closable="false"
          >
          <a-spin :spinning="spinnings" tip="資料載中...">
            <a-form>
              <a-form-item :label="binMsg">
                  <a-input 
                      v-model="myAccount" 
                      placeholder="請輸入帳號"
                  ></a-input>
                  <a-input 
                      type="password"
                      v-model="myPassword" 
                      placeholder="請輸入密碼"
                  ></a-input>
                  
                  <a-row :gutter="16">
                    <a-col :span="14">
                        <a-input 
                            v-model="verify" 
                            placeholder="請輸入驗證碼"
                        ></a-input>                                                
                    </a-col>
                    <a-col :span="10">
                        <div @click="refreshCode" style="cursor: pointer;">
                            <Identify :identifycode="identifyCode" ></Identify>
                        </div>                
                    </a-col>
                  </a-row>                                
              </a-form-item>
            </a-form> 
          </a-spin>            
            <template slot="footer">
                <a-button @click="closeModal">取消</a-button>
                <a-button @click="bindBtn()" :disabled="myAccount=='' || myPassword=='' || myCheck || identifyCode==''" type="primary">登入</a-button>          
            </template>
          
      </a-modal>

  </div>
</template>

<script>
/*
我們可以把常用的函數抽取出來，讓多個元件共用函數，這種東西呢，在vue2稱為mixin，但因為mixin會有需多全局污染的問題
*/
import Login from "../auth/Login.vue";
import Linelogin from "../auth/Linelogin.vue";
import Registered from "../auth/Registered.vue";
import InforObj from "../center/components/Info_detail.vue";
import Forget from "../auth/Forget.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import { messService } from "../_services/";
import { userService } from "../_services/";
import { mixinWebsocket } from "../wsocket/wsobj.js";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import Identify from "../auth/Identify.vue";
import MY_JSON from "../assets/json/optiondataindex.json";
import VueQr from "vue-qr";
import router from "../router";
import logoImg from "@/assets/lineicon.svg";
import html2pdf from "html2pdf.js";

const updatedata = obj => {
  return messService.upstatus(obj);
};

const postData = (params) => {
  return userService.myLogin(params);
};

export default {
  name: "Menubar",
  mixins: [mixinWebsocket],
  components: {
    Login,
    Linelogin,
    Registered,
    VueSlickCarousel,
    Forget,
    VueQr,
    InforObj,
    Identify
  },
  props: {
    msg: String,
    loginshow: Boolean,
  },
  data() {
    return {
      show: null,
      purl: "",
      ids: "",
      types: "",
      queryval: null,
      gemess: null,
      alertmess: null,
      myJson: MY_JSON,
      collapse: { show: false },
      windowsize: { width: 0, height: 0 },
      pagename: "",
      list: true,
      logoPath: logoImg,
      fields: ["from", "content"],
      items: [],
      textValue: "",
      altdata: [],
      qrSize: 350,
      displayname: "",
      iconLoading2: false,
      showbar:true,

      binMsg: "",
      spinnings: false,
      showModal: false,
      myAccount:"",
      myPassword:"",                
      verify:'',
      identifyCode: '',
      identifyCodes: '123456789abcdefghijklmnopqstuwxyzABCDEFGHGKMNPQRSTUVWXYZ',     
    };
  },
  methods: {
    ...mapActions("account", ["checktoken", "logout","binLogin"]),
    ...mapMutations("ws", ["sendMessage"]),
    ...mapActions("makeorg", ["readorg"]),

    showrg() {
      this.$refs["my-login"].hide();
      this.$refs["my-regist"].show();
    },
    showlg() {
      this.$refs["my-regist"].hide();
      this.$refs["my-login"].show();
    },
    showfg() {
      this.$refs["my-login"].hide();
      this.$refs["my-forget"].show();
    },
    showlogin() {
      this.$refs["my-forget"].hide();
      this.$refs["my-regist"].hide();
      this.$refs["my-login"].show();
    },

    showline() {
      this.$refs["my-forget"].hide();
      this.$refs["my-regist"].hide();
      this.$refs["my-login"].hide();
      this.$refs["my-lines"].show();
    },

    openInfor(id, type) {
      this.ids = id;
      this.types = type;
      setTimeout(() => {
        this.$refs["my-inform"].show();
        clearInterval();
      }, 200);
    },
    fixtxt(val){
      return val.substring(0, 2)
    },
    InfoClose() {
      let obj = { id: this.ids };
      if (this.types === "items") {
        this.items = this.items.filter(obj => obj.id !== this.ids);
        if(this.items.length===0){
          this.gemess=null
        }        
      } else {
        this.altdata = this.altdata.filter(obj => obj.id !== this.ids);
        if(this.altdata.length===0){
          this.alertmess=null
        }
      }
      this.$refs["my-inform"].hide();
      updatedata(obj).then(({ data }) => {
        if (data.rs == "done") {

          setTimeout(() => {
            this.$refs["my-inform"].hide();
            clearInterval();
          }, 200);
        }
      });
    },

    handleResize() {
      this.windowsize.width = window.innerWidth;
      this.windowsize.height = window.innerHeight;
      this.$emit("getwinsize", this.windowsize);
    },

    queryitmes() {
        //let tmp  = this.queryval==null || this.queryval==''? '':this.queryval
        //router.push({ name: "Query_center", params: { id: tmp } });
        router.push({ name: "Query_center" });
    },
    handleEnterKey() {
      this.queryitmes()
    },

    logouts() {
      this.websocketclose();
      this.logout();
      if (this.status.token === "") {
        this.purl = "";
        this.displayname = "";
        this.show = this.status.loggedIn;
      }
    },
    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    },

    catImg(imgs) {
      return require(`../assets/img/${imgs}`);
    },
    queryitme(val) {
      this.$emit("query", val);
    },

    gohome() {
      router.replace("/").catch(err => {
        err;
      });
    },
    transform() {
      this.showlg();
    },
    txtmess(val, i) {
      return val.substr(0, i) + "......";
    },
    closelogin() {
      this.$refs["my-login"].hide();
      this.onload();
    },
    onload() {
      if (this.status.token !== "") {
        this.purl = this.user.data.user.pri_url;
        this.displayname = this.user.data.user.display_name; 
        this.show = this.status.loggedIn;
        //if(this.getws==1) {
        if (this.wsState == false) {
          if (this.status.loggedIn) {
            //this.initWebsocket();
            let id = { id: this.user.data.user.id };
            this.readorg(id);
            if (this.user.data.use && this.user.data.user.address.toString().trim()==='') {
              router.replace("/setting").catch(err => {
                err;
              });
            }else{ //2023122 改動                                  
              this.initWebsocket()
              /*router.replace("/setting").catch(err => {
                err;
              });*/              
            }
          }
        }
        //this.checktoken()
      }
    },

    loadmess() {
      if(this.user !== null){
          let obj = {
          type: "load",
          pid: this.user.data.user.id,
          rmg: "",
          tomenss: [{ id: this.user.data.user.id }]
        };
        this.sendMessage(JSON.stringify(obj));
      }
    },

    toOrder() {
      this.list = false;
      this.textValue = "actjoin:" + this.user.data.user.id;
      this.$refs["my-qr"].show();
    },

    toBind(){
        this.binMsg = "";        
        this.myAccount=""
        this.myPassword="" 
        this.spinnings = false;
        this.verify="" 
        this.refreshCode()
        this.showModal = true;
    },

    async bindBtn() {
      if(this.myAccount!=='' && this.myPassword!=='' ){
          let item = {
          id:this.user.data.user.id,
          username: this.myAccount,
          password: this.myPassword, 
          utoken:this.user.data.user.utoken,
          pri_url:this.user.data.user.pri_url       
        };
        this.spinnings = true;
        let rs = await this.myLogin(item)
        if (rs.code== 200){
          this.cardcontent = "綁定成功";
          this.display = true;
          setTimeout(() => {
            this.display = false;
            this.spinnings = false;
            this.closeModal();
            this.binLogin({username: this.myAccount,password: this.myPassword,targetPage:'Home'})
          }, 1000);
        }else if(rs.code== 404){
          this.binMsg = "請確認密碼, 或是帳不是否存在, 或已被綁定";  
          this.spinnings = false;               
        }
      }
    },
    
    myLogin(item) {
      return new Promise((resolve) => {         
          postData({
            ...item,
          }).then(({ data }) => {
              resolve(data);            
          });
        });
      },     
    
    closeModal() {
      this.spinnings = false;
      this.showModal = false;
    },
    
    refreshCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },

    makeCode(o, l) {
        for (let i = 0; i<l; i++) {
            this.identifyCode += this.identifyCodes[
                Math.floor(Math.random() * (this.identifyCodes.length - 0) + 0)
                ]
        }  
     },     

    toclose() {
      this.list = true;
      this.$refs["my-qr"].hide();
    },

    exportToPDF() {
      // pdf匯出設定
      this.iconLoading2 = true;
      const opt = {
        margin: 0.3,
        filename: "QRCode.pdf",
        image: { type: "jpeg", quality: 1 },
        html2canvas: { dpi: 192, letterRendering: true, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" }
      };
      this.domList = [];
      // 抓取各個圖片的DOM元素，並把它裝入doc這個陣列裡
      let newDiv = document.createElement("div");
      //let j = this.items.act_detrimal.length
      let head1 = document.getElementById("word1head");
      const clone1 = head1.cloneNode(true);
      newDiv.appendChild(clone1);
      let element1 = document.getElementById("word123");
      this.domList.push(element1);
      // 開始進行列印
      let doc = html2pdf()
        .from(this.domList[0])
        .set(opt)
        .toPdf();
      for (let j = 1; j < this.domList.length - 1; j++) {
        doc = doc
          .get("pdf")
          .then(pdf => pdf.addPage())
          .from(this.domList[j])
          .toContainer()
          .toCanvas()
          .toPdf();
      }
      // 列印完成輸出done
      doc.save().then(() => (this.iconLoading2 = false));
      //
    },
    closebar(val){
      this.showbar = val
    }
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  computed: {
    ...mapState("account", ["status", "user"]),
    ...mapState("ws", ["wsNotify", "wsState", "socket"]),

      myCheck(){
        return this.verify.toLowerCase()!=this.identifyCode.toLowerCase()?true:false
      }
      
  },
  watch: {
    "status.loggedIn": val => {
      if (val == false) {
        //if(this.wsState!==undefined){
        //  this.websocketclose();
        //}
      }
    },

    wsNotify(val) {
      this.altdata = [];
      this.items = [];
      this.gemess = null;
      this.alertmess = null;
      if (val) {
        let { data } = val;
        //let tdata =null
        let itmes = {};

        for (var obj in data) {
          itmes = data[obj];
          if (data[obj].typ === "mess") {
            //tdata = Number(this.gemess==null?0:this.gemess) +1
            //this.gemess =  tdata.toString()
            this.gemess = "New";
            this.items.push(itmes);
          } else if (data[obj].typ === "alert") {
            //tdata = Number(this.alertmess==null?0:this.alertmess) +1
            //this.alertmess =  tdata.toString()
            this.alertmess = "New";
            this.altdata.push(itmes);
          }
        }
        var byDate = this.items.slice(0);
        this.items = byDate.sort((a, b) => {
          return Date.parse(b.created_at) - Date.parse(a.created_at);
        });

        var byDate2 = this.altdata.slice(0);
        this.altdata = byDate2.sort((a, b) => {
          return Date.parse(b.created_at) - Date.parse(a.created_at);
        });
        let tmpdata = [];
        let i = 0;
        for (var ob1 in this.items) {
          if (i < 5) {
            tmpdata.push(this.items[ob1]);
          } else if (i > 5) {
            break;
          }
          i++;
        }
        this.items = tmpdata;
        let tmpdata2 = [];
        let j = 0;
        for (var ob2 in this.altdata) {
          if (j < 5) {
            tmpdata2.push(this.altdata[ob2]);
          } else if (j > 5) {
            break;
          }
          j++;
        }
        this.altdata = tmpdata2;
      }
    },

    wsState(val) {
      if (val == true) {
        this.loadmess();
      }
    },

    socket(val) {
      if (val !== null) {
        if (val.url == undefined) {
          this.initWebsocket();
          this.loadmess();
        }
      }
    },

  },
  mounted() {
    this.onload();
    this.checktoken();
    this.pagename = this.$route.name;
    //console.log(this.status);
    /*this.myJson.splice(0,0,    {
        "title": "全部",
        "value": "null",
        "url": "new_all.svg"
    },)*/
    //this.loginshow? this.showlogin():null
  },
  created() {
    if (this.status.loggedIn) {
      if (this.wsState == true) {
        this.loadmess();
      }
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }
};
</script>
<style scoped>
.slick-slider {
  margin: 0px auto;
  padding: 0px;
  width: 80%;
  color: white;
  max-width: 990px;
}

.my-buttons .active {
  color: #fff !important;
}
.btn-group .button {
  /*background-color: #4CAF50;  Green */
  border: none;
  height: 49px;
  color: rgb(5, 59, 80);
  padding: 15px 0px;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  float: left;
}

.btn-group .button:hover {
  background-color: #c5d6c5;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border-bottom: 7px solid #68beb3;
  background: linear-gradient(#d0fdf7, #7bdbce);
}

.qrbody {
  padding: 0.4rem;
  background-color: #76d27a;
  color: white;
  border-radius: 0 0 0.5rem 0.5rem;
  border-bottom-style: dotted;
  border-color: #3e3e3e;
  border-width: 0.35rem;
  border-left-style: dotted;
  border-right-style: dotted;
}

.qrheader {
  padding: 0.6rem;
  background-color: #6dc871;
  color: white;
  border-radius: 1.3rem 1.3rem 0 0;
  border-color: #3e3e3e;
  border-bottom-style: dotted;
  border-width: 0.2rem;
}

.arrow-btn1{
  margin-top:0.22rem;
}     

.arrow-btn2 {
  margin-top:0.22rem;
} 
</style>

<style >
#modalQrXy > .modal-dialog > .modal-content > .modal-body {
  border-color: transparent;
  background-color: transparent !important;
  color: white;
}
#modalQrXy > .modal-dialog > .modal-content {
  border-color: transparent;
  background-color: transparent !important;
}
</style>