<template>
  <div class="container">
    <div class="spin-content">
      <a-steps :current="current">
        <a-step v-for="item in steps" :key="item.title" :title="item.title" />
      </a-steps>
      <div class="steps-content">
        <div class="text-center" style="padding: 10px">
          <span v-text="steps[current].content" />
        </div>
        <div v-if="current == 0">
          <div class="text-left">
            <a-input-search
              placeholder="請輸入查詢名稱"
              style="margin: 10px; width: 200px"
              enter-button
              @search="onSearch"
            />
          </div>
          <div class="overflow-auto" style="max-width: 720px" id="style-3">
            <a-alert
              v-if="merrors != ''"
              :message="merrors"
              type="error"
              show-icon
            />
            <a-table
              :columns="mcolumns"
              :row-key="(record) => record.id"
              :data-source="udata"
              :pagination="upagination"
              :loading="uloading"
              :row-selection="{
                type: 'radio',
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
              @change="uhandleTableChange"
              bordered
            >
              <template slot="pri_url" slot-scope="pri_url">
                <b-avatar
                  :src="pri_url"
                  variant="primary"
                  text
                  size="2rem"
                  class="align-baseline"
                ></b-avatar>
              </template>
              <template slot="username" slot-scope="record">
                <div>
                  <span
                    v-if="record.username != ''"
                    style="color: #f08c3c"
                    v-text="maskName(record.username)"
                  ></span>
                </div>
              </template>
              <template slot="display_name" slot-scope="record">
                <div>
                  <span
                    v-if="record.display_name != ''"
                    style="color: #2c7190"
                    v-text="record.display_name"
                  ></span>
                </div>
              </template>
            </a-table>
          </div>
        </div>
        <div v-else-if="current == 1">
          <div class="text-left">
            <b-form>
              <p class="h6"><b-icon-vinyl-fill></b-icon-vinyl-fill>關於</p>
              <div class="text-center">
                <a-upload
                  name="imgfile"
                  list-type="picture"
                  class="avatar-uploader"
                  method="post"
                  :show-upload-list="false"
                  :headers="headers"
                  :action="uploadurl"
                  :before-upload="beforeUpload"
                  @change="handleChange"
                >
                  <b-avatar
                    :src="form.url"
                    @click="beforeUpload"
                    variant="primary"
                    text
                    size="9rem"
                    class="align-baseline"
                  ></b-avatar>
                </a-upload>
              </div>
              <hr
                style="
                  height: 1px;
                  border-width: 0;
                  color: #3d7878;
                  background-color: #3d7878;
                "
              />
              <a-alert
                v-if="formcheck"
                message="'資料填寫完畢, 請下一步'"
                type="success"
                style="margin-bottom: 1rem;"
                show-icon
              />
              <h6><span class="font-weight-bold" style="margin-top: 1.5rem;" v-text="'帳號名稱 : '+form.name" />
                <span class="font-weight-bold" style="margin-top: 1.5rem;" v-text="realName!==''?' | 顯示名稱 : '+realName:''" /></h6>
              <input type="hidden" id="input-1" v-model="form.name" >            
              <p class="h6"></p> 
               <div style="padding:5px;">
                   <b-icon-award-fill></b-icon-award-fill><span v-text="'是否獲得學分 : '"></span> 
                   <a-switch checked-children="是" un-checked-children="否" @change="scoreChange" :checked="form.getscore" default-unchecked />
               </div>               
              <div v-if="role == 'coach'">
                <b-form-group
                  id="input-group-2"
                  style="width: 80%"
                  label="職稱*  "
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="form.title"
                    type="text"
                    placeholder="輸入職稱 "
                    rows="8"
                    autocomplete="off"
                    :state="titleState"
                    required
                  ></b-form-input>
                </b-form-group>

                <p class="h6"><b-icon-grid-fill></b-icon-grid-fill>個人介紹</p>
                <hr
                  style="
                    height: 1px;
                    border-width: 0;
                    color: #3d7878;
                    background-color: #3d7878;
                  "
                />
                <b-form-group
                  id="input-group-3"
                  style="width: 80%"
                  label="公司名稱*"
                  label-for="input-3"
                >
                  <b-form-input
                    id="input-3"
                    v-model="form.company"
                    type="text"
                    placeholder="輸入公司名稱 "
                    autocomplete="off"
                    :state="companyState"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  style="width: 98%"
                  label="嘉賓簡介*  "
                  label-for="input-2"
                >
                  <b-form-textarea
                    id="input-2"
                    v-model="form.desc"
                    type="text"
                    placeholder="輸入嘉賓簡介 "
                    rows="8"
                    autocomplete="off"
                    :state="descState"
                    required
                  ></b-form-textarea>
                </b-form-group>

                <p class="h6"><b-icon-tree-fill></b-icon-tree-fill>媒體連結</p>
                <hr
                  style="
                    height: 1px;
                    border-width: 0;
                    color: #3d7878;
                    background-color: #3d7878;
                  "
                />

                <b-form-group
                  id="input-group-8"
                  style="width: 80%"
                  label="Facebook  "
                  label-for="input-8"
                >
                  <b-form-input
                    id="input-8"
                    v-model="form.finkurl"
                    type="url"
                    placeholder="輸入Facebook"
                    autocomplete="off"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-9"
                  style="width: 80%"
                  label="Instagram  "
                  label-for="input-9"
                >
                  <b-form-input
                    id="input-9"
                    v-model="form.iinkurl"
                    type="url"
                    placeholder="輸入Instagram"
                    autocomplete="off"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <input
                type="hidden"
                name="customfield"
                class="form-control"
                :value="form.uid"
              />
            </b-form>
          </div>
        </div>
        <div v-else-if="current == 2">
          <a-result :title="'資料填寫完畢, 請執行存檔謝謝!'">
            <template #icon>
              <a-icon :type="iconState" theme="twoTone" />
            </template>
          </a-result>
        </div>
      </div>
    </div>
    <div class="steps-action left">
      <a-button
        v-if="current < steps.length - 1"
        :disabled="!steps[current].state"
        type="primary"
        @click="next"
        >下一步</a-button
      >
      <a-button
        v-if="current == steps.length - 1"
        type="primary"
        :disabled="!formcheck"
        @click="upsave"
        >完成</a-button
      >
      <a-button v-if="current > 0" style="margin-left: 8px" @click="prev"
        >上一步</a-button
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { cmessService, joinService } from "../_services";

const queryuser = (params) => {
  return cmessService.findusListName(params.page, params.name);
};
const queryBaid = (params) => {
  return joinService.checkbyDate(params);
};

const mcolumns = [
  {
    title: "圖像",
    dataIndex: "pri_url",
    width: "25%",
    scopedSlots: { customRender: "pri_url" },
  },
  {
    title: "註冊帳號",
    dataIndex: "",
    scopedSlots: { customRender: "username" },
    width: "30%",
  },
  {
    title: "Line顯示名稱",
    dataIndex: "",
    scopedSlots: { customRender: "display_name" },
    width: "30%",
  },
];

export default {
  name: "Getcoach",
  props: {
    role: {
      type: String,
    },
    objs: {
      type: Array,
    },
    objs2: {
      type: Array,
    },
    jdata: {
      type: Object,
    },
  },
  data() {
    return {
      spinning: false,
      iconState: "smile", //  success
      current: 0,
      loading: false,
      errors: "",
      mtyps: "error",
      uploadurl: process.env.VUE_APP_URL + "user/image",
      mcolumns,
      name: "all",
      udata: [],
      merrors: "",
      upagination: { defaultPageSize: 4 },
      uloading: false,
      selectedRowKeys: [],
      realName:'',
      form: {
        uid: "",
        desc: "",
        name: "",
        title: "",
        company: "",
        finkurl: "",
        iinkurl: "",
        url: "",
        getscore:true,
      },
      steps: [
        {
          title: "步驟一",
          content: "選擇人員",
          state: false,
        },
        {
          title: "步驟二",
          content: "資料介紹",
          state: false,
        },
        {
          title: "完成",
          content: "執行存檔",
          state: false,
        },
      ],
    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
    headers() {
      return {
        "X-Requested-With": null,
        Authorization: "Bearer " + this.status.token,
      };
    },
    formcheck() {
      if (this.role == "coach") {
        if (
          this.form.uid != "" &&
          this.form.desc != "" &&
          this.form.name != "" &&
          this.form.title != "" &&
          this.form.company != ""
        ) {
          this.gotrue(true);
          return true;
        }
      } else {
        if (this.form.uid != "" && this.form.name != "") {
          this.gotrue(true);
          return true;
        }
      }
      this.gotrue(false);
      return false;
    },
    descState() {
      return this.form.desc !== "" ? true : false;
    },

    nameState() {
      return this.form.name !== "" ? true : false;
    },

    titleState() {
      return this.form.title !== "" ? true : false;
    },

    companyState() {
      return this.form.company !== "" ? true : false;
    },
  },
  methods: {
    next() {
      this.current++;
    },
    prev() {
      this.current--;
    },

    maskName(name) {
            const len = name.length;
            const half = Math.ceil(len / 2);
            const maskedPart = '*'.repeat(half);
            const visiblePart = name.slice(half);
            return maskedPart + visiblePart;
    },      

    scoreChange(getscore) {
        this.form.getscore = getscore
     },      

    async onSearch(value) {
      if (value == "") {
        this.name = "all";
      } else {
        this.name = value;
      }
      await this.usfetch({ results: 4, page: 1, pageSize: 4, name: this.name });
    },

    clearn() {
      this.form.uid = "";
      this.form.desc = "";
      this.form.name = "";
      this.form.title = "";
      this.form.company = "";
      this.form.finkurl = "";
      this.form.iinkurl = "";
      this.form.url = "";
      this.form.getscore = true;
      this.selectedRowKeys = [];
      this.current = 0;
      this.steps[0].state = false;
      this.steps[1].state = false;
      this.steps[2].state = false;
    },

    fillin(rs, id) {
      let v1 = rs.filter((el) => {
        return el.id === id;
      });
      //console.log(v1,id,v1[0].profile[0].priUrl)
      this.form.uid = id;
      this.form.name = v1[0].username;
      this.form.url = v1[0].pri_url;
    },

    beforeUpload(file) {
      this.errors = "";
      //this.$emit('upmessage',null)
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      return new Promise((resolve, reject) => {
        if (!isJpgOrPng) {
          //this.$message.error('您的檔案必須是 JPG or PNG!');
          this.errors = "您的檔案必須是 JPG or PNG!";
          this.mtyps = "error";
          //this.$emit('upmessage','您的檔案必須是 JPG or PNG!')
          reject(file);
        } else if (!isLt2M) {
          //this.$message.error('您的檔案必須是小於 2MB!');
          this.errors = "您的檔案必須是小於 2MB!";
          this.mtyps = "error";
          //this.$emit('upmessage','您的檔案必須是小於 2MB!')
          reject(file);
        } else {
          this.$refs["my-loading"].show();
          resolve(file);
        }
      });
    },

    async handleChange({ file }) {
      if (file.status === "done") {
        this.form.url = file.response.Location;
      }
    },

    getdata() {
      return this.form;
    },

    gotrue(va1) {
      this.steps[this.current].state = va1;
    },

    catImg(imgs) {
      return require(`../assets/${imgs}`);
    },

    onReset(event) {
      event.preventDefault();
      this.$nextTick(() => {});
    },

    usfetch(params = {}) {
      this.uloading = true;
      queryuser({
        //results: 4,
        ...params,
      }).then(({ data }) => {
        const pagination = { ...this.upagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.info.totail;
        //pagination.total = 200;
        this.uloading = false;
        this.udata = data.results;
        this.upagination = pagination;
      });
    },

    Gemanaid(
      params = {
        uid: this.selectedRowKeys,
        fid: this.jdata.fid,
        aid: this.jdata.aid,
      }
    ) {
      return queryBaid({
        ...params,
      }).then(({ data }) => data.info);
    },

    GetAid() {
      return new Promise((resolve) => {
        resolve(this.Gemanaid());
      });
    },

    uhandleTableChange(pagination, filters, sorter) {
      //console.log(pagination);
      const pager = { ...this.upagination };
      pager.current = pagination.current;
      this.upagination = pager;
      //console.log(this.pagination);
      this.usfetch({
        results: pagination.pageSize,
        name: this.name,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },

    async onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      let myRs = this.udata.filter((el) => {
          return el.id === this.selectedRowKeys[0];
        }); 
      this.realName=myRs[0].display_name
      let vs = [];
      if (this.objs !== null) {
        vs = this.objs2.filter((el) => {
          return el.uid === this.selectedRowKeys[0];
        });
      }
      let rs = await this.GetAid();
      if (vs.length > 0 || rs == true) {
        this.merrors = "該員已經是工作人員或講師了或是參加者, 請先移除再新增";
        this.steps[this.current].state = false;
      } else {
        this.merrors = "";
        let v1 = this.udata;
        this.fillin(v1, this.selectedRowKeys[0]);
        this.steps[this.current].state = true;
      }
    },

    upsave() {
      this.$emit("addMen", this.role, this.form);
    },
  },
  mounted() {
    this.clearn();
    this.usfetch({ results: 4, page: 1, pageSize: 4, name: this.name });
  },
  beforeDestroy() {},
};
</script>
<style scoped>
.card-body {
  padding: 5px 0px 5px 0px;
}
.ddiv {
  padding: 7px;
  border-radius: 17px;
  margin: 5px auto;
  box-shadow: 1px 1px 15px -5px rgb(46, 44, 44);
  transition: all 0.3s ease;
}
.card-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.bton {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: 40px;
  position: relative;
  box-shadow: 0 10px 20px rgba(68, 66, 66, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  left: 0;
  right: 0;
}
div#home a:link {
  color: #578cc9;
}
div#home a:visited {
  color: #578cc9;
}
div#home a:hover {
  color: #578cc9;
}
div#home a:active {
  color: #578cc9;
}

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #302f2f;
}

#style-3 {
  text-align: left;
}

.sava {
  position: fixed;
  right: 0;
  top: 35%;
  width: 8em;
  margin-top: -2.5em;
}
</style>

